import classNames from 'classnames';

import styles from './quotes-policy-tracker.css';

import welcomeBox from '../../../../../../../public/assets/paw-portal/illustrations/multipet/welcome_box.png';

function QuotesPolicyTracker({ quoteData, currentMonth, canBeCurrentMonth }) {
  const {
    all_time_policies: allTimePolicies,
    all_time_quotes: allTimeQuotes,
    current_month_policies: currentMonthPolicies,
    current_month_quotes: currentMonthQuotes,
  } = quoteData;

  // even though parent is looping views, only show current month view if non-zero data
  const isAllTime = !(
    canBeCurrentMonth &&
    (currentMonthQuotes > 0 || currentMonthPolicies > 0)
  );

  return (
    <div className={styles.root}>
      <img src={welcomeBox} alt='' className={styles.img} />

      <h3>You're making an impact!</h3>

      <div
        className={classNames(styles.quotesBlock, {
          [styles.currentMonth]: !isAllTime,
        })}
      >
        <p>{isAllTime ? 'Since Activation Date' : `Month of ${currentMonth}`}</p>

        <div className={styles.data}>
          <div className={styles.item}>
            <p className={styles.number}>
              {isAllTime ? allTimeQuotes : currentMonthQuotes}
            </p>
            <p className={styles.label}>QUOTES</p>
          </div>

          <div className={styles.item}>
            <p className={styles.number}>
              {isAllTime ? allTimePolicies : currentMonthPolicies}
            </p>
            <p className={styles.label}>INSURED PETS</p>
          </div>
        </div>
      </div>

      <div className={styles.block}>
        <h4>Disclaimers</h4>
        <p>
          Hospitals and Hospital staff will not be rewarded for the amount of Quotes
          and Policies generated.
        </p>
      </div>
    </div>
  );
}

export default QuotesPolicyTracker;
