import { useMutation, useQueryClient } from 'react-query';

import { fetchWrapper } from '@pumpkincare/portal-shared';

import { MEDICAL_RECORDS_REQUEST_QUERY } from './medical-records-request-query';

export function useMutatePatchMedicalRecordRequest(
  requestId,
  { ...hookOptions } = {}
) {
  /**
   * Return the RQ method for updating a medical record request - FE using this to close a request
   * @param {string} requestId
   * @param {string} status - closing a medical record request should make it CLOSED
   * @param {string} closed_with - should point to either DOCUMENTS or one of the REASON options from reasons-modal
   * @param {string} reason_notes - free text that is optional (unless REASON:OTHER)
   */
  const queryClient = useQueryClient();

  return useMutation(
    body =>
      fetchWrapper(`/medical-records-requests/${requestId}`, {
        method: 'PATCH',
        body,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([MEDICAL_RECORDS_REQUEST_QUERY, requestId]);
      },
      ...hookOptions,
    }
  );
}
