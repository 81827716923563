import { apiGet } from '../../app';
import { PREVENT_CLAIM } from '../constants/claim-types';

export default function downloadClaim(claimType, claimId) {
  const typePath =
    claimType === PREVENT_CLAIM ? 'prevent-claim-summary' : 'protect-claim-eob';
  return dispatch => {
    return dispatch(
      apiGet({
        url: `/claims/${typePath}/${claimId}`,
        options: {
          isAuth: true,
        },
      })
    ).then(data => {
      window.open(data.url, '_blank');
    });
  };
}
