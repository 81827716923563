import { useEffect, useState } from 'react';
import classNames from 'classnames';

import { Body2, LegalBody } from '@pumpkincare/portal-ui';

import styles from './quiz.css';

function Quiz({ quiz }) {
  const [view, setView] = useState('base');

  useEffect(() => {
    setTimeout(() => {
      setView('reveal');
    }, 10000);

    setTimeout(() => {
      setView('answer');
    }, 15000);
  }, []);

  return (
    <div className={styles.root}>
      {view === 'answer' ? (
        <div className={styles.answer}>
          <h1>{quiz.answer}</h1>
          <LegalBody className={styles.citation}>{quiz.citation}</LegalBody>
        </div>
      ) : (
        <div className={styles.base}>
          <Body2 className={styles.pill}>QUIZ TIME!</Body2>
          <h1>{quiz.question}</h1>

          <div className={styles.options}>
            {quiz.options.map(option => (
              <div
                key={option.label}
                className={classNames(styles.option, {
                  [styles.correct]: view === 'reveal' && option.value,
                })}
              >
                {option.label}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default Quiz;
