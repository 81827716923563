import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { ERROR, LOADING, SUCCESS } from '@pumpkincare/portal-shared';
import { A, Alert, Body1, Button, Processing } from '@pumpkincare/portal-ui';
import {
  getVetSelfId,
  useVetLogOut,
  useVetSelf,
  useVetUser,
} from '@pumpkincare/portal-user';

import { CONTACT_EMAIL_LINK } from '../../constants';
import { resetStoreState } from '../../redux/reducer';
import { PATH_LOGIN } from '../../routing';
import {
  getAppResetPasswordError,
  getAppResetPasswordStatus,
} from '../../selectors';
import { resetAppResetPassword } from '../../state';
import changePassword from '../../thunks/change-password';
import ResetPasswordForm from '../reset-password-form';

import styles from './profile.css';

export default function Profile() {
  const dispatch = useDispatch();
  const logOut = useVetLogOut();

  const [isChangePassword, setChangePassword] = useState(false);
  const { isFetching: isSelfFetching, data: userId } = useVetSelf({
    select: getVetSelfId,
  });
  const { isFetching: isUserFetching, data: user } = useVetUser(userId);
  const passwordStatus = useSelector(getAppResetPasswordStatus);
  const passwordError = useSelector(getAppResetPasswordError);

  function handleSignOut() {
    logOut().then(() => {
      dispatch(resetStoreState());
      global.location.href = '/';
    });
  }

  const ERROR_MESSAGES = {
    NotAuthorizedException:
      'Apawlogies, this password doesn’t match our records. Please try again. Please note, if you enter your current password incorrectly more than 5 times, you will be temporarily timed out. ',
    LimitExceededException: (
      <>
        Ruh-Roh! Looks like you tried to enter the wrong password too many times and
        are temporarily timed out. Forgot your password?{' '}
        <Link to={PATH_LOGIN} onClick={handleSignOut}>
          Log out
        </Link>{' '}
        and click “Reset My Password” on the login page.
      </>
    ),
  };

  function handleSubmit({ password, currentPassword }) {
    dispatch(changePassword(password, currentPassword))
      .then(() => setChangePassword(false))
      .catch(() => {});
  }

  function handleTimer() {
    dispatch(resetAppResetPassword());
  }

  if (isSelfFetching || isUserFetching)
    return <Processing classes={{ container: styles.processing }} />;

  return (
    <>
      {!isChangePassword ? <h3 className={styles.header}>My Account</h3> : null}

      <div className={styles.container}>
        {isChangePassword ? (
          <ResetPasswordForm
            className={styles.resetForm}
            onSubmit={handleSubmit}
            isLoading={passwordStatus === LOADING}
            hasCurrentPassword
            hasError={passwordStatus === ERROR}
            errorMessage={ERROR_MESSAGES[passwordError?.code]}
          />
        ) : (
          <>
            <div className={styles.module}>
              {passwordStatus === SUCCESS ? (
                <Alert
                  type='success'
                  message='Your password has been updated.'
                  timer={5000}
                  onTimer={handleTimer}
                  classes={{ alert: styles.alert }}
                />
              ) : null}

              <Body1 className={styles.flex}>
                <span>Name:</span>
                <b>
                  {user.first_name} {user.last_name}
                </b>
              </Body1>

              <Body1 className={styles.flex}>
                <span>Email:</span>
                <b>{user.email}</b>
              </Body1>

              <Body1 className={styles.flex}>
                <span>Password:</span>
                <b>************</b>
              </Body1>

              <Button
                className={styles.change}
                onClick={() => setChangePassword(true)}
              >
                <Body1>
                  <b>Change</b>
                </Body1>
              </Button>
            </div>

            <Body1 className={styles.center}>
              Experiencing issues with your account?
            </Body1>
            <A href={CONTACT_EMAIL_LINK} className={styles.contact}>
              Contact the PawPortal Support Team
            </A>
          </>
        )}
      </div>
    </>
  );
}
