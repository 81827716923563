// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../../../../libs/shared/src/ui/breakpoints.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".patient-policy_inactive_894e275b {\n    opacity: 0.6;\n}\n\n.patient-policy_insuranceLink_ca1c8c7a {\n    flex: 100%;\n    font-size: 14px;\n    color: var(--royal);\n    margin-top: 10px;\n    text-align: left;\n    padding: 0;\n    min-height: 0;\n}\n\n@media " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["b-desktop"] + " {\n    .patient-policy_insuranceLink_ca1c8c7a {\n        flex: 1;\n        text-align: right;\n        margin-top: 0;\n    }\n}", "",{"version":3,"sources":["webpack://src/domains/patients/view/patient-policy/patient-policy.css"],"names":[],"mappings":"AAEA;IACI,YAAY;AAChB;;AAEA;IACI,UAAU;IACV,eAAe;IACf,mBAAmB;IACnB,gBAAgB;IAChB,gBAAgB;IAChB,UAAU;IACV,aAAa;AACjB;;AAEA;IACI;QACI,OAAO;QACP,iBAAiB;QACjB,aAAa;IACjB;AACJ","sourcesContent":["@value b-desktop from '~@pumpkincare/shared/ui/breakpoints';\n\n.inactive {\n    opacity: 0.6;\n}\n\n.insuranceLink {\n    flex: 100%;\n    font-size: 14px;\n    color: var(--royal);\n    margin-top: 10px;\n    text-align: left;\n    padding: 0;\n    min-height: 0;\n}\n\n@media b-desktop {\n    .insuranceLink {\n        flex: 1;\n        text-align: right;\n        margin-top: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"b-desktop": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["b-desktop"] + "",
	"inactive": "patient-policy_inactive_894e275b",
	"insuranceLink": "patient-policy_insuranceLink_ca1c8c7a"
};
export default ___CSS_LOADER_EXPORT___;
