import { CAT, DOG } from '@pumpkincare/portal-shared';

import bolt from '../../../../../../../public/assets/paw-portal/icons/bolt-filled.svg';
import bone from '../../../../../../../public/assets/paw-portal/icons/bone-filled.svg';
import comb from '../../../../../../../public/assets/paw-portal/icons/comb-filled.svg';
import heart from '../../../../../../../public/assets/paw-portal/icons/heart-filled.svg';
import kid from '../../../../../../../public/assets/paw-portal/icons/kid-reaching-filled.svg';
import paw from '../../../../../../../public/assets/paw-portal/icons/paw-filled.svg';
import speech from '../../../../../../../public/assets/paw-portal/icons/speech-filled.svg';
import tennis from '../../../../../../../public/assets/paw-portal/icons/tennis-filled.svg';
import yarn from '../../../../../../../public/assets/paw-portal/icons/yarn-filled.svg';
import catScooting from '../../../../../../../public/assets/paw-portal/illustrations/cat/scooting.png';
import dogScooting from '../../../../../../../public/assets/paw-portal/illustrations/dog/scooting.png';

export const rankingMapping = {
  1: 'Low',
  2: 'Moderately Low',
  3: 'Moderate',
  4: 'Moderately High',
  5: 'High',
};

const PLAYFULNESS = { title: 'Playfulness', key: 'playfulness', icon: yarn };
const ENERGY_LEVEL = { title: 'Energy Level', key: 'energy_level', icon: bolt };
const AFFECTION_LEVEL = {
  title: 'Affection Level',
  key: 'affection_level',
  icon: heart,
};
const GROOMING_EFFORT = {
  title: 'Grooming Effort',
  key: 'grooming_effort',
  icon: comb,
};
const PET_FRIENDLY = { title: 'Pet-Friendly', key: 'pet_friendly', icon: paw };
const KID_FRIENDLY = { title: 'Kid Friendly', key: 'kid_friendly', icon: kid };
const CHATTINESS = { title: 'Chattiness', key: 'chattiness', icon: speech };
const TRAINING_ABILITY = {
  title: 'Training Ability',
  key: 'training_ability',
  icon: bone,
};
const EXERCISE_NEEDS = {
  title: 'Exercise Needs',
  key: 'exercise_needs',
  icon: tennis,
};
const BARKING = { title: 'Barking', key: 'barking', icon: bolt };

export const getToKnowTraits = {
  [CAT]: [PLAYFULNESS, AFFECTION_LEVEL, PET_FRIENDLY, KID_FRIENDLY],
  [DOG]: [AFFECTION_LEVEL, PET_FRIENDLY, KID_FRIENDLY],
};

export function formatBreedName(breedName) {
  const mappings = {
    'Small Mix (up to 10 pounds)': 'small mixed-breed dog',
    'Small Mix (11-25 pounds)': 'small mixed-breed dog',
    'Medium Mix (26-50 pounds)': 'medium mixed-breed dog',
    'Large Mix (51-100 pounds)': 'large mixed-breed dog',
    'Giant Mix (over 100 pounds)': 'large mixed-breed dog',
    'Mixed Cat': 'small mixed-breed cat',
  };

  return mappings[breedName] || breedName;
}

export const planAheadAside = {
  [CAT](petName) {
    return [
      'From breed-specific conditions to broken bones, cats can get sick or hurt unexpectedly at any stage of life.',
      'One of the best things to do is enroll in a pet insurance plan when they’re young and healthy, before surprise ruh-rohs happen!',
      <b key='help-cat'>{`See how a Pumpkin Cat Insurance plan can help ${petName} get the best care.`}</b>,
    ];
  },
  [DOG](petName) {
    return [
      'From breed-specific conditions to broken bones, pups can get sick or hurt unexpectedly at any stage of life.',
      'One of the best things to do is enroll in a pet insurance plan when they’re young and healthy, before surprise ruh-rohs happen!',
      <b key='help-dog'>{`See how a Pumpkin Dog Insurance plan can help ${petName} get the best care.`}</b>,
    ];
  },
};

export const careTipsTraits = {
  [CAT]: [ENERGY_LEVEL, GROOMING_EFFORT, CHATTINESS],
  [DOG]: [TRAINING_ABILITY, EXERCISE_NEEDS, GROOMING_EFFORT, BARKING],
};

export const behavioralAside = {
  [CAT]: [
    'Any cat can develop a behavioral issue during their life, such as separation anxiety, house soiling, or excessive licking',
    'A veterinarian can help diagnose and treat these conditions',
    'Unlike some other pet insurance plans, Pumpkin plans cover eligible treatment and Rx medication for behavorial problems',
  ],
  [DOG]: [
    'Any dog can develop a behavioral issue during their life, such as separation anxiety, excessive licking, or aggression',
    'A veterinarian can help diagnose and treat these conditions',
    'Unlike some other pet insurance plans, Pumpkin plans cover eligible treatment and Rx medication for behavioral problems',
  ],
};

export const seeTheDifference = {
  [CAT]: {
    caseCopy(petName) {
      return `Cats usually land on their feet, but leg fractures are still common. Say ${petName} falls off a ledge and fractures their rear leg. Your vet recommends surgery for ${petName} and rehabilitation therapy to help speed up recovery.`;
    },
    img: catScooting,
    finalCost: '$4,775',
    pumpkinPays: '$4,297.50',
    youPay: '$477.50',
  },
  [DOG]: {
    caseCopy(petName) {
      return `${petName} accidentally swallowed a foreign object, like a pair of your socks! As the fabric could unravel and cause all sorts of damage, your vet needs to perform emergency stomach surgery to remove the socks as soon as possible.`;
    },
    img: dogScooting,
    finalCost: '$2,920',
    pumpkinPays: '$2,628',
    youPay: '$292',
  },
};
