const API = 'API';

export function apiGet(payload) {
  return {
    type: API,
    payload: {
      ...payload,
      method: 'GET',
    },
  };
}

export function apiPost(payload) {
  return {
    type: API,
    payload: {
      ...payload,
      method: 'POST',
    },
  };
}

export function apiPut(payload) {
  return {
    type: API,
    payload: {
      ...payload,
      method: 'PUT',
    },
  };
}

export function apiDelete(payload) {
  return {
    type: API,
    payload: {
      ...payload,
      method: 'DELETE',
    },
  };
}
