import classNames from 'classnames';

import { useFrames } from '@pumpkincare/portal-app';
import {
  Button,
  ButtonSecondary,
  FileInput,
  SubText1,
} from '@pumpkincare/portal-ui';
import { getVetSelfVetId, useVetSelf } from '@pumpkincare/portal-user';

import styles from './frame-form.css';

import refresh from '../../../../../../../public/assets/paw-portal/icons/refresh-cw.svg';

export default function FrameForm({
  frameKey,
  imageSrc,
  handleFrameOptionClick,
  onImageChange,
  goToNextStep,
}) {
  const { data: vetId } = useVetSelf({ select: getVetSelfVetId });
  const { data: framesData } = useFrames(vetId);
  const frameSrc = framesData.find(frame => frame.frame_type === frameKey)?.url;

  function handleButtonClick() {
    goToNextStep();
  }

  return (
    <>
      <h4 className={styles.title}>Choose Your Theme</h4>

      <div className={styles.content}>
        <div className={styles.imageAction}>
          <div className={styles.imageContainer}>
            <img src={frameSrc} className={styles.frame} />
            <div
              style={{ backgroundImage: `url(${imageSrc})` }}
              className={styles.userPhoto}
            />
          </div>

          <FileInput
            classes={{ container: styles.retake }}
            handleImageChange={onImageChange}
          >
            <img
              src={refresh}
              className={styles.icon}
              alt='change photo'
              role='presentation'
            />
            Change Photo
          </FileInput>
        </div>

        <div className={styles.carouselContainer}>
          <div className={styles.carousel}>
            {framesData.map(frame => {
              const isSelected = frame.frame_type === frameKey;
              const src = isSelected ? frame.icon_active : frame.icon_inactive;

              return (
                <Button
                  key={frame.icon_alt}
                  className={styles.option}
                  onClick={() => handleFrameOptionClick(frame.frame_type)}
                >
                  <img
                    src={src}
                    alt={frame.icon_alt}
                    role='presentation'
                    className={styles.optionIcon}
                  />
                  <SubText1
                    className={classNames(styles.optionText, {
                      [styles.disabledText]: !isSelected,
                    })}
                  >
                    {frame.icon_text}
                  </SubText1>
                </Button>
              );
            })}
          </div>
        </div>
      </div>

      <ButtonSecondary onClick={handleButtonClick} className={styles.submit}>
        Next: Enter Recipient Details
      </ButtonSecondary>
    </>
  );
}
