import { trackCustomerPEP } from '@pumpkincare/analytics';
import { A, Body1, ButtonPrimary } from '@pumpkincare/portal-ui';

import { PATH_EXT_QUOTE_FLOW } from '../../../app';
import { PP_BASE_UTM_PARAMS } from '../../constants';

import styles from './signing-up.css';

import step1 from '../../../../../../../public/assets/paw-portal/illustrations/misc/step-1.png';
import step2 from '../../../../../../../public/assets/paw-portal/illustrations/misc/step-2.png';
import step3 from '../../../../../../../public/assets/paw-portal/illustrations/misc/step-3.png';

export default function SigningUp({ pepVersion }) {
  return (
    <div>
      <div className={styles.container}>
        <h3>Signing up for Pumpkin is easy!</h3>

        <div className={styles.steps}>
          <div className={styles.step}>
            <img src={step1} alt='' />
            <Body1>
              Choose a Pumpkin insurance plan that's right for you and your pet
            </Body1>
          </div>
          <div className={styles.step}>
            <img src={step2} alt='' />
            <Body1>
              Add an optional Preventive Essentials package to your Pumpkin insurance
              plan
            </Body1>
          </div>
          <div className={styles.step}>
            <img src={step3} alt='' />
            <Body1>
              Get paid back for covered vet bills via direct deposit or check
            </Body1>
          </div>
        </div>

        <A
          href={`${PATH_EXT_QUOTE_FLOW}?${PP_BASE_UTM_PARAMS}${pepVersion}`}
          onClick={() =>
            trackCustomerPEP('Clicked Create Plan', pepVersion, 'Bottom of page')
          }
          target='_blank'
        >
          <ButtonPrimary className={styles.button}>
            Create Your Pet's Plan
          </ButtonPrimary>
        </A>
      </div>
    </div>
  );
}
