import { apiPost, setAppResetPassword } from '../state';

export default function checkUserToken(token, type) {
  return dispatch => {
    return dispatch(
      apiPost({
        url: `/users/check-token?token=${token}&type=${type}`,
        options: {
          onLoading: setAppResetPassword,
          onError: setAppResetPassword,
        },
      })
    );
  };
}
