// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../../../../libs/shared/src/ui/breakpoints.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".patient-form_title_efbab3b8 {\n    text-align: center;\n    margin-top: 48px;\n}\n\n.patient-form_subtext_4b92bee3 {\n    margin-top: 20px;\n    margin-bottom: 48px;\n    text-align: center;\n    opacity: 1;\n}\n\n.patient-form_asterisk_5f1eeb0d {\n    color: var(--redAlert);\n}\n\n.patient-form_field_537e35ed {\n    margin-top: 16px;\n}\n\n.patient-form_checkbox_a32f4232 {\n    margin-top: 44px;\n    align-items: flex-start;\n}\n\n.patient-form_submit_e18217af {\n    width: 250px;\n    margin: 40px auto;\n    display: block;\n}\n\n.patient-form_box_0411b313 {\n    top: 4px;\n}\n\n@media " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["b-desktop"] + " {\n    .patient-form_container_008d1a2b {\n        width: 350px;\n        margin: auto;\n    }\n\n    .patient-form_title_efbab3b8 {\n        margin-top: 24px;\n    }\n}", "",{"version":3,"sources":["webpack://src/domains/visit/view/patient-form/patient-form.css"],"names":[],"mappings":"AAEA;IACI,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,kBAAkB;IAClB,UAAU;AACd;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,uBAAuB;AAC3B;;AAEA;IACI,YAAY;IACZ,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,QAAQ;AACZ;;AAEA;IACI;QACI,YAAY;QACZ,YAAY;IAChB;;IAEA;QACI,gBAAgB;IACpB;AACJ","sourcesContent":["@value b-desktop from '~@pumpkincare/shared/ui/breakpoints';\n\n.title {\n    text-align: center;\n    margin-top: 48px;\n}\n\n.subtext {\n    margin-top: 20px;\n    margin-bottom: 48px;\n    text-align: center;\n    opacity: 1;\n}\n\n.asterisk {\n    color: var(--redAlert);\n}\n\n.field {\n    margin-top: 16px;\n}\n\n.checkbox {\n    margin-top: 44px;\n    align-items: flex-start;\n}\n\n.submit {\n    width: 250px;\n    margin: 40px auto;\n    display: block;\n}\n\n.box {\n    top: 4px;\n}\n\n@media b-desktop {\n    .container {\n        width: 350px;\n        margin: auto;\n    }\n\n    .title {\n        margin-top: 24px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"b-desktop": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["b-desktop"] + "",
	"title": "patient-form_title_efbab3b8",
	"subtext": "patient-form_subtext_4b92bee3",
	"asterisk": "patient-form_asterisk_5f1eeb0d",
	"field": "patient-form_field_537e35ed",
	"checkbox": "patient-form_checkbox_a32f4232",
	"submit": "patient-form_submit_e18217af",
	"box": "patient-form_box_0411b313",
	"container": "patient-form_container_008d1a2b"
};
export default ___CSS_LOADER_EXPORT___;
