import { useState } from 'react';

import {
  useTargetState,
  useToggleState,
  validateEmail,
} from '@pumpkincare/portal-shared';
import {
  ButtonSecondary,
  Checkbox,
  SubText1,
  TextField,
} from '@pumpkincare/portal-ui';

import styles from './patient-form.css';

const errors = {
  email: 'Please enter a valid email to continue',
  firstName: 'Please enter a value to continue',
  lastName: 'Please enter a value to continue',
  petName: 'Please enter a value to continue',
};

function PatientForm({ goToNextStep, patientData }) {
  const [firstName, setFirstName, isFirstNameValid] = useTargetState(
    patientData.current.firstName
  );
  const [lastName, setLastName, isLastNameValid] = useTargetState(
    patientData.current.lastName
  );
  const [email, setEmail, isEmailValid] = useTargetState(
    patientData.current.email,
    validateEmail
  );
  const [petName, setPetName, isPetNameValid] = useTargetState(
    patientData.current.petName
  );
  const [areErrorsVisible, setErrorsVisible] = useState(false);
  const [permitted, togglePermission] = useToggleState(false);

  function setCurrent() {
    patientData.current = {
      firstName,
      lastName,
      email,
      petName,
    };
  }

  function handleSubmit(e) {
    e.preventDefault();

    if (
      !(
        isFirstNameValid &&
        isLastNameValid &&
        isPetNameValid &&
        isEmailValid &&
        permitted
      )
    ) {
      setErrorsVisible(true);
      return;
    }

    setCurrent();
    goToNextStep();
  }

  return (
    <div className={styles.container}>
      <h4 className={styles.title}>Enter Recipient Details</h4>
      <SubText1 className={styles.subtext}>
        <span className={styles.asterisk}>*</span>
        Required Field
      </SubText1>

      <form onSubmit={handleSubmit} noValidate>
        <TextField
          id='client-first'
          value={firstName}
          label='Client First Name'
          onChange={setFirstName}
          classes={{ container: styles.field }}
          error={{
            errorMessage: areErrorsVisible && !isFirstNameValid && errors.firstName,
          }}
          onBlur={setCurrent}
          required
        />

        <TextField
          id='client-last'
          value={lastName}
          label='Client Last Name'
          onChange={setLastName}
          classes={{ container: styles.field }}
          error={{
            errorMessage: areErrorsVisible && !isLastNameValid && errors.lastName,
          }}
          onBlur={setCurrent}
          required
        />

        <TextField
          id='client-email'
          value={email}
          label='Client Email'
          onChange={setEmail}
          classes={{ container: styles.field }}
          error={{ errorMessage: areErrorsVisible && !isEmailValid && errors.email }}
          onBlur={setCurrent}
          required
        />

        <TextField
          id='pet-name'
          value={petName}
          label='Pet Name'
          onChange={setPetName}
          classes={{ container: styles.field }}
          error={{
            errorMessage: areErrorsVisible && !isPetNameValid && errors.petName,
          }}
          onBlur={setCurrent}
          required
        />

        <Checkbox
          id='permission'
          label="Yep, I got permission from my client to take, send, and share photos of their pet from today's visit"
          onChange={togglePermission}
          checked={permitted}
          classes={{ container: styles.checkbox, box: styles.box }}
          isErrorVisible={areErrorsVisible}
          required
        />

        <ButtonSecondary type='submit' className={styles.submit}>
          Next: Preview & Send
        </ButtonSecondary>
      </form>
    </div>
  );
}

export default PatientForm;
