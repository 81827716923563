import { useRef } from 'react';
import classNames from 'classnames';

import {
  formatCurrency,
  USER_AGENTS,
  useToggleState,
} from '@pumpkincare/portal-shared';
import { A, Body1, BodySubhead } from '@pumpkincare/portal-ui';

import styles from './pep-card.css';

import arrowIcon from '../../../../../../../public/assets/paw-portal/icons/arrow-down.svg';
import checkIcon from '../../../../../../../public/assets/paw-portal/icons/check-green.svg';
import starIcon from '../../../../../../../public/assets/paw-portal/illustrations/misc/star.png';

const ages = {
  0: '8 weeks',
  5: '6 months',
  6: '6 months',
  11: '11 months',
  12: '1 year',
  999: 'older',
};
export function formatPlanAge(months) {
  if (ages[months]) return ages[months];

  // for rounding N years 11 months to N + 1 years;
  const modifier = months % 12 === 11 ? 1 : 0;

  return `${(months + modifier) / 12} years`;
}

const parasIds = {
  dog: '#simparica-savings',
  cat: '#revolution-savings',
};

export default function PEPCard({ plan }) {
  const [isExpanded, toggleIsExpanded] = useToggleState(false);
  const el = useRef(null);

  function handleExpansion() {
    const minHeight = USER_AGENTS.desktop ? 240 : 264;
    const paddingBottom = USER_AGENTS.desktop ? 52 : 44;

    if (isExpanded) {
      el.current.style.maxHeight = `${minHeight}px`;
    } else {
      el.current.style.maxHeight = `${
        el.current.children[0].clientHeight +
        el.current.children[1].clientHeight +
        paddingBottom
      }px`;
    }

    toggleIsExpanded();
  }

  const minAge = formatPlanAge(plan.min_age_months);
  const maxAge = formatPlanAge(plan.max_age_months);
  const conjunction = maxAge === 'older' ? 'and' : 'to';

  return (
    <div
      ref={el}
      className={classNames(styles.container, {
        [styles.expandedContainer]: isExpanded,
      })}
    >
      <div className={styles.content}>
        <div className={styles.ages}>
          <img src={starIcon} alt='' />
          <h4 className={styles.h4}>
            {minAge} {conjunction} {maxAge}
          </h4>
        </div>

        <div className={styles.entitlements}>
          {plan.entitlements.map(({ category, items }) => {
            return (
              <div key={category}>
                <BodySubhead className={styles.category}>{category}</BodySubhead>
                <ul>
                  {items.map(item => (
                    <li key={category + item.name}>
                      <img src={checkIcon} alt='' />{' '}
                      <span
                        dangerouslySetInnerHTML={{
                          __html: `${item.quantity} ${item.description}`,
                        }}
                      />
                    </li>
                  ))}
                </ul>
              </div>
            );
          })}
        </div>

        <div className={styles.cost}>
          <h4 className={styles.h4}>+ {formatCurrency(plan.cost)} / mo</h4>
          <Body1 className={styles.addon}>add-on to insurance</Body1>
          {plan.savings ? (
            <Body1 className={styles.savings}>
              <b>
                Your clients may save over <span>${plan.savings}/yr</span> on this
                package!
                <A href={parasIds[plan.target]}>*</A>
              </b>
            </Body1>
          ) : null}
        </div>
      </div>
      <button
        type='button'
        className={classNames(styles.control, {
          [styles.expandedControl]: isExpanded,
        })}
        onClick={handleExpansion}
      >
        Show {isExpanded ? 'Less' : 'More'} <img src={arrowIcon} alt='' />
      </button>
    </div>
  );
}
