import { Fragment } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './stepper.css';

function Stepper({ steps, activeIndex, classes = {} }) {
  return (
    <div className={classNames(styles.root, classes.root)}>
      {steps.map(({ icon, text }, index) => (
        <Fragment key={text}>
          <div className={styles.step}>
            <img src={icon} alt='' className={classNames(classes.img)} />
            <p
              className={classNames({
                [styles.active]: index <= activeIndex,
              })}
            >
              {text}
            </p>
          </div>

          {index !== steps.length - 1 ? (
            <span className={styles.divider} role='separator' />
          ) : null}
        </Fragment>
      ))}
    </div>
  );
}

Stepper.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    img: PropTypes.string,
  }),

  steps: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string.isRequired,
      text: PropTypes.node.isRequired,
    })
  ).isRequired,
  activeIndex: PropTypes.number.isRequired,
};

export default Stepper;
