import { Route, Switch } from 'react-router-dom';

import { PATH_CUSTOMER_BASE, PATH_CUSTOMER_VISIT } from '../../../app';
import CustomerVisit from './customer-visit';

export default function CustomerVisitWrapper() {
  return (
    <Switch>
      <Route
        path={`${PATH_CUSTOMER_BASE}/:visitId`}
        render={() => (
          <Switch>
            <Route
              path={`${PATH_CUSTOMER_BASE}/:visitId/vets`}
              render={() => (
                <Switch>
                  <Route
                    path={PATH_CUSTOMER_VISIT}
                    render={() => <CustomerVisit />}
                  />
                </Switch>
              )}
            />
          </Switch>
        )}
      />
    </Switch>
  );
}
