import { Component } from 'react';

import ErrorPage from '../error-page';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    const { isPrivate, itemRenderer, children } = this.props;
    const { hasError } = this.state;

    return hasError ? (
      <ErrorPage content={itemRenderer} isPrivate={isPrivate} />
    ) : (
      children
    );
  }
}

export default ErrorBoundary;
