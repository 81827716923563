import { apiGet } from '../../app';

export default function downloadPolicy(policyId) {
  return dispatch => {
    return dispatch(
      apiGet({
        url: `/patients/policy-url/${policyId}`,
        options: {
          isAuth: true,
        },
      })
    ).then(data => {
      window.open(data.url, '_blank');
    });
  };
}
