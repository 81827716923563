export const getAllClaims = state => state.patients.allClaims.data;
export const getAllClaimsStatus = state => state.patients.allClaims.status;
export const getAllClaimsList = state => state.patients.allClaims.data?.claims;
export const getAllClaimsTotal = state =>
  state.patients.allClaims.data?.total_claims;

export const getAllPatients = state => state.patients.allPatients.data;
export const getAllPatientsStatus = state => state.patients.allPatients.status;
export const getAllPatientsList = state => state.patients.allPatients.data?.patients;
export const getAllPatientsTotal = state =>
  state.patients.allPatients.data?.total_patients;

export const getPatientsSearch = state => state.patients.search.data;
export const getPatientsSearchResults = state =>
  state.patients.search.data?.patients;
export const getPatientsSearchTotal = state =>
  state.patients.search.data?.total_patients;
export const getPatientsSearchStatus = state => state.patients.search.status;

export const getPatient = state => state.patients.patient.data;
export const getPatientStatus = state => state.patients.patient.status;

export const getPatientsPets = state => state.patients.pets.data;

export const getPatientsPetsRecentClaims = state => state.patients.recentClaims.data;
