import { useState } from 'react';

import { trackPhotoCardGalleryDownload } from '@pumpkincare/analytics';
import {
  formatISODate,
  USER_AGENTS,
  useToggleState,
} from '@pumpkincare/portal-shared';
import {
  A,
  Body1,
  BodySubhead,
  ButtonPrimary,
  ButtonSecondary,
  Modal,
  Processing,
  ToggleButton,
} from '@pumpkincare/portal-ui';

import styles from './photo-card-modal.css';

import calendar from '../../../../../../../public/assets/paw-portal/icons/calendar.svg';
import download from '../../../../../../../public/assets/paw-portal/icons/download.svg';
import mail from '../../../../../../../public/assets/paw-portal/icons/mail.svg';

const WITH_FRAME = 'With Frame';
const WITHOUT_FRAME = 'Without Frame';

export default function PhotoCardModal({ data: { photo, visit }, onClose }) {
  const isDesktop = USER_AGENTS.desktop;
  const [image, setImage] = useState({
    isFramed: true,
    src: photo.merged_signed_url,
  });
  const [isLoading, toggleIsLoading] = useToggleState(true);

  function handleImageDownload(type) {
    trackPhotoCardGalleryDownload(
      visit.id,
      type || image.isFramed ? WITH_FRAME : WITHOUT_FRAME
    );
  }

  function handleToggle({ value }) {
    setImage(value);
    toggleIsLoading(true);
  }

  return (
    <Modal
      onClose={onClose}
      classes={{ container: styles.modal, content: styles.modalContent }}
    >
      <div className={styles.container}>
        {!isDesktop ? (
          <>
            <div className={styles.toggles}>
              <ToggleButton
                label='With Frame'
                clickHandler={handleToggle}
                value={{ isFramed: true, src: photo.merged_signed_url }}
                selected={image.isFramed}
                classes={{ container: styles.leftButton }}
              />
              <ToggleButton
                label='Photo Only'
                clickHandler={handleToggle}
                value={{ isFramed: false, src: photo.signed_url }}
                selected={!image.isFramed}
                classes={{ container: styles.rightButton }}
              />
            </div>
            <BodySubhead className={styles.mobileHeader}>
              Tap & hold the image below to download
            </BodySubhead>
          </>
        ) : null}

        <div style={{ position: 'relative' }}>
          <img
            src={image.src}
            alt={`Photo of ${visit.pet_name}`}
            className={styles.image}
            onLoad={toggleIsLoading}
            onTouchStart={() => handleImageDownload()}
          />
          {isLoading ? (
            <div className={styles.overlay}>
              <Processing
                classes={{ container: styles.loading, ring: styles.ring }}
              />
            </div>
          ) : null}
        </div>

        <div className={styles.data}>
          <h3 style={{ marginBottom: '24px' }}>{visit.pet_name}</h3>
          <div style={{ marginBottom: '16px' }} className={styles.infoContainer}>
            <img
              src={calendar}
              alt='calendar icon'
              role='presentation'
              className={styles.icon}
            />
            <Body1 className={styles.text}>
              <b>{formatISODate(visit.created_at)}</b>
            </Body1>
          </div>
          <div style={{ marginBottom: '48px' }} className={styles.infoContainer}>
            <img
              src={mail}
              alt='mail icon'
              role='presentation'
              className={styles.icon}
            />
            <Body1 className={styles.text}>
              <b>
                {visit.client_first_name} {visit.client_last_name}
              </b>
              <br />
              {visit.client_email}
            </Body1>
          </div>

          {isDesktop ? (
            <>
              <A href={photo.merged_signed_url} download>
                <ButtonPrimary
                  className={styles.button}
                  onClick={() => handleImageDownload(WITH_FRAME)}
                >
                  <img
                    src={download}
                    role='presentation'
                    className={styles.primaryIcon}
                  />
                  Download with Frame
                </ButtonPrimary>
              </A>
              <A href={photo.signed_url} download>
                <ButtonSecondary
                  className={styles.button}
                  onClick={() => handleImageDownload(WITHOUT_FRAME)}
                >
                  <img
                    src={download}
                    role='presentation'
                    className={styles.secondaryIcon}
                  />
                  Download Image Only
                </ButtonSecondary>
              </A>
            </>
          ) : null}
        </div>
      </div>
    </Modal>
  );
}
