import { NavBarProvider } from '@pumpkincare/portal-app';

import MainSwitch from '../../routing/main-switch';

function App() {
  return (
    <NavBarProvider>
      <div
        style={{
          margin: 'auto',
          backgroundColor: 'var(--zeroWhite)',
          fontFamily: 'var(--main-font)',
          position: 'relative',
        }}
      >
        <MainSwitch />
      </div>
    </NavBarProvider>
  );
}

export default App;
