import { useQuery } from 'react-query';

import { fetchWrapper } from '@pumpkincare/portal-shared';

export default function usePrintouts(vetId) {
  return useQuery(
    ['vetPrintouts', vetId],

    async () => fetchWrapper(`/vets/${vetId}/printout`),

    {
      enabled: !!vetId,
      placeholderData: {},
      staleTime: 1000 * 60 * 60, // 1 hour in milliseconds
    }
  );
}
