import { asyncInitialState, asyncReduxCreator } from '@pumpkincare/portal-shared';

const [SET_PATIENT, SET_PATIENT_ERROR, SET_PATIENT_SUCCESS, setPatient] =
  asyncReduxCreator('SET_PATIENT');
const [
  SET_ALL_PATIENTS,
  SET_ALL_PATIENTS_ERROR,
  SET_ALL_PATIENTS_SUCCESS,
  setAllPatients,
] = asyncReduxCreator('SET_ALL_PATIENTS');
const [
  SET_PATIENT_PET,
  SET_PATIENT_PET_ERROR,
  SET_PATIENT_PET_SUCCESS,
  setPatientPet,
] = asyncReduxCreator('SET_PATIENT_PET');
const [
  SET_PATIENTS_SEARCH,
  SET_PATIENTS_SEARCH_ERROR,
  SET_PATIENTS_SEARCH_SUCCESS,
  setPatientsSearch,
] = asyncReduxCreator('SET_PATIENTS_SEARCH');
const [
  UPDATE_PATIENTS_SEARCH,
  UPDATE_PATIENTS_SEARCH_ERROR,
  UPDATE_PATIENTS_SEARCH_SUCCESS,
  updatePatientsSearch,
] = asyncReduxCreator('UPDATE_PATIENTS_SEARCH');
const [
  SET_PATIENT_PET_RECENT_CLAIMS,
  SET_PATIENT_PET_RECENT_CLAIMS_ERROR,
  SET_PATIENT_PET_RECENT_CLAIMS_SUCCESS,
  setPatientPetRecentClaims,
] = asyncReduxCreator('SET_PATIENT_PET_RECENT_CLAIMS');
const [SET_ALL_CLAIMS, SET_ALL_CLAIMS_ERROR, SET_ALL_CLAIMS_SUCCESS, setAllClaims] =
  asyncReduxCreator('SET_ALL_CLAIMS');

const patientsInitialState = {
  allClaims: asyncInitialState(null),
  allPatients: asyncInitialState({}),
  patient: asyncInitialState(null),
  pets: asyncInitialState({}),
  search: asyncInitialState({}),
  recentClaims: asyncInitialState({}),
};

export default function patientsReducer(
  state = patientsInitialState,
  { payload, type } = {}
) {
  switch (type) {
    case SET_PATIENT:
    case SET_PATIENT_SUCCESS:
    case SET_PATIENT_ERROR:
      return { ...state, patient: { ...state.patient, ...payload } };

    case SET_ALL_PATIENTS:
    case SET_ALL_PATIENTS_SUCCESS:
    case SET_ALL_PATIENTS_ERROR:
      return { ...state, allPatients: { ...state.allPatients, ...payload } };

    case SET_PATIENT_PET:
    case SET_PATIENT_PET_ERROR:
      return { ...state, pets: { ...state.pets, ...payload } };

    case SET_PATIENT_PET_SUCCESS:
      return {
        ...state,
        pets: {
          ...state.pets,
          ...payload,
          data: {
            ...state.pets.data,
            [payload.data.id || payload.data.name]: payload.data,
          },
        },
      };

    case SET_PATIENT_PET_RECENT_CLAIMS:
    case SET_PATIENT_PET_RECENT_CLAIMS_ERROR:
      return { ...state, recentClaims: { ...state.recentClaims, ...payload } };

    case SET_PATIENT_PET_RECENT_CLAIMS_SUCCESS:
      return {
        ...state,
        recentClaims: {
          ...state.recentClaims,
          ...payload,
          data: {
            ...state.recentClaims.data,
            [payload.data.pet_id]: payload.data.claims,
          },
        },
      };

    case SET_PATIENTS_SEARCH:
    case SET_PATIENTS_SEARCH_SUCCESS:
    case SET_PATIENTS_SEARCH_ERROR:
      return { ...state, search: { ...state.search, ...payload } };

    case UPDATE_PATIENTS_SEARCH:
    case UPDATE_PATIENTS_SEARCH_SUCCESS:
    case UPDATE_PATIENTS_SEARCH_ERROR:
      return {
        ...state,
        search: {
          ...state.search,
          ...payload,
          data: {
            patients: [
              ...(state.search?.data?.patients || []),
              ...(payload.data?.patients || []),
            ],
            total_patients: payload.data?.total_patients,
          },
        },
      };

    case SET_ALL_CLAIMS:
    case SET_ALL_CLAIMS_SUCCESS:
    case SET_ALL_CLAIMS_ERROR:
      return { ...state, allClaims: { ...state.allClaims, ...payload } };

    default:
      return state;
  }
}

export {
  setPatient,
  setAllClaims,
  setAllPatients,
  setPatientPet,
  setPatientsSearch,
  updatePatientsSearch,
  setPatientPetRecentClaims,
};
