import { fetchAuthSession, updatePassword } from 'aws-amplify/auth';

import { ERROR, LOADING, SUCCESS } from '@pumpkincare/portal-shared';

import { setAppResetPassword } from '../state';

export default function changePassword(newPassword, oldPassword) {
  return dispatch => {
    dispatch(setAppResetPassword({ status: LOADING, error: null }));

    return fetchAuthSession()
      .then(() => updatePassword({ oldPassword, newPassword }))
      .then(() => dispatch(setAppResetPassword({ status: SUCCESS }, SUCCESS)))
      .catch(error => {
        dispatch(setAppResetPassword({ status: ERROR, error }, ERROR));
        throw error;
      });
  };
}
