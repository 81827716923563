import { Fragment } from 'react';

import { Accordion } from '@pumpkincare/portal-ui';

import { rankingMapping } from '../constants';

import styles from './watchouts.css';

import cost from '../../../../../../../../public/assets/paw-portal/icons/cost-filled.svg';
import heartbeat from '../../../../../../../../public/assets/paw-portal/icons/heartbeat-filled.svg';
import lifecycle from '../../../../../../../../public/assets/paw-portal/icons/lifecycle.svg';
import scale from '../../../../../../../../public/assets/paw-portal/icons/scale-filled.svg';
import source from '../../../../../../../../public/assets/paw-portal/icons/source.svg';

export function isPetMixed(petBreedName) {
  return [
    'Mixed Cat',
    'Domestic Shorthair',
    'Domestic Mediumhair',
    'Domestic Longhair',
    'Small Mix (up to 10 pounds)',
    'Small Mix (11-25 pounds)',
    'Medium Mix (26-50 pounds)',
    'Large Mix (51-100 pounds)',
    'Giant Mix (over 100 pounds)',
  ].some(breed => breed === petBreedName);
}

export function formatCopy(
  { petBreedName, petName },
  vetName = 'your veterinarian'
) {
  const note = `This data is not specific to ${petName} or costs at ${vetName}.`;

  const defaultCopy = {
    header: `Health watchouts for your ${petBreedName}`,
    subheader: `All ${petBreedName}s have specific health risks, even if they’re currently healthy and came from a reputable breeder or shelter. Here are some helpful stats and common health issues to look out for in ${petBreedName}s, plus how Pumpkin insurance plans can help you give ${petName} the best care for unexpected accidents and illnesses. ${note}`,
  };

  if (petBreedName === 'Mixed Cat')
    return {
      ...defaultCopy,
      header: 'Health watchouts for your mixed-breed cat',
      subheader: `While mixed-breed cats tend to be more healthy, there are still specific health risks that ${petName} and all mixed cat breeds can be susceptible to. Here are some common health issues to look out for, plus how Pumpkin insurance plans can help you give ${petName} the best care for unexpected accidents and illnesses. ${note}`,
    };

  if (
    ['Domestic Shorthair', 'Domestic Mediumhair', 'Domestic Longhair'].some(
      breed => breed === petBreedName
    )
  )
    return {
      header: defaultCopy.header,
      subheader: `Like all cat breeds, there are some specific health risks that ${petName}  and all ${petBreedName} cats may be susceptible to. Here are some common health issues to look out for, plus how Pumpkin insurance plans can help you give ${petName} the best care for unexpected accidents and illnesses. ${note}`,
    };

  if (
    [
      'Small Mix (up to 10 pounds)',
      'Small Mix (11-25 pounds)',
      'Medium Mix (26-50 pounds)',
      'Large Mix (51-100 pounds)',
      'Giant Mix (over 100 pounds)',
    ].some(breed => breed === petBreedName)
  ) {
    const first = petBreedName.split(' ')[0].toLowerCase();
    const breed = first === 'giant' ? 'large' : first;
    return {
      header: `Get to know your ${breed} mixed-breed dog`,
      subheader: `All ${breed} mixed-breed dogs have specific health risks, even if they’re currently healthy. Here are some common health issues to look out for, plus how Pumpkin insurance plans can help you give ${petName} the best care for unexpected accidents and illnesses. ${note}`,
    };
  }

  return defaultCopy;
}

function Watchouts({ pet, vetName, breedReport }) {
  const { header, subheader } = formatCopy(pet, vetName);
  const { petBreedName } = pet;
  const {
    breed_characteristics: breedCharacteristics,
    about,
    conditions,
  } = breedReport;
  const isMixed = isPetMixed(petBreedName);

  return (
    <div className={styles.root}>
      <h2>{header}</h2>
      <p className={styles.subheader}>{subheader}</p>
      {isMixed ? null : (
        <>
          <div className={styles.about}>
            <div className={styles.aboutItem}>
              <img alt='' src={heartbeat} />
              <p className={styles.itemTitle}>Health Issues</p>
              <p className={styles.itemSubtitle}>
                {rankingMapping[breedCharacteristics.health_issues]}
              </p>
            </div>
            <div className={styles.aboutItem}>
              <img alt='' src={scale} />
              <p className={styles.itemTitle}>Weight Range</p>
              <p className={styles.itemSubtitle}>{about.weight_range}</p>
            </div>

            <div className={styles.aboutItem}>
              <img alt='' src={lifecycle} />
              <p className={styles.itemTitle}>Average Life Span</p>
              <p className={styles.itemSubtitle}>{about.life_span}</p>
            </div>

            <div className={styles.aboutItem}>
              <img alt='' src={cost} />
              <p className={styles.itemTitle}>Lifetime Care Cost</p>
              <p className={styles.itemSubtitle}>{about.lifetime_care_cost}</p>
            </div>
          </div>
          <h3>{`Common conditions in ${petBreedName}`}</h3>
        </>
      )}
      <div className={styles.conditions}>
        {conditions.map(condition => {
          return (
            <Fragment key={condition.name}>
              <div className={styles.mobile}>
                <div className={styles.condition}>
                  <Accordion
                    staticContent={condition.name}
                    classes={{
                      container: styles.accordionContainer,
                      header: styles.accordionHeader,
                      content: styles.accordionContent,
                      row: styles.accordionRow,
                    }}
                  >
                    <div>
                      <p>{condition.copy}</p>
                      <a target='_blank' rel='noreferrer' href={condition.source}>
                        View Source
                      </a>
                    </div>
                  </Accordion>
                </div>
                <div className={styles.prices}>
                  <a target='_blank' rel='noreferrer' href={condition.source}>
                    Cost to treat: {condition.cost_to_treat}
                  </a>
                  <p>
                    Pumpkin plan covers<sup>*</sup>: {condition.pumpkin_covers}
                  </p>
                </div>
              </div>
              <div className={styles.desktop}>
                <div className={styles.conditionContent}>
                  <p className={styles.conditionHeader}>{condition.name}</p>
                  <div className={styles.conditionBody}>
                    <p>{condition.copy}</p>
                    <a target='_blank' rel='noreferrer' href={condition.source}>
                      View Source
                    </a>
                  </div>
                  <div className={styles.prices}>
                    <a target='_blank' rel='noreferrer' href={condition.source}>
                      Cost to treat: {condition.cost_to_treat}
                      <img alt='' src={source} />
                    </a>
                    <p>
                      Pumpkin plan covers<sup>*</sup>: {condition.pumpkin_covers}
                    </p>
                  </div>
                </div>
              </div>
            </Fragment>
          );
        })}
      </div>
      <p className={styles.note}>
        <sup>*</sup>Reimbursement example shows 90% reimbursement of a covered vet
        bill, assuming the annual deductible has already been met and the annual
        limit has not yet been satisfied.
      </p>
    </div>
  );
}

export default Watchouts;
