// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../../../../libs/shared/src/ui/breakpoints.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pet-button_petButton_2673c11e {\n    background: var(--white);\n    padding: 12px;\n\n    width: 100%;\n    display: flex;\n    align-items: center;\n}\n\n.pet-button_petImage_674b40da {\n    width: 50px;\n    height: 50px;\n    margin-right: 12px;\n}\n\n@media " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["b-desktop"] + " {\n    .pet-button_petButton_2673c11e {\n        border-radius: 8px;\n        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);\n        margin-top: 16px;\n    }\n\n    .pet-button_selected_65ca19a0 {\n        background: var(--puppyPurple);\n    }\n\n    .pet-button_selected_65ca19a0.pet-button_petName_19dc3fff {\n        color: var(--white);\n    }\n\n    .pet-button_selected_65ca19a0 img {\n        box-shadow: 0 0 0 2px var(--white);\n        border-radius: 50%;\n    }\n}", "",{"version":3,"sources":["webpack://src/domains/patients/view/pet-button/pet-button.css"],"names":[],"mappings":"AAEA;IACI,wBAAwB;IACxB,aAAa;;IAEb,WAAW;IACX,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI;QACI,kBAAkB;QAClB,wCAAwC;QACxC,gBAAgB;IACpB;;IAEA;QACI,8BAA8B;IAClC;;IAEA;QACI,mBAAmB;IACvB;;IAEA;QACI,kCAAkC;QAClC,kBAAkB;IACtB;AACJ","sourcesContent":["@value b-desktop from '~@pumpkincare/shared/ui/breakpoints';\n\n.petButton {\n    background: var(--white);\n    padding: 12px;\n\n    width: 100%;\n    display: flex;\n    align-items: center;\n}\n\n.petImage {\n    width: 50px;\n    height: 50px;\n    margin-right: 12px;\n}\n\n@media b-desktop {\n    .petButton {\n        border-radius: 8px;\n        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);\n        margin-top: 16px;\n    }\n\n    .selected {\n        background: var(--puppyPurple);\n    }\n\n    .selected.petName {\n        color: var(--white);\n    }\n\n    .selected img {\n        box-shadow: 0 0 0 2px var(--white);\n        border-radius: 50%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"b-desktop": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["b-desktop"] + "",
	"petButton": "pet-button_petButton_2673c11e",
	"petImage": "pet-button_petImage_674b40da",
	"selected": "pet-button_selected_65ca19a0",
	"petName": "pet-button_petName_19dc3fff"
};
export default ___CSS_LOADER_EXPORT___;
