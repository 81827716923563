import { Body2, LegalBody } from '@pumpkincare/portal-ui';

import styles from './fact.css';

function Fact({ fact }) {
  return (
    <div className={styles.root}>
      <div className={styles.base}>
        <Body2 className={styles.pill}>{fact.label}</Body2>
        <h1 dangerouslySetInnerHTML={{ __html: fact.copy }} />
        <LegalBody className={styles.legal}>{fact.citation}</LegalBody>
      </div>
    </div>
  );
}

export default Fact;
