import { Body1, ButtonSecondary, Carousel, Modal } from '@pumpkincare/portal-ui';

import styles from './pet-pic-tips-modal.css';

import dogPhone from '../../../../../../../public/assets/paw-portal/illustrations/misc/dog-phone.png';
import lightbulbIcon from '../../../../../../../public/assets/paw-portal/illustrations/misc/lightbulb.png';
import phoneSchedule from '../../../../../../../public/assets/paw-portal/illustrations/misc/phone-schedule.png';
import petsPicture from '../../../../../../../public/assets/paw-portal/illustrations/multipet/picture.png';

function PetPicTipsModal({ onClose }) {
  return (
    <Modal
      onClose={onClose}
      classes={{
        container: styles.modalContainer,
        content: styles.modalContent,
      }}
    >
      <Carousel
        title={
          <div className={styles.carouselTitle}>
            <img
              src={lightbulbIcon}
              alt='title-lightbulb'
              role='presentation'
              className={styles.carouselTitleIcon}
            />
            <h4>Tips for Taking a Pawesome Pet Photo</h4>
          </div>
        }
      >
        <div className={styles.carouselItemContent}>
          <img
            className={styles.carouselItemImage}
            src={petsPicture}
            alt='pets centralized on photograpy'
            role='presentation'
          />
          <Body1>
            Make sure the pet is centered in your camera’s frame. Shown here in pink,
            the left & right (landscape) or top & bottom areas (portrait) of the
            photo will be cut off by the frame.
          </Body1>
        </div>
        <div className={styles.carouselItemContent}>
          <img
            className={styles.carouselItemImage}
            src={dogPhone}
            alt='dog looking to camera'
            role='presentation'
          />
          <Body1>
            Help the pet look at the camera – hold a treat or toy near your phone's
            camera to get the pawfect shot!
          </Body1>
        </div>
        <div className={styles.carouselItemContent}>
          <img
            className={styles.carouselItemImage}
            src={phoneSchedule}
            alt='schedule on phone'
            role='presentation'
          />
          <Body1>
            In a hurry? You can always snap a pic now, then create the cards later in
            PawPortal! Don't forget, you'll need the parent's name and email.
          </Body1>
          <ButtonSecondary className={styles.carouselItemButton} onClick={onClose}>
            Got It!
          </ButtonSecondary>
        </div>
      </Carousel>
    </Modal>
  );
}

export default PetPicTipsModal;
