import { usePlans } from '@pumpkincare/portal-app';
import {
  filterSpeciesPlan,
  isPrevent4,
  PARASITICIDES,
  PREVENTATIVES,
  VACCINES,
  WELLNESS_DIAGNOSTICS,
  WELLNESS_EXAMS,
  WELLNESS_SERVICES,
} from '@pumpkincare/portal-shared';
import { A, Body1, LegalBody, Processing } from '@pumpkincare/portal-ui';

import { PATH_EXT_SAMPLE_PLAN } from '../../../app';
import PEPCard from '../../../app/view/pep-card';
import { PP_BASE_UTM_PARAMS } from '../../constants';

import styles from './plans-section.css';

import CatIcon from '../../../../../../../public/assets/paw-portal/illustrations/misc/cat-female.png';
import DogIcon from '../../../../../../../public/assets/paw-portal/illustrations/misc/dog-male.png';
import multiPet from '../../../../../../../public/assets/paw-portal/pet-portraits/multi-pet.png';

const entitlementsOrder = [
  PARASITICIDES,
  WELLNESS_EXAMS,
  VACCINES,
  WELLNESS_DIAGNOSTICS,
  PREVENTATIVES,
  WELLNESS_SERVICES,
];

const entitlementDescription = {
  [WELLNESS_EXAMS]: '100% refund for a wellness exam fee',
  [VACCINES]: '100% refund for crucial vaccines',
  [WELLNESS_DIAGNOSTICS]: '100% refund for key wellness diagnostics',
  [PREVENTATIVES]: '100% refund for select preventive meds/treatments',
  [WELLNESS_SERVICES]: '100% refund for other recommended wellness services',
  [PARASITICIDES]: <b>12 months of parasite protection shipped to your door*</b>,
};

function getTargetCards(targetPlans) {
  return targetPlans.map(plan => {
    return <PEPCard key={plan.id} plan={plan} />;
  });
}

export function determineUniqueEntitlements(plans) {
  return [
    ...new Set(
      [].concat(
        ...plans.map(({ entitlements }) =>
          entitlements.reduce((result, { category, items }) => {
            if (category !== PREVENTATIVES) {
              result.push(category);
              return result;
            }

            // For FE rendering, we want to separate Parasiticides out of the BE category Preventatives
            items.forEach(item => {
              result.push(
                item.name.match(/Simparica|Revolution/) ? PARASITICIDES : category
              );
            });
            return result;
          }, [])
        )
      )
    ),
  ];
}

export default function PlansSection({ vetId, vetName, pepVersion }) {
  const customTitle = isPrevent4({ version_number: pepVersion })
    ? `by ${vetName}`
    : 'with Vets Just Like Yours';

  const { data: plans, isPlaceholderData: isLoading } = usePlans(vetId);

  const dogPlans = filterSpeciesPlan(plans, 'dog');
  const catPlans = filterSpeciesPlan(plans, 'cat');

  const uniquePlansEntitlements = determineUniqueEntitlements(plans);

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        {isLoading ? (
          <Processing classes={{ container: styles.processing }} />
        ) : (
          <>
            <div className={styles.title}>
              <h2>Preventive Essentials Packages</h2>
              <LegalBody className={styles.subtitle}>
                Designed {customTitle}
              </LegalBody>
            </div>

            <div className={styles.content}>
              <img src={multiPet} className={styles.multiPet} alt='' />

              <div className={styles.copy}>
                <h4>
                  Custom wellness packages created with your vet and your pet’s needs
                  in mind.
                </h4>

                <Body1 className={styles.body}>
                  We partnered with your vet to create Preventive Essentials. It’s
                  not insurance, but an optional wellness package you can add to your
                  Pumpkin plan to get refunds for crucial routine care. This
                  includes:
                </Body1>

                <ul>
                  {uniquePlansEntitlements
                    .sort(
                      (a, b) =>
                        entitlementsOrder.indexOf(a) - entitlementsOrder.indexOf(b)
                    )
                    .map(category => (
                      <li key={category}>{entitlementDescription[category]}</li>
                    ))}
                </ul>
                {uniquePlansEntitlements.some(
                  entitlement => entitlement === PARASITICIDES
                ) ? (
                  <p className={styles.exclusion}>
                    *Excluding dogs under 6 months old
                  </p>
                ) : null}
              </div>
            </div>

            <div className={styles.cards}>
              {dogPlans.length ? (
                <div>
                  <div className={styles.targetTitle}>
                    <img src={DogIcon} alt='' />
                    <h3>For Dogs</h3>
                  </div>
                  {getTargetCards(dogPlans)}
                </div>
              ) : null}

              {catPlans.length ? (
                <div>
                  <div className={styles.targetTitle}>
                    <img src={CatIcon} alt='' />
                    <h3>For Cats</h3>
                  </div>
                  {getTargetCards(catPlans)}
                </div>
              ) : null}
            </div>

            <Body1 className={styles.seeMore}>
              See more details in{' '}
              <A
                href={`${PATH_EXT_SAMPLE_PLAN}?${PP_BASE_UTM_PARAMS}${pepVersion}&v=${pepVersion}&type=preventive`}
                target='_blank'
              >
                our sample Preventive Essentials Customer Agreement
              </A>
            </Body1>
          </>
        )}
      </div>
    </div>
  );
}
