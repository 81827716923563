// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./pink_paws.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".meme_root_1f158694 {\n    width: 800px;\n    height: 800px;\n\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.meme_root_1f158694:before {\n    content: '';\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    position: absolute;\n    width: 100%;\n    height: 100vh;\n    top: 0;\n}\n\n.meme_root_1f158694 img {\n    width: 640px;\n    height: 640px;\n    filter: drop-shadow(0px 0px 48px rgba(0, 0, 0, 0.25));\n}", "",{"version":3,"sources":["webpack://src/domains/dashboard/view/meme/meme.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;;IAEb,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,WAAW;IACX,mDAAgC;IAChC,kBAAkB;IAClB,WAAW;IACX,aAAa;IACb,MAAM;AACV;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,qDAAqD;AACzD","sourcesContent":[".root {\n    width: 800px;\n    height: 800px;\n\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.root:before {\n    content: '';\n    background: url('pink_paws.png');\n    position: absolute;\n    width: 100%;\n    height: 100vh;\n    top: 0;\n}\n\n.root img {\n    width: 640px;\n    height: 640px;\n    filter: drop-shadow(0px 0px 48px rgba(0, 0, 0, 0.25));\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "meme_root_1f158694"
};
export default ___CSS_LOADER_EXPORT___;
