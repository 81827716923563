// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../../../../libs/shared/src/ui/breakpoints.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".create-plan-nav_utility_c32bba95,\n.create-plan-nav_create_d758e6e7 {\n    width: 100%;\n    display: flex;\n    align-items: center;\n    padding: 0 40px;\n}\n\n.create-plan-nav_utility_c32bba95 {\n    height: 37px;\n    background: var(--cream);\n    justify-content: end;\n}\n\n.create-plan-nav_subhead_95d5b248 {\n    margin-left: 16px;\n    color: var(--bluesClues);\n}\n\n.create-plan-nav_create_d758e6e7 {\n    height: 72px;\n    background: var(--bluesClues);\n    justify-content: space-between;\n}\n\n.create-plan-nav_create_d758e6e7 button {\n    width: 130px;\n}\n\n@media " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["b-desktop"] + " {\n    .create-plan-nav_subhead_95d5b248 {\n        margin-left: 30px;\n    }\n\n    .create-plan-nav_create_d758e6e7 button {\n        width: 201px;\n    }\n}", "",{"version":3,"sources":["webpack://src/domains/customer/view/create-plan-nav/create-plan-nav.css"],"names":[],"mappings":"AAEA;;IAEI,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,wBAAwB;IACxB,oBAAoB;AACxB;;AAEA;IACI,iBAAiB;IACjB,wBAAwB;AAC5B;;AAEA;IACI,YAAY;IACZ,6BAA6B;IAC7B,8BAA8B;AAClC;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI;QACI,iBAAiB;IACrB;;IAEA;QACI,YAAY;IAChB;AACJ","sourcesContent":["@value b-desktop from '~@pumpkincare/shared/ui/breakpoints';\n\n.utility,\n.create {\n    width: 100%;\n    display: flex;\n    align-items: center;\n    padding: 0 40px;\n}\n\n.utility {\n    height: 37px;\n    background: var(--cream);\n    justify-content: end;\n}\n\n.subhead {\n    margin-left: 16px;\n    color: var(--bluesClues);\n}\n\n.create {\n    height: 72px;\n    background: var(--bluesClues);\n    justify-content: space-between;\n}\n\n.create button {\n    width: 130px;\n}\n\n@media b-desktop {\n    .subhead {\n        margin-left: 30px;\n    }\n\n    .create button {\n        width: 201px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"b-desktop": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["b-desktop"] + "",
	"utility": "create-plan-nav_utility_c32bba95",
	"create": "create-plan-nav_create_d758e6e7",
	"subhead": "create-plan-nav_subhead_95d5b248"
};
export default ___CSS_LOADER_EXPORT___;
