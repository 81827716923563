import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { USER_AGENTS } from '@pumpkincare/portal-shared';
import { A, Body1, ButtonSecondary } from '@pumpkincare/portal-ui';

import { getVisitPhotoMergedUrl } from '../../selectors';

import styles from './visit-success.css';

import check from '../../../../../../../public/assets/paw-portal/icons/check-circle-green.svg';
import download from '../../../../../../../public/assets/paw-portal/icons/download.svg';

export default function VisitSuccess({
  onClick,
  patientData,
  returnCopy = 'Return to Home',
}) {
  const image = useSelector(getVisitPhotoMergedUrl);
  const { firstName, lastName, email, petName } = patientData;
  return (
    <>
      <h4 className={styles.title}>
        <img src={check} alt='check' role='presentation' className={styles.check} />
        Success!
      </h4>
      <Body1 className={styles.subtitle}>
        Your photo card has been sent to {firstName} {lastName} ({email}).
      </Body1>
      <img src={image} alt={`Visit from ${petName}`} className={styles.photo} />

      <Body1
        className={classNames(styles.mobileAction, {
          [styles.hidden]: USER_AGENTS.desktop,
        })}
      >
        Tap & hold the image above to download
      </Body1>
      <A
        href={image}
        download
        className={classNames(styles.desktopAction, {
          [styles.hidden]: !USER_AGENTS.desktop,
        })}
      >
        <img
          src={download}
          alt='download'
          role='presentation'
          className={styles.download}
        />
        <Body1 className={styles.desktopText}>Download</Body1>
      </A>

      <ButtonSecondary onClick={onClick} className={styles.button}>
        {returnCopy}
      </ButtonSecondary>
    </>
  );
}
