import { useMutation } from 'react-query';

import { fetchWrapper } from '@pumpkincare/portal-shared';

export function useMutateSendPawsightsEmail() {
  return useMutation(({ email, petName, breedName, breedCode, quoteId, vetId }) => {
    const body = {
      email,
      pet_name: petName,
      breed_name: breedName,
      breed_code: breedCode,
      quote_id: quoteId,
    };
    if (vetId) body.vet_id = vetId;

    return fetchWrapper('/utils/pawsights-email', {
      method: 'POST',
      body,
    });
  });
}

export function useMutateTrackPawsightsEmail() {
  return useMutation(({ email }) => {
    return fetchWrapper('/utils/pawsights-track', {
      method: 'POST',
      body: { email },
    });
  });
}
