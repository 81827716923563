import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { push, replace } from 'connected-react-router';

import {
  dateIsAfter,
  DOG,
  formatISODate,
  formatMMDDYYYYtoYYYYMMDD,
  IDLE,
  LOADING,
  SUCCESS,
  useTargetState,
  useToggleState,
  validateDateMMDDYYY,
  validateOnlyNumbers,
} from '@pumpkincare/portal-shared';
import {
  Body1,
  Body2,
  BodySubhead,
  Button,
  ButtonPrimary,
  ButtonSecondary,
  Checkbox,
  DateInput,
  FileUpload,
  Processing,
  Radio,
  RadioGroup,
  SearchField,
  TextArea,
  TextField,
  ToggleButton,
  Tooltip,
} from '@pumpkincare/portal-ui';

import {
  getRouterLocationQuery,
  PATH_FILE_CLAIM_BASE,
  PATH_FILE_CLAIM_SUCCESS,
  uploadToS3,
} from '../../../app';
import AddPatientModal from '../../../patients/view/add-patient-modal';
import RequestPermissionModal from '../../../patients/view/request-permission-modal';
import {
  ALLOWED_TYPES,
  ALLOWED_TYPES_MESSAGE,
  MAX_SIZE,
} from '../../constants/claim-submission';
import {
  getClaimPatient,
  getClaimPatientSearch,
  getClaimPatientSearchStatus,
  getClaimPatientStatus,
  getClaimSubmitStatus,
} from '../../selectors';
import { setClaimPatient } from '../../state';
import createClaimFileSignedUrl from '../../thunks/create-claim-file-signed-url';
import loadClaimPatient from '../../thunks/load-claim-patient';
import loadClaimPatientSearch from '../../thunks/load-claim-patient-search';
import submitClaim from '../../thunks/submit-claim';
import FraudNoticeModal from '../fraud-notice-modal';

import styles from './file-claim.css';

import accidentIcon from '../../../../../../../public/assets/paw-portal/icons/accident.svg';
import catIcon from '../../../../../../../public/assets/paw-portal/icons/cat.png';
import dogIcon from '../../../../../../../public/assets/paw-portal/icons/dog.png';
import infoIcon from '../../../../../../../public/assets/paw-portal/icons/info.svg';
import emailIcon from '../../../../../../../public/assets/paw-portal/icons/mail.svg';
import pawColoredIcon from '../../../../../../../public/assets/paw-portal/icons/paw-colored.svg';
import phoneIcon from '../../../../../../../public/assets/paw-portal/icons/phone.svg';
import vaccinesIcon from '../../../../../../../public/assets/paw-portal/icons/vaccines.png';

export default function FileClaim() {
  const history = useHistory();
  const dispatch = useDispatch();

  const [isAddPatientModalOpen, toggleAddPatientModal] = useToggleState(false);
  const [isRequestPermissionModal, toggleRequestPermissionModal] =
    useToggleState(false);
  const [isFraudNoticeModalOpen, toggleFraudNoticeModal] = useToggleState(false);
  const [claimType, setClaimType] = useState('claim');
  const [patientSearch, setPatientSearch] = useTargetState('');
  const [claimFileList, setClaimFileList] = useState([]);
  const [errorEmptyUploadListMessage, setErrorEmptyUploadListMessage] = useState('');
  const [isClaimFileUploading, setIsClaimFileUploading] = useState(false);
  const [visitDate, setVisitDate, isValidVisitDate] = useTargetState(
    '',
    validateDateMMDDYYY
  );
  const [injuryDate, setInjuryDate] = useTargetState('');
  const [errorInjuryDate, setErrorInjuryDate] = useState('');
  const [claimedAmount, setClaimedAmount, isValidClaimedAmount] = useTargetState(
    '',
    validateOnlyNumbers
  );
  const [isInsuranceAccident, setIsInsuranceAccident] = useToggleState(false);
  const [isInsuranceIllness, setIsInsuranceIllness] = useToggleState(false);
  const [isPreventiveEssentials, setIsPreventiveEssentials] = useToggleState(false);
  const [isMultiVet, setMultiVet] = useState();
  const [isOngoing, setIsOngoing] = useState();
  const [reason, setReason, isValidReason] = useTargetState('');
  const [selectedReimbursement, setSelectedReimbursement] = useState();
  const [isFraudNoticeChecked, toggleFraudNoticeCheck] = useToggleState(false);
  const [userFirst, setUserFirst] = useTargetState('');
  const [userLast, setUserLast] = useTargetState('');
  const [userTitle, setUserTitle] = useTargetState('');

  const submitStatus = useSelector(getClaimSubmitStatus);
  const isSubmitLoading = submitStatus === LOADING;
  const query = useSelector(getRouterLocationQuery);
  const [patientId, setPatientId] = useState(query.patientId || '');
  const [petId, setPetId] = useState(query.petId || '');
  const selectedPatient = useSelector(getClaimPatient);
  const selectedPatientStatus = useSelector(getClaimPatientStatus);
  const isSelectedPatientLoading = [IDLE, LOADING].some(
    status => status === selectedPatientStatus
  );
  const searchedPatients = useSelector(getClaimPatientSearch);
  const searchStatus = useSelector(getClaimPatientSearchStatus);

  const canContinueForm = selectedPatient?.permissions[0]?.can_file_claim && petId;

  const selectedPet = selectedPatient?.pets.filter(({ id }) => id === petId)[0];

  const petHasInsurance = selectedPet?.has_insurance;
  const petHasPreventative = selectedPet?.has_preventative;

  const isProtectClaim = isInsuranceAccident || isInsuranceIllness;

  const isAllowedSubmit =
    patientId &&
    petId &&
    !isClaimFileUploading &&
    claimFileList.length &&
    isValidVisitDate &&
    isValidClaimedAmount &&
    (isInsuranceAccident || isInsuranceIllness || isPreventiveEssentials) &&
    !(
      isProtectClaim &&
      (errorInjuryDate ||
        typeof isOngoing !== 'boolean' ||
        typeof isMultiVet !== 'boolean')
    ) &&
    isValidReason &&
    selectedReimbursement &&
    isFraudNoticeChecked &&
    !!userFirst &&
    !!userLast &&
    !!userTitle;

  function handleFileUpload(file, rawFile, fileType) {
    return dispatch(createClaimFileSignedUrl(patientId, rawFile, fileType))
      .then(response => {
        const signedUrlParams = response[file.name];
        file.key = signedUrlParams.fields.key;
        return dispatch(uploadToS3(rawFile, { signed_url: signedUrlParams }));
      })
      .then(() => {
        return file;
      });
  }

  function handleSearch(e) {
    const { value } = e.target;

    if (value) {
      const encodedValue = encodeURIComponent(value);
      dispatch(loadClaimPatientSearch(encodedValue));
    }
  }

  function handleSubmitClaim() {
    const filesUploadList = claimFileList.map(file => {
      return {
        name: file.name,
        type: file.type,
        description: '',
        key: file.key,
        document_type: 'invoice',
      };
    });

    const claim = {
      is_estimate: claimType === 'estimate',
      is_accident: isInsuranceAccident,
      is_illness: isInsuranceIllness,
      is_prevent: isPreventiveEssentials,
      pet_id: petId,
      user_id: patientId,
      claim_amounts: [Math.round(parseFloat(claimedAmount) * 100)],
      send_reimbursement_to_vet: selectedReimbursement === 'clinic',
      diagnosis_story: reason,
      claim_attachments: filesUploadList,
      treatment_dates: [formatMMDDYYYYtoYYYYMMDD(visitDate)],
      audit: {
        first_name: userFirst,
        last_name: userLast,
        title: userTitle,
      },
    };

    if (isProtectClaim) {
      claim.is_ongoing = isOngoing;
      claim.is_multi_vet = isMultiVet;
      claim.injury_date = formatMMDDYYYYtoYYYYMMDD(injuryDate);
    }

    dispatch(submitClaim(claim)).then(() => {
      dispatch(push(PATH_FILE_CLAIM_SUCCESS));
    });
  }

  function handlePatientSelection(patient) {
    setPatientSearch({
      target: { value: `${patient.first_name} ${patient.last_name}` },
    });
    setPatientId(patient.id);
    dispatch(setClaimPatient({ data: patient, status: SUCCESS }, SUCCESS));
  }

  function handleChangeSelectedPatient() {
    dispatch(setClaimPatient({ data: null, status: IDLE }));
    dispatch(replace({ pathname: PATH_FILE_CLAIM_BASE }));
    setPatientId('');
    setPetId('');
  }

  function handlePetSelect(pet) {
    setPetId(pet.id);
  }

  function handleChangeInjuryDate(e, options) {
    const currentVisitDate = options?.visitDate || visitDate;
    setInjuryDate(e);
    const injuryDateValue = e.target.value;
    let errorMessage = '';
    if (!injuryDateValue) {
      errorMessage = 'The value is required';
    } else if (!validateDateMMDDYYY(injuryDateValue)) {
      errorMessage = 'Date format invalid';
    } else if (currentVisitDate && dateIsAfter(injuryDateValue, currentVisitDate)) {
      errorMessage = 'Accident/illness date should be before date of visit';
    }
    setErrorInjuryDate(errorMessage);
  }

  function handleChangeVisitDate(e) {
    setVisitDate(e);
    handleChangeInjuryDate(
      { target: { value: injuryDate } },
      { visitDate: e.target.value }
    );
  }

  useEffect(() => {
    if (
      patientId &&
      selectedPatientStatus !== LOADING &&
      patientId !== selectedPatient?.id
    ) {
      dispatch(loadClaimPatient(patientId)).catch(() =>
        dispatch(replace({ pathname: PATH_FILE_CLAIM_BASE }))
      );
    }
  }, [dispatch, patientId, selectedPatient, selectedPatientStatus]);

  return (
    <div className={styles.container}>
      <h1 className={styles.header}>File a Claim or Estimate</h1>
      <Body1 className={styles.requiredAlert}>= Required Field</Body1>

      <h4 className={styles.sectionTitle}>1. What are you filing?</h4>
      <div style={{ marginTop: '32px' }}>
        <div className={styles.groupContainer}>
          <ToggleButton
            classes={{
              container: styles.toggleButton,
              img: styles.toggleButtonImage,
            }}
            label='Claim'
            selected={claimType === 'claim'}
            icon={pawColoredIcon}
            clickHandler={() => setClaimType('claim')}
          />
          <ToggleButton
            classes={{
              container: styles.toggleButton,
              img: styles.toggleButtonImage,
            }}
            label='Estimate'
            selected={claimType === 'estimate'}
            icon={pawColoredIcon}
            clickHandler={() => setClaimType('estimate')}
          />
        </div>
      </div>
      {claimType === 'estimate' ? (
        <Body1 className={styles.toggleButtonSubtext}>
          Please note that estimates are not processed immediately – an estimate is
          not a pre-approval and should not be relied on to determine care.
        </Body1>
      ) : null}

      <h4 className={styles.sectionTitle}>2. Enter Client Information</h4>
      {patientId ? (
        <>
          <div className={styles.selectedPatient}>
            {isSelectedPatientLoading ? (
              <Processing />
            ) : (
              <>
                <h5 className={styles.selectedPatientItem}>
                  {selectedPatient.first_name} {selectedPatient.last_name}
                </h5>
                <Body1 className={styles.selectedPatientItem}>
                  <img
                    src={emailIcon}
                    alt='email'
                    role='presentation'
                    className={styles.patientInfoIcon}
                  />
                  {selectedPatient.email}
                </Body1>
                <Body1 className={styles.selectedPatientItem}>
                  <img
                    src={phoneIcon}
                    alt='phone'
                    role='presentation'
                    className={styles.patientInfoIcon}
                  />
                  {selectedPatient.phone}
                </Body1>
                <Button
                  className={styles.selectedPatientChange}
                  onClick={handleChangeSelectedPatient}
                >
                  Change Selected Client
                </Button>
              </>
            )}
          </div>
          {selectedPatient ? (
            <>
              <BodySubhead className={styles.petSelection}>
                Select Patient
              </BodySubhead>
              <div className={styles.petButtons}>
                {selectedPatient.pets
                  .filter(pet => pet.has_insurance || pet.has_preventative)
                  .map(pet => (
                    <ToggleButton
                      key={pet.id}
                      selected={pet.id === petId}
                      label={pet.name}
                      clickHandler={() => handlePetSelect(pet)}
                      icon={pet.species === DOG ? dogIcon : catIcon}
                      classes={{
                        container: styles.petButton,
                        img: styles.petButtonImage,
                      }}
                    />
                  ))}
              </div>
              {petHasInsurance ? (
                <div className={styles.petInfoContainer}>
                  <Body1 className={styles.petInfoLabel}>
                    Insurance Coverage Start Date
                  </Body1>
                  <Tooltip
                    content='Insurance claims outside of coverage start dates will not be accepted.'
                    classes={{ tooltip: styles.petInfoTooltip }}
                  >
                    <img
                      src={infoIcon}
                      className={styles.petInfoIcon}
                      alt='info-icon'
                    />
                  </Tooltip>
                  <Body1 className={styles.petInfoValue} style={{ width: '106px' }}>
                    <span data-testid='insurance-coverage-start-date'>
                      {formatISODate(selectedPet.insurance_start_date)}
                    </span>
                  </Body1>
                </div>
              ) : null}
              {petHasPreventative ? (
                <div
                  className={styles.petInfoContainer}
                  style={{ marginTop: '8px' }}
                >
                  <Body1 className={styles.petInfoLabel}>
                    Prevent Coverage Start Date
                  </Body1>
                  <Tooltip
                    content={
                      <>
                        Preventive Essentials claims can be filed the same day a
                        pet’s plan is purchased. Your client also has the option to
                        get a full refund for any Preventive Essentials benefits
                        their pet may have received up to{' '}
                        <span style={{ fontWeight: 'bold' }}>
                          2 weeks before enrolling
                        </span>
                        . If they have a puppy under 6 months, your client can get a
                        full refund for up to 4 vaccines received any time before
                        enrolling.
                      </>
                    }
                    classes={{ tooltip: styles.petInfoTooltip }}
                  >
                    <img
                      src={infoIcon}
                      className={styles.petInfoIcon}
                      alt='info-icon'
                    />
                  </Tooltip>
                  <Body1 className={styles.petInfoValue} style={{ width: '119px' }}>
                    <span data-testid='preventive-coverage-start-date'>
                      {formatISODate(selectedPet.preventative_start_date)}
                    </span>
                  </Body1>
                </div>
              ) : null}
            </>
          ) : null}
        </>
      ) : (
        <SearchField
          value={patientSearch}
          onSearch={handleSearch}
          onChange={setPatientSearch}
          isLoading={searchStatus === LOADING}
          label='Search by Client Name, Email, Phone'
          isLabelHidden={false}
          classes={{
            focusedContainer: styles.focusedSearchContainer,
          }}
          dropdownOptions={{
            isDropdown: true,
            isIdle: searchStatus === IDLE,
            stickyFooter: (
              <div className={styles.searchInvite}>
                Can't find the client you're looking for?{' '}
                <button type='button' onClick={toggleAddPatientModal}>
                  <b>Invite Client</b>
                </button>
              </div>
            ),
          }}
        >
          {searchedPatients?.patients.map(patient => (
            <div
              key={patient.pumpkin_id}
              className={styles.searchPatient}
              onClick={() => handlePatientSelection(patient)}
              aria-hidden='true'
            >
              <span>
                {patient.first_name} {patient.last_name}
              </span>
              <span style={{ textAlign: 'right' }}>{patient.email}</span>
            </div>
          ))}
        </SearchField>
      )}

      {canContinueForm ? (
        <>
          <h4 className={styles.sectionTitle}>3. Upload Itemized Invoice</h4>
          <div style={{ marginTop: '24px' }}>
            <Body1>Please include all pages of your itemized invoice.</Body1>
            <FileUpload
              maxFileSize={MAX_SIZE}
              allowedTypes={ALLOWED_TYPES}
              files={claimFileList}
              onFileListChange={setClaimFileList}
              acceptedFilesText={ALLOWED_TYPES_MESSAGE}
              onEmptyError={setErrorEmptyUploadListMessage}
              errorEmptyListMessage={errorEmptyUploadListMessage}
              uploadType='claim-attachment'
              uploadInputId='claim-attachment'
              onIsUploadingChange={setIsClaimFileUploading}
              onUpload={handleFileUpload}
            />
          </div>

          <h4 className={styles.sectionTitle}>4. Enter Visit Details</h4>
          <>
            <div className={styles.groupContainer}>
              <div className={styles.inputContainer}>
                <BodySubhead className={styles.visitDateLabel}>
                  Date of Visit
                </BodySubhead>
                <DateInput
                  onChange={handleChangeVisitDate}
                  inputId='visit-date'
                  classes={{ container: styles.dateVisitContainer }}
                />
              </div>

              <div className={styles.inputContainer}>
                <BodySubhead className={styles.claimedAmountLabel}>
                  Total Amount Claimed
                </BodySubhead>
                <TextField
                  isLabelHidden
                  id='amount-claimed'
                  variant='outline'
                  label='0.00'
                  value={claimedAmount}
                  onChange={setClaimedAmount}
                  startAdornment={<div className={styles.dollarAdornment}>$</div>}
                />
              </div>
            </div>

            <div className={styles.labelContainer}>
              <BodySubhead className={styles.dataLabel}>Type of Claim</BodySubhead>
              <Body1 className={styles.dataLabelNotes}>Select all that apply.</Body1>
            </div>

            <div className={styles.typeContainer}>
              <ToggleButton
                classes={{
                  container: styles.typeToggleButton,
                  img: styles.toggleButtonImage,
                }}
                label='Insurance (Accident)'
                selected={isInsuranceAccident}
                icon={accidentIcon}
                clickHandler={setIsInsuranceAccident}
              />
              <ToggleButton
                classes={{
                  container: styles.typeToggleButton,
                  img: styles.toggleButtonImage,
                }}
                label='Insurance (Illness)'
                selected={isInsuranceIllness}
                icon={accidentIcon}
                clickHandler={setIsInsuranceIllness}
              />

              {petHasPreventative ? (
                <ToggleButton
                  classes={{
                    container: styles.typeToggleButton,
                    img: styles.toggleButtonImage,
                  }}
                  label='Preventive Essentials'
                  selected={isPreventiveEssentials}
                  icon={vaccinesIcon}
                  clickHandler={setIsPreventiveEssentials}
                />
              ) : null}
            </div>

            {isProtectClaim ? (
              <>
                <BodySubhead className={styles.injuryDateLabel}>
                  When did the accident/illness first occur?
                </BodySubhead>
                <DateInput
                  onChange={handleChangeInjuryDate}
                  inputId='injury-date'
                  errorMessage={errorInjuryDate}
                  classes={{ container: styles.injuryDateContainer }}
                />

                <BodySubhead className={styles.radioHeader}>
                  Is this a new condition?
                </BodySubhead>
                <RadioGroup
                  name='isOngoing'
                  value={isOngoing}
                  onChange={setIsOngoing}
                  classes={{ root: styles.radioGroup }}
                >
                  <Radio value label='Yes' data-testid='radio-isongoing' />
                  <Radio value={false} label='No' />
                </RadioGroup>

                <BodySubhead className={styles.radioHeader}>
                  Did they visit more than one vet clinic for this accident/illness?
                </BodySubhead>
                <RadioGroup
                  name='isMultiVet'
                  value={isMultiVet}
                  onChange={setMultiVet}
                  classes={{ root: styles.radioGroup }}
                >
                  <Radio value label='Yes' data-testid='radio-ismultivet' />
                  <Radio value={false} label='No' />
                </RadioGroup>
              </>
            ) : null}

            <BodySubhead className={styles.textareaLabel} style={{ marginTop: '0' }}>
              What was the reason for the vet visit?
            </BodySubhead>
            <Body2 className={styles.textareaHints}>
              Describe what happened to this pet. Please be sure to include:
              <ul style={{ paddingLeft: '24px' }}>
                <li>Details around the incident or illness</li>
                <li>when the symptoms or incident started happening</li>
                <li>occurrence/diagnosis you and or other clinics provided</li>
              </ul>
            </Body2>
            <TextArea rows={8} value={reason} onChange={setReason} />

            <BodySubhead className={styles.reimbursedLabel}>
              Who Should Get Reimbursed?
            </BodySubhead>
            <div className={styles.groupContainer}>
              <ToggleButton
                classes={{ container: styles.toggleButton }}
                label='Client'
                selected={selectedReimbursement === 'client'}
                clickHandler={() => setSelectedReimbursement('client')}
              />
              <ToggleButton
                classes={{ container: styles.toggleButton }}
                label='Our Clinic'
                selected={selectedReimbursement === 'clinic'}
                clickHandler={() => setSelectedReimbursement('clinic')}
              />
            </div>
          </>

          <Checkbox
            id='fraud-notice'
            label={
              <div className={styles.checkboxLabel}>
                {`I confirm that all the information above is accurate, no relevant details have been omitted, and I have read and accept the `}
                <Button
                  onClick={toggleFraudNoticeModal}
                  className={styles.fraudNoticeButton}
                >
                  declaration and fraud notice
                </Button>
                . I have reviewed these details with the above named policy holiday,
                and the above named policy holder has affirmed that the details are
                correct.
              </div>
            }
            onChange={toggleFraudNoticeCheck}
            checked={isFraudNoticeChecked}
            classes={{
              container: styles.checkboxContainer,
              box: styles.checkbox,
            }}
          />

          <div className={styles.auditContainer}>
            <div className={styles.auditInputContainer}>
              <BodySubhead className={styles.inputLabel}>Your Name</BodySubhead>
              <TextField
                label='First Name'
                id='user-first-name'
                value={userFirst}
                onChange={setUserFirst}
              />
            </div>
            <div className={styles.auditInputContainer}>
              <TextField
                label='Last Name'
                id='user-last-name'
                value={userLast}
                onChange={setUserLast}
              />
            </div>

            <div className={styles.auditInputContainer}>
              <BodySubhead className={styles.yourTitleLabel}>Your Title</BodySubhead>
              <TextField
                label='Example: Practice Manager'
                id='user-title'
                value={userTitle}
                onChange={setUserTitle}
              />
            </div>
          </div>

          <div className={styles.alignCenter}>
            <ButtonPrimary
              isLoading={isSubmitLoading}
              disabled={!isAllowedSubmit}
              className={styles.submit}
              onClick={handleSubmitClaim}
            >
              Submit Claim
            </ButtonPrimary>
          </div>
        </>
      ) : null}

      {selectedPatient && !selectedPatient.permissions[0]?.can_file_claim ? (
        <>
          <Body1 className={styles.requestError}>
            {selectedPatient.first_name} has not given permission for you to file
            claims on their behalf.
          </Body1>
          <ButtonSecondary
            className={styles.submit}
            onClick={toggleRequestPermissionModal}
          >
            Request Permission
          </ButtonSecondary>
        </>
      ) : null}

      {isAddPatientModalOpen ? (
        <AddPatientModal
          onClose={toggleAddPatientModal}
          successButtonText='Start Another Claim'
          onSuccessClick={() => history.go(0)}
        />
      ) : null}

      {isRequestPermissionModal ? (
        <RequestPermissionModal
          patientEmail={selectedPatient.email}
          patientFirstName={selectedPatient.first_name}
          patientLastName={selectedPatient.last_name}
          onClose={toggleRequestPermissionModal}
          successButtonText='Start Another Claim'
          onSuccessClick={() => history.go(0)}
        />
      ) : null}

      {isFraudNoticeModalOpen ? (
        <FraudNoticeModal onClose={toggleFraudNoticeModal} />
      ) : null}
    </div>
  );
}
