import { apiGet } from '../../app';
import { setPatientPet } from '../state';

// identifier is id for pumpkin customers, email for non
// identifier is coming from the URL, where it should already be encoded
export default function loadPatientPet(identifier, pet) {
  return dispatch => {
    return dispatch(
      apiGet({
        url: `/patients/${identifier}/pets/${encodeURIComponent(
          pet.id || pet.name
        )}`,
        options: {
          isAuth: true,
          handleActions: setPatientPet,
        },
      })
    );
  };
}
