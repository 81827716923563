import { useSelector } from 'react-redux';

import { Body1, SubText1 } from '@pumpkincare/portal-ui';

import { getRouterLocationQuery } from '../../selectors';

import styles from './unsubscribe-page.css';

import checkCircled from '../../../../../../../public/assets/paw-portal/icons/check-circle-green.svg';
import pumpkinLogo from '../../../../../../../public/assets/paw-portal/logos/pumpkin-blue.svg';

export default function UnsubscribePage() {
  const query = useSelector(getRouterLocationQuery);

  return (
    <div className={styles.container}>
      <img src={pumpkinLogo} className={styles.logo} alt='Pumpkin' />
      <img
        src={checkCircled}
        className={styles.icon}
        alt=''
        data-testid='check-icon'
      />
      <Body1 className={styles.header}>
        You've successfully been unsubscribed from{' '}
        {decodeURIComponent(query.utm_campaign)} emails.
      </Body1>
      <SubText1 className={styles.subheader}>Didn't mean to unsubscribe?</SubText1>
      <SubText1 className={styles.message}>
        No worries! Just send an email to your Pumpkin sales representative.
      </SubText1>
    </div>
  );
}
