import PropTypes from 'prop-types';

import styles from './legal-section.css';

function LegalSection({ legal }) {
  return (
    <div className={styles.legalSection}>
      {legal.map(({ title, content }, idx) => (
        <p key={idx} className={styles.legal}>
          <b dangerouslySetInnerHTML={{ __html: title }} />{' '}
          <span dangerouslySetInnerHTML={{ __html: content }} />
        </p>
      ))}
    </div>
  );
}

LegalSection.propTypes = {
  legal: PropTypes.array.isRequired,
};

export default LegalSection;
