import { Route, Switch } from 'react-router-dom';

import { PATH_ACCOUNT_BASE, PATH_ACCOUNT_PROFILE } from '../../routing';
import AccountHome from '../account-home';
import Profile from '../profile';

export default function AccountWrapper() {
  return (
    <Switch>
      <Route path={PATH_ACCOUNT_PROFILE} render={() => <Profile />} />
      <Route path={PATH_ACCOUNT_BASE} render={() => <AccountHome />} />
    </Switch>
  );
}
