import { useEffect, useState } from 'react';

import styles from './pawsights-loader.css';

import loadImage from '../../../../../../../../public/assets/paw-portal/illustrations/multipet/fetch.png';

function PawsightsLoader({ messages }) {
  const [messageIndex, setMessageIndex] = useState(0);
  const messagesLength = messages.length;

  useEffect(() => {
    const interval = setInterval(() => {
      setMessageIndex(val => {
        if (val + 1 === messagesLength - 1) {
          clearInterval(interval);
        }
        return val + 1;
      });

      return () => clearInterval(interval);
    }, 1000);
  }, [messagesLength]);

  return (
    <div className={styles.root}>
      <img src={loadImage} alt='' />
      <p>{messages[messageIndex]}</p>
    </div>
  );
}

export default PawsightsLoader;
