import {
  Body1,
  ButtonPrimary,
  ButtonSecondary,
  Modal,
  TextField,
} from '@pumpkincare/portal-ui';

import useAddPatient from '../../hooks/use-add-patient';

import styles from './add-patient-modal.css';

import check from '../../../../../../../public/assets/paw-portal/icons/check-circle-green.svg';

export default function AddPatientModal({
  onClose,
  successButtonText,
  onSuccessClick = null,
  defaultValue = '',
}) {
  const { email, fieldErrorMessage, requestPatient, setEmail, statuses } =
    useAddPatient(defaultValue);

  return (
    <Modal
      onClose={onClose}
      classes={{ container: styles.container, content: styles.content }}
    >
      {statuses.isSuccess ? (
        <>
          <h4 className={styles.title}>
            <img
              src={check}
              alt='check'
              role='presentation'
              className={styles.check}
            />
            Invite Sent
          </h4>
          <Body1 className={styles.copy}>
            Your client should receive an email shortly inviting them to add your
            hospital to their account. You will receive an email once they’ve
            accepted your request.
          </Body1>
          <ButtonSecondary
            onClick={onSuccessClick || onClose}
            className={styles.button}
          >
            {successButtonText || 'Close'}
          </ButtonSecondary>
        </>
      ) : (
        <>
          <h4 className={styles.title}>Add a Client</h4>
          <Body1 className={styles.copy}>
            Can't find the client you're looking for? They may not have you listed as
            their veterinary hospital. Send them an invitation to update their
            Pumpkin account:
          </Body1>
          <form onSubmit={requestPatient}>
            <TextField
              value={email}
              onChange={setEmail}
              label='Client Email'
              error={{ errorMessage: fieldErrorMessage }}
              classes={{ container: styles.field }}
            />
            <ButtonPrimary
              type='submit'
              isLoading={statuses.isLoading}
              className={styles.button}
              disabled={!email}
            >
              Send Invite
            </ButtonPrimary>
          </form>
          {statuses.isError ? (
            <Body1 className={styles.error}>
              Sorry, something went wrong! Please try again later or reach out to our
              care team.
            </Body1>
          ) : null}
        </>
      )}
    </Modal>
  );
}
