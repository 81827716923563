import { v4 as uuid } from 'uuid';

import { apiPost } from '../state/api-ducks';

export default function createDocumentFileSignedUrl(vetId, image, options = {}) {
  return dispatch => {
    // image.type = image/png, image/jpeg;
    const extension = image.type.split('/')[1];

    return dispatch(
      apiPost({
        url: `/vets/${vetId}/documents`,
        options: {
          body: {
            file_name: `${uuid()}.${extension}`,
          },
          ...options,
        },
      })
    );
  };
}
