import { CAT, DOG } from '@pumpkincare/portal-shared';

import styles from './resources.css';

import catResource1 from '../../../../../../../../public/assets/paw-portal/illustrations/multipet/resources-cat-1.png';
import catResource2 from '../../../../../../../../public/assets/paw-portal/illustrations/multipet/resources-cat-2.png';
import catResource3 from '../../../../../../../../public/assets/paw-portal/illustrations/multipet/resources-cat-3.png';
import catResource4 from '../../../../../../../../public/assets/paw-portal/illustrations/multipet/resources-cat-4.png';
import dogResource1 from '../../../../../../../../public/assets/paw-portal/illustrations/multipet/resources-dog-1.png';
import dogResource2 from '../../../../../../../../public/assets/paw-portal/illustrations/multipet/resources-dog-2.png';
import dogResource3 from '../../../../../../../../public/assets/paw-portal/illustrations/multipet/resources-dog-3.png';
import dogResource4 from '../../../../../../../../public/assets/paw-portal/illustrations/multipet/resources-dog-4.png';
import bottomWave from '../../../../../../../../public/assets/paw-portal/illustrations/shapes/wave-edge-bottom.png';
import topWave from '../../../../../../../../public/assets/paw-portal/illustrations/shapes/wave-edge-top.png';

const resources = {
  [DOG]: [
    {
      img: dogResource1,
      title: 'How Much Does it Really Cost to Care for a Dog?',
      link: 'https://www.pumpkin.care/blog/cost-of-dog-care/',
    },
    {
      img: dogResource2,
      title: 'What is Pet Insurance and How Does It Work?',
      link: 'https://www.pumpkin.care/blog/what-is-pet-insurance-and-how-does-it-work/',
    },
    {
      img: dogResource3,
      title: 'Is Pet Insurance Worth It? 5 Simple Ways to Find Out',
      link: 'https://www.pumpkin.care/blog/is-pet-insurance-worth-it/',
    },
    {
      img: dogResource4,
      title: 'How does Pumpkin plan stack up against other brands?',
      link: 'https://www.pumpkin.care/compare/',
    },
  ],
  [CAT]: [
    {
      img: catResource1,
      title: 'How Often Should You Take Your Cat to the Vet?',
      link: 'https://www.pumpkin.care/blog/how-often-do-you-take-cat-to-vet/',
    },
    {
      img: catResource2,
      title: 'Kitten Vaccination Schedule: A Guide for New Cat Owners',
      link: 'https://www.pumpkin.care/blog/kitten-vaccination-schedule/',
    },
    {
      img: catResource3,
      title: 'Pet Insurance for Older Cats',
      link: 'https://www.pumpkin.care/blog/pet-insurance-for-older-cats/',
    },
    {
      img: catResource4,
      title: 'Is Pet Insurance Worth It? 5 Simple Ways to Find Out',
      link: 'https://www.pumpkin.care/blog/is-pet-insurance-worth-it/',
    },
  ],
};
function Resources({ petBreedSpecies }) {
  return (
    <>
      <img className={styles.wave} src={topWave} alt='' />

      <div className={styles.root}>
        <h2>Resources</h2>

        <div className={styles.resources}>
          {resources[petBreedSpecies].map(resource => (
            <a
              className={styles.resource}
              key={resource.title}
              href={resource.link}
              target='_blank'
              rel='noreferrer'
            >
              <img alt='' src={resource.img} />
              <div className={styles.title}>
                <p>{resource.title}</p>
              </div>
            </a>
          ))}
        </div>
      </div>

      <img className={styles.wave} src={bottomWave} alt='' />
    </>
  );
}

export default Resources;
