// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".learning-lab-aside_root_5fe54fd8 h3 {\n    margin: 114px auto 28px;\n}\n\n.learning-lab-aside_img_9227957a {\n    position: absolute;\n    top: 0;\n    left: 50%;\n    transform: translate(-50%, -37%);\n    width: 236px;\n}\n\n.learning-lab-aside_qr_5a63945f {\n    width: 160px;\n    margin: 0 auto 24px;\n}\n\n.learning-lab-aside_block_b1c4064b {\n    width: calc(100% - 32px);\n    margin: 8px auto 0;\n    padding: 8px;\n    background-color: var(--peteyBlue);\n}\n\n.learning-lab-aside_block_b1c4064b p {\n    font-weight: 600;\n    font-size: 24px;\n    line-height: 26px;\n    padding: 8px;\n    width: 100%;\n    border: 3px dashed var(--huckleberryBlue);\n}", "",{"version":3,"sources":["webpack://src/domains/dashboard/view/learning-lab-aside/learning-lab-aside.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,SAAS;IACT,gCAAgC;IAChC,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,wBAAwB;IACxB,kBAAkB;IAClB,YAAY;IACZ,kCAAkC;AACtC;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,YAAY;IACZ,WAAW;IACX,yCAAyC;AAC7C","sourcesContent":[".root h3 {\n    margin: 114px auto 28px;\n}\n\n.img {\n    position: absolute;\n    top: 0;\n    left: 50%;\n    transform: translate(-50%, -37%);\n    width: 236px;\n}\n\n.qr {\n    width: 160px;\n    margin: 0 auto 24px;\n}\n\n.block {\n    width: calc(100% - 32px);\n    margin: 8px auto 0;\n    padding: 8px;\n    background-color: var(--peteyBlue);\n}\n\n.block p {\n    font-weight: 600;\n    font-size: 24px;\n    line-height: 26px;\n    padding: 8px;\n    width: 100%;\n    border: 3px dashed var(--huckleberryBlue);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "learning-lab-aside_root_5fe54fd8",
	"img": "learning-lab-aside_img_9227957a",
	"qr": "learning-lab-aside_qr_5a63945f",
	"block": "learning-lab-aside_block_b1c4064b"
};
export default ___CSS_LOADER_EXPORT___;
