import Store from 'store';

import { IS_LOGGED_IN } from '@pumpkincare/portal-app';
import { asyncInitialState, asyncReduxCreator } from '@pumpkincare/portal-shared';

const [SET_APP_AUTH, SET_APP_AUTH_ERROR, SET_APP_AUTH_SUCCESS, setAppAuth] =
  asyncReduxCreator('SET_APP_AUTH');
const [
  SET_APP_RESET_PW,
  SET_APP_RESET_PW_ERROR,
  SET_APP_RESET_PW_SUCCESS,
  setAppResetPassword,
] = asyncReduxCreator('SET_APP_RESET_PW');
const RESET_APP_RESET_PW = 'app/RESET_RESET_PW';

const appInitialState = {
  auth: { isLoggedIn: Store.get(IS_LOGGED_IN), ...asyncInitialState(null) },
  resetPassword: asyncInitialState(null),
};

export default function appReducer(state = appInitialState, { payload, type } = {}) {
  switch (type) {
    case SET_APP_AUTH:
    case SET_APP_AUTH_SUCCESS:
    case SET_APP_AUTH_ERROR:
      return { ...state, auth: { ...state.auth, ...payload } };

    case SET_APP_RESET_PW:
    case SET_APP_RESET_PW_SUCCESS:
    case SET_APP_RESET_PW_ERROR:
      return { ...state, resetPassword: { ...state.resetPassword, ...payload } };

    case RESET_APP_RESET_PW:
      return { ...state, resetPassword: appInitialState.resetPassword };

    default:
      return state;
  }
}

export { setAppAuth, setAppResetPassword, SET_APP_AUTH_SUCCESS };

export function resetAppResetPassword() {
  return { type: RESET_APP_RESET_PW };
}
