// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".reasons-modal_root_facf6dd1 h3 {\n    margin-bottom: 8px;\n}\n\n.reasons-modal_title_3aca51ad {\n    margin-bottom: 20px;\n}\n\n.reasons-modal_label_0d1f4816 {\n    font-size: 16px;\n    line-height: 24px;\n    display: block;\n    margin-top: 24px;\n}\n\n.reasons-modal_submit_e5fb74bd {\n    margin: 0 auto;\n    display: block;\n}\n\n.reasons-modal_error_047f8dd6 {\n    color: var(--crookshanksRed);\n    font-size: 14px;\n    margin-bottom: 16px;\n    text-align: center;\n}", "",{"version":3,"sources":["webpack://src/domains/records/view/reasons-modal/reasons-modal.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,cAAc;AAClB;;AAEA;IACI,4BAA4B;IAC5B,eAAe;IACf,mBAAmB;IACnB,kBAAkB;AACtB","sourcesContent":[".root h3 {\n    margin-bottom: 8px;\n}\n\n.title {\n    margin-bottom: 20px;\n}\n\n.label {\n    font-size: 16px;\n    line-height: 24px;\n    display: block;\n    margin-top: 24px;\n}\n\n.submit {\n    margin: 0 auto;\n    display: block;\n}\n\n.error {\n    color: var(--crookshanksRed);\n    font-size: 14px;\n    margin-bottom: 16px;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "reasons-modal_root_facf6dd1",
	"title": "reasons-modal_title_3aca51ad",
	"label": "reasons-modal_label_0d1f4816",
	"submit": "reasons-modal_submit_e5fb74bd",
	"error": "reasons-modal_error_047f8dd6"
};
export default ___CSS_LOADER_EXPORT___;
