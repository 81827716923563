import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

import { claimsReducer } from '../../claims';
import { patientsReducer } from '../../patients';
import { visitReducer } from '../../visit';
import { appReducer } from '../state';

const RESET = 'RESET';

export default function pumpkinReducer(history) {
  const reducers = combineReducers({
    app: appReducer,
    patients: patientsReducer,
    router: connectRouter(history),
    visit: visitReducer,
    claim: claimsReducer,
  });

  return (state, action) => {
    const newState =
      action.type === RESET
        ? {
            router: state.router,
          }
        : state;

    return reducers(newState, action);
  };
}

export function resetStoreState() {
  return { type: RESET };
}
