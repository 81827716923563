import { fetchWrapper } from '@pumpkincare/portal-shared';

export default function apiMiddleware({ dispatch }) {
  return next => action => {
    // next(action);

    if (action.type !== 'API') {
      return next(action);
    }

    const { url, method, options = {} } = action.payload;

    function handleActionByStatus(key) {
      return options[key]
        ? (data, status) => dispatch(options[key](data, status))
        : options.handleActions
        ? (data, status) => dispatch(options.handleActions(data, status))
        : () => {};
    }

    // eslint-disable-next-line consistent-return
    return fetchWrapper(url, {
      ...options,
      method,
      onError: handleActionByStatus('onError'),
      onLoading: handleActionByStatus('onLoading'),
      onSuccess: handleActionByStatus('onSuccess'),
    }).finally(() => {
      next(action);
    });
  };
}
