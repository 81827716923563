import { useQuery } from 'react-query';

import { BASE_API } from '@pumpkincare/portal-config';
import { fetchWrapper } from '@pumpkincare/portal-shared';

export const MEDICAL_RECORDS_REQUEST_QUERY = 'medicalRecordsRequest';

/*
 BE returns array of records, but FE always wants to render allMedicalRecords, medicalRecords, and invoice in that order
 rather that sort by type, easier to transform into an object by type and conditionally render each type
 */
function transformRecords(records) {
  return records?.reduce(
    (result, record) => {
      if (record.type === 'ALL_RECORDS') {
        result.allMedicalRecords = record;
      } else if (record.type === 'INVOICE') {
        result.invoice = record;
      } else if (record.type === 'RECORD') {
        result.medicalRecords.push(record);
      }

      return result;
    },
    { medicalRecords: [] }
  );
}

export function useMedicalRecordsRequest({ recordRequestId } = {}, options = {}) {
  return useQuery(
    [MEDICAL_RECORDS_REQUEST_QUERY, recordRequestId],

    () =>
      fetchWrapper(`/medical-records-requests/${recordRequestId}`, {
        baseApi: BASE_API,
        transformData: data => ({
          ...data,
          records: transformRecords(data.records),
        }),
      }),

    {
      enabled: !!recordRequestId,
      placeholderData: {},
      ...options,
    }
  );
}
