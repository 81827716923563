import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  checkPasswordRequirements,
  LOADING,
  useTargetState,
  useToggleState,
} from '@pumpkincare/portal-shared';
import {
  A,
  Body1,
  ButtonPrimary,
  Checkbox,
  PasswordRequirements,
  SubText1,
  TextField,
} from '@pumpkincare/portal-ui';

import { CONTACT_EMAIL_LINK } from '../../constants';
import { PATH_TERMS } from '../../routing';
import {
  getAppResetPasswordError,
  getAppResetPasswordStatus,
  getRouterLocationQuery,
} from '../../selectors';
import registerUser from '../../thunks/register-user';
import ErrorPage from '../error-page';

import styles from './register-page.css';

import catComputer from '../../../../../../../public/assets/paw-portal/illustrations/cat/computer.png';

export default function RegisterPage() {
  const dispatch = useDispatch();
  const [areErrorsVisible, setErrorsVisible] = useState(false);
  const [isPermitted, togglePermission] = useToggleState(false);
  const [password, setPassword, passwordValidity] = useTargetState(
    '',
    checkPasswordRequirements
  );
  const [confirmPassword, setConfirmPassword, arePasswordsMatching] = useTargetState(
    '',
    value => value && value === password
  );
  const query = useSelector(getRouterLocationQuery);
  const isLoading = useSelector(getAppResetPasswordStatus) === LOADING;
  const error = useSelector(getAppResetPasswordError);
  const isExpired = error?.message.match(/Token/i);

  const { email, firstName, lastName, token, vetName } = useMemo(() => {
    return {
      token: query.token,
      email: decodeURIComponent(query.email),
      firstName: decodeURIComponent(query.first_name),
      lastName: decodeURIComponent(query.last_name),
      vetName: decodeURIComponent(query.vet_display_name),
    };
  }, [query]);

  function handleSubmit(e) {
    e.preventDefault();

    if (passwordValidity.hasErrors || !arePasswordsMatching || !isPermitted) {
      setErrorsVisible(true);
      return;
    }

    dispatch(registerUser(token, email, password));
  }

  return (
    <>
      {!isExpired ? (
        <div className={styles.container}>
          <img
            src={catComputer}
            alt='cat on computer'
            role='presentation'
            className={styles.image}
          />
          <h4 className={styles.header}>
            {`Let's get started by creating your account with ${vetName}.`}
          </h4>
          <SubText1 className={styles.subtext}>
            <span className={styles.asterisk}>*</span>Required Field
          </SubText1>

          <form onSubmit={handleSubmit} noValidate className={styles.form}>
            <TextField
              id='user-first'
              value={firstName}
              label='First Name'
              readOnly
            />

            <TextField id='user-last' value={lastName} label='Last Name' readOnly />

            <TextField id='user-email' value={email} label='Email' readOnly />

            <TextField
              id='user-password'
              value={password}
              label='Password'
              type='password'
              onChange={setPassword}
              error={{ hasError: areErrorsVisible && passwordValidity.hasErrors }}
              required
            />

            <TextField
              id='user-confirm-password'
              value={confirmPassword}
              label='Confirm Password'
              type='password'
              onChange={setConfirmPassword}
              error={{
                errorMessage:
                  areErrorsVisible && !arePasswordsMatching
                    ? "Password doesn't match"
                    : '',
              }}
              required
            />

            <PasswordRequirements validation={passwordValidity} />

            <Checkbox
              id='permission'
              label={
                <>
                  {`Yep! I have read and agree to the `}
                  <A href={PATH_TERMS} target='_blank'>
                    Pumpkin PawPortal Terms of Use
                  </A>
                  .
                </>
              }
              onChange={togglePermission}
              checked={isPermitted}
              classes={{
                container: styles.checkbox,
                box: styles.box,
              }}
              isErrorVisible={areErrorsVisible}
            />

            <ButtonPrimary
              isLoading={isLoading}
              className={styles.submit}
              type='submit'
            >
              Sign Up
            </ButtonPrimary>
          </form>

          <Body1 className={styles.contact}>
            Having trouble setting up your account?
            <br />
            <A href={CONTACT_EMAIL_LINK}>Contact the PawPortal Care Team</A>
          </Body1>
          {error ? (
            <Body1 className={styles.error}>
              Sorry, something went wrong! Please try again later or reach out to our
              care team.
            </Body1>
          ) : null}
        </div>
      ) : (
        <ErrorPage
          content={
            <div className={styles.errorContainer}>
              <h1 className={styles.errorHeader}>Ruh Roh!</h1>
              <h4 className={styles.errorSubheader}>Your invitation has expired.</h4>
              <Body1 className={styles.errorCopy}>
                Your invitation to PawPortal has expired. To request a new
                invitation, please contact our Pumpkin Care Team:
              </Body1>
              <A href={CONTACT_EMAIL_LINK}>
                <ButtonPrimary className={styles.errorButton}>
                  Contact Pumpkin Care Team
                </ButtonPrimary>
              </A>
            </div>
          }
        />
      )}
    </>
  );
}
