export const CONTACT_EMAIL = 'pawportal@pumpkin.care';
export const CONTACT_EMAIL_LINK = `mailto:${CONTACT_EMAIL}`;

export const HELP_EMAIL = 'help@pumpkin.care';
export const HELP_EMAIL_LINK = `mailto:${HELP_EMAIL}`;

export const SALES_EMAIL = 'sales@pumpkin.care';

export const RECORDS_EMAIL = 'records@pumpkin.care';
export const RECORDS_EMAIL_LINK = `mailto:${RECORDS_EMAIL}`;

export const CONTACT_HOURS = 'Mon-Fri, 8am-8pm EST';
export const CONTACT_PHONE = '1-866-273-6369';
export const CONTACT_PHONE_ARFMEOW = '1‑866‑ARF‑MEOW';
export const CONTACT_PHONE_LINK = `tel:${CONTACT_PHONE}`;
export const CONTACT_PAWSIGHTS_PHONE = '1-888-299-7297';

export const ADDRESS = '432 Park Avenue South, Floor 12, New York, NY 10016';

export const INSTAGRAM_LINK = 'https://www.instagram.com/pumpkinpetcare/';
export const FACEBOOK_LINK = 'https://www.facebook.com/PetcarePumpkin';
export const TWITTER_LINK = 'https://twitter.com/pumpkinpetcare';
