// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../../../../libs/shared/src/ui/breakpoints.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".no-claims_link_8775dd0a,\n.no-claims_content_0cf19e2a {\n    max-width: 340px;\n    margin: 0 auto;\n    text-align: center;\n}\n\n.no-claims_link_8775dd0a {\n    display: block;\n    margin-top: 20px;\n    color: var(--royal);\n    text-decoration: none;\n}\n\n@media " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["b-mobile"] + " {\n    .no-claims_link_8775dd0a {\n        font-size: 14px;\n    }\n}", "",{"version":3,"sources":["webpack://src/domains/patients/view/no-claims/no-claims.css"],"names":[],"mappings":"AAEA;;IAEI,gBAAgB;IAChB,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,mBAAmB;IACnB,qBAAqB;AACzB;;AAEA;IACI;QACI,eAAe;IACnB;AACJ","sourcesContent":["@value b-mobile from '~@pumpkincare/shared/ui/breakpoints';\n\n.link,\n.content {\n    max-width: 340px;\n    margin: 0 auto;\n    text-align: center;\n}\n\n.link {\n    display: block;\n    margin-top: 20px;\n    color: var(--royal);\n    text-decoration: none;\n}\n\n@media b-mobile {\n    .link {\n        font-size: 14px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"b-mobile": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["b-mobile"] + "",
	"link": "no-claims_link_8775dd0a",
	"content": "no-claims_content_0cf19e2a"
};
export default ___CSS_LOADER_EXPORT___;
