import { useState } from 'react';

import { useTargetState } from '@pumpkincare/portal-shared';
import {
  Body1,
  ButtonPrimary,
  Modal,
  Radio,
  RadioGroup,
  TextArea,
} from '@pumpkincare/portal-ui';

import styles from './reasons-modal.css';

function ReasonsModal({ petName, toggleClosed, onSubmit, isLoading, isError }) {
  const [reason, setReason] = useState();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [notes, setNotes] = useTargetState('');

  const needsNotes = reason === 'REASON_OTHER' && !notes;

  function handleModalSubmit() {
    setHasSubmitted(true);

    if (!reason || needsNotes) {
      return;
    }

    onSubmit(reason, notes);
  }

  return (
    <Modal onClose={toggleClosed} classes={{ container: styles.root }}>
      <h3>Can't track down {petName}'s medical records?</h3>
      <Body1 className={styles.title}>Please let us know the reason:</Body1>

      <RadioGroup
        name='reason'
        value={reason}
        onChange={setReason}
        error={{
          errorMessage: hasSubmitted && !reason ? 'Please select a reason' : '',
        }}
      >
        <Radio
          value='REASON_NO_PATIENT'
          label='Patient or owner not found in our system'
        />
        <Radio
          value='REASON_NO_RECORDS'
          label='Patient found, but requested record(s) unavailable'
        />
        <Radio
          value='REASON_NOT_CURRENTLY_AVAILABLE'
          label="Still finalizing doctor's notes"
        />
        <Radio value='REASON_OTHER' label='Other (please specify below)' />
      </RadioGroup>

      <label htmlFor='notes' className={styles.label}>
        Additional information that may assist the Pumpkin Claims Team:
      </label>
      <TextArea
        value={notes}
        onChange={setNotes}
        id='notes'
        rows={4}
        errorMessage={
          hasSubmitted && needsNotes
            ? 'Please provide us with some additional information'
            : ''
        }
      />

      {isError ? (
        <Body1 className={styles.error}>
          Looks like something went wrong. Try contacting our Pumpkin Care Team.
        </Body1>
      ) : null}

      <ButtonPrimary
        className={styles.submit}
        onClick={handleModalSubmit}
        isLoading={isLoading}
      >
        Send
      </ButtonPrimary>
    </Modal>
  );
}

export default ReasonsModal;
