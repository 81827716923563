import { Redirect } from 'react-router-dom';

import { useFrames, useVetProducts } from '@pumpkincare/portal-app';
import { AUTH_VET_LS_KEY, getIsLoggedIn } from '@pumpkincare/portal-shared';
import { getVetSelfVetId, useVetSelf } from '@pumpkincare/portal-user';
import { getVetState, useVet } from '@pumpkincare/portal-vets';

import { PATH_HOME } from '../../routing/paths';
import PageComponent from '../page-component';

function PrivatePageComponent({ component, hideNavBar, hideFooter }) {
  const isLoggedIn = !!getIsLoggedIn(AUTH_VET_LS_KEY);
  const { data: vetId } = useVetSelf({ select: getVetSelfVetId });

  const { data: vetData } = useVet(vetId);
  useVetProducts(getVetState(vetData));
  useFrames(vetId);

  return isLoggedIn ? (
    <PageComponent
      isPrivate
      component={component}
      hideNavBar={hideNavBar}
      hideFooter={hideFooter}
    />
  ) : (
    <Redirect to={PATH_HOME} />
  );
}

export default PrivatePageComponent;
