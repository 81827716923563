import { trackCustomerPEP } from '@pumpkincare/analytics';
import { A, BodySubhead, ButtonPrimary, LegalBody } from '@pumpkincare/portal-ui';

import { PATH_EXT_QUOTE_FLOW, PATH_EXT_UNDERWRITING } from '../../../app';
import { PP_BASE_UTM_PARAMS } from '../../constants';

import styles from './create-plan-nav.css';

import pumpkin from '../../../../../../../public/assets/paw-portal/logos/pumpkin.svg';

export default function CreatePlanNav({ pepVersion }) {
  return (
    <>
      <div className={styles.utility}>
        <A href={`${PATH_EXT_UNDERWRITING}?${PP_BASE_UTM_PARAMS}${pepVersion}`}>
          <LegalBody>Underwriting</LegalBody>
        </A>

        <A href='tel:+18662736369'>
          <BodySubhead className={styles.subhead}>1-866-ARF-MEOW</BodySubhead>
        </A>
      </div>
      <div className={styles.create}>
        <img src={pumpkin} alt='Pumpkin' />
        <A
          href={`${PATH_EXT_QUOTE_FLOW}?${PP_BASE_UTM_PARAMS}${pepVersion}`}
          onClick={() =>
            trackCustomerPEP('Clicked Create Plan', pepVersion, 'Header')
          }
          target='_blank'
        >
          <ButtonPrimary>Create Plan</ButtonPrimary>
        </A>
      </div>
    </>
  );
}
