import { useCallback, useState } from 'react';

import {
  ERROR,
  fetchWrapper,
  IDLE,
  LOADING,
  SUCCESS,
} from '@pumpkincare/portal-shared';

const DEFAULT_LIMIT = 6;

export default function useLoadVisitsPhotos(initialValue) {
  const [visitsPhotos, setVisitsPhotos] = useState(initialValue);
  const [status, setStatus] = useState(IDLE);
  const [page, setPage] = useState(0);
  const [totalPhotos, setTotalPhotos] = useState(0);

  const loadVisitPhotos = useCallback((keyword, newPage) => {
    setStatus(LOADING);

    fetchWrapper(
      `/visits?keyword=${keyword}&start=${
        newPage * DEFAULT_LIMIT
      }&limit=${DEFAULT_LIMIT}`,
      { isAuth: true }
    )
      .then(data => {
        setStatus(SUCCESS);
        setTotalPhotos(data.total);

        if (newPage === 0) {
          setVisitsPhotos(data.visits);
        } else {
          setVisitsPhotos(state => [...state, ...data.visits]);
        }
      })
      .catch(() => {
        setStatus(ERROR);
      });
  }, []);

  const loadNewSearch = useCallback(
    keyword => {
      loadVisitPhotos(keyword, 0);
      setPage(0);
    },
    [loadVisitPhotos]
  );

  const loadNewPage = useCallback(
    keyword => {
      loadVisitPhotos(keyword, page + 1);
      setPage(state => state + 1);
    },
    [loadVisitPhotos, page]
  );

  return {
    totalPhotos,
    visitsPhotos,
    loadNewSearch,
    loadNewPage,
    statuses: {
      isError: status === ERROR,
      isLoading: status === LOADING,
      isSuccess: status === SUCCESS,
      isDone: totalPhotos <= page * DEFAULT_LIMIT,
    },
  };
}
