// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".reset-password-page_container_62603230 {\n    width: var(--column10);\n    max-width: 360px;\n    margin: 40px auto 0;\n    min-height: 70vh;\n    padding-bottom: 120px;\n}\n\n.reset-password-page_title_ac4349d6 {\n    margin-bottom: 34px;\n    text-align: center;\n}\n\n.reset-password-page_title_ac4349d6 {\n    display: flex;\n    align-items: center;\n}\n\n.reset-password-page_success_66c9bf7f {\n    margin-right: 10px;\n}\n\n.reset-password-page_button_1d1774e9 {\n    display: block;\n    width: 100%;\n    margin-top: 24px;\n}", "",{"version":3,"sources":["webpack://src/domains/app/view/reset-password-page/reset-password-page.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,gBAAgB;IAChB,mBAAmB;IACnB,gBAAgB;IAChB,qBAAqB;AACzB;;AAEA;IACI,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,WAAW;IACX,gBAAgB;AACpB","sourcesContent":[".container {\n    width: var(--column10);\n    max-width: 360px;\n    margin: 40px auto 0;\n    min-height: 70vh;\n    padding-bottom: 120px;\n}\n\n.title {\n    margin-bottom: 34px;\n    text-align: center;\n}\n\n.title {\n    display: flex;\n    align-items: center;\n}\n\n.success {\n    margin-right: 10px;\n}\n\n.button {\n    display: block;\n    width: 100%;\n    margin-top: 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "reset-password-page_container_62603230",
	"title": "reset-password-page_title_ac4349d6",
	"success": "reset-password-page_success_66c9bf7f",
	"button": "reset-password-page_button_1d1774e9"
};
export default ___CSS_LOADER_EXPORT___;
