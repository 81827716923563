// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dashboard-legal_root_96ccdf3d {\n    padding: 80px 56px 0;\n    color: var(--cujoCream);\n}\n\n.dashboard-legal_title_0e846080 {\n    color: var(--zeroWhite);\n    font-size: 18px;\n    line-height: 24px;\n    font-weight: 800;\n    margin-bottom: 24px;\n}\n\n.dashboard-legal_root_96ccdf3d ul {\n    padding-left: 24px;\n}\n\n.dashboard-legal_root_96ccdf3d p, .dashboard-legal_root_96ccdf3d li {\n    font-size: 13px;\n    line-height: 18px;\n    font-weight: 600;\n}\n\n.dashboard-legal_root_96ccdf3d p, .dashboard-legal_root_96ccdf3d ul {\n    margin-bottom: 18px;\n}", "",{"version":3,"sources":["webpack://src/domains/dashboard/view/dashboard-legal/dashboard-legal.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,uBAAuB;AAC3B;;AAEA;IACI,uBAAuB;IACvB,eAAe;IACf,iBAAiB;IACjB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".root {\n    padding: 80px 56px 0;\n    color: var(--cujoCream);\n}\n\n.title {\n    color: var(--zeroWhite);\n    font-size: 18px;\n    line-height: 24px;\n    font-weight: 800;\n    margin-bottom: 24px;\n}\n\n.root ul {\n    padding-left: 24px;\n}\n\n.root p, .root li {\n    font-size: 13px;\n    line-height: 18px;\n    font-weight: 600;\n}\n\n.root p, .root ul {\n    margin-bottom: 18px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "dashboard-legal_root_96ccdf3d",
	"title": "dashboard-legal_title_0e846080"
};
export default ___CSS_LOADER_EXPORT___;
