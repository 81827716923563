import { LOADING } from '@pumpkincare/portal-shared';

import { apiGet } from '../../app';
import { setPatientsSearch, updatePatientsSearch } from '../state';

export default function loadPatientSearch(keyword, page = 0, limit = 10) {
  return dispatch => {
    dispatch(updatePatientsSearch({ status: LOADING }));
    return dispatch(
      apiGet({
        url: `/patients/search?keyword=${keyword}&start=${
          page * limit
        }&limit=${limit}`,
        options: {
          handleActions: page === 0 ? setPatientsSearch : updatePatientsSearch,
          isAuth: true,
        },
      })
    );
  };
}
