// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".request-password-page_container_a3ae2293 {\n    width: var(--column10);\n    max-width: 356px;\n    margin: 40px auto 0;\n    min-height: 70vh;\n    padding-bottom: 120px;\n}\n\n.request-password-page_text_53efaaea,\n.request-password-page_title_b5e47e9e {\n    margin-bottom: 24px;\n    text-align: center;\n}\n\n.request-password-page_title_b5e47e9e {\n    display: flex;\n    align-items: center;\n}\n\n.request-password-page_success_5ca1091d {\n    margin-right: 10px;\n}\n\n.request-password-page_button_098e3f39 {\n    display: block;\n    width: 100%;\n}\n\n.request-password-page_reset_9ed6094f {\n    color: var(--bluesClues);\n    cursor: pointer;\n}\n\n.request-password-page_error_f88880d6 {\n    color: var(--redAlert);\n    text-align: center;\n    margin-top: 16px;\n    font-size: 12px;\n}", "",{"version":3,"sources":["webpack://src/domains/app/view/request-password-page/request-password-page.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,gBAAgB;IAChB,mBAAmB;IACnB,gBAAgB;IAChB,qBAAqB;AACzB;;AAEA;;IAEI,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,WAAW;AACf;;AAEA;IACI,wBAAwB;IACxB,eAAe;AACnB;;AAEA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;AACnB","sourcesContent":[".container {\n    width: var(--column10);\n    max-width: 356px;\n    margin: 40px auto 0;\n    min-height: 70vh;\n    padding-bottom: 120px;\n}\n\n.text,\n.title {\n    margin-bottom: 24px;\n    text-align: center;\n}\n\n.title {\n    display: flex;\n    align-items: center;\n}\n\n.success {\n    margin-right: 10px;\n}\n\n.button {\n    display: block;\n    width: 100%;\n}\n\n.reset {\n    color: var(--bluesClues);\n    cursor: pointer;\n}\n\n.error {\n    color: var(--redAlert);\n    text-align: center;\n    margin-top: 16px;\n    font-size: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "request-password-page_container_a3ae2293",
	"text": "request-password-page_text_53efaaea",
	"title": "request-password-page_title_b5e47e9e",
	"success": "request-password-page_success_5ca1091d",
	"button": "request-password-page_button_098e3f39",
	"reset": "request-password-page_reset_9ed6094f",
	"error": "request-password-page_error_f88880d6"
};
export default ___CSS_LOADER_EXPORT___;
