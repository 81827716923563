import { PATH_FILE_CLAIM_BASE } from '../../app';

export function formatClaimRedirect(patientId = '', petId = '') {
  if (petId) {
    return `${PATH_FILE_CLAIM_BASE}?patientId=${patientId}&petId=${petId}`;
  }

  return patientId
    ? `${PATH_FILE_CLAIM_BASE}?patientId=${patientId}`
    : PATH_FILE_CLAIM_BASE;
}
