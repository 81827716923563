import { createDocumentFileSignedUrl, uploadToS3 } from '../../app';
import { getVisitPatientId } from '../selectors';
import { setVisitPhoto } from '../state';
import createVisitPatient from './create-visit-patient';
import createVisitPhoto from './create-visit-photo';
import updateVisitPatient from './update-visit-patient';

export default function submitVisit(vetId, patientData, image, frame) {
  return (dispatch, getState) => {
    const state = getState();
    const visitId = getVisitPatientId(state);

    const visitAction = visitId
      ? () => updateVisitPatient(vetId, patientData, visitId)
      : () => createVisitPatient(vetId, patientData);

    return Promise.all([
      dispatch(
        createDocumentFileSignedUrl(vetId, image, {
          onError: setVisitPhoto,
          onLoading: setVisitPhoto,
        })
      ),
      dispatch(visitAction()),
    ]).then(([docData]) => {
      return dispatch(uploadToS3(image, docData, { onError: setVisitPhoto })).then(
        () => dispatch(createVisitPhoto(vetId, frame.id, docData.id))
      );
    });
  };
}
