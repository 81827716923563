import { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import {
  AppMeta,
  PATH_PAWSIGHTS_BASE,
  PATH_PAWSIGHTS_FORM,
  PATH_PAWSIGHTS_LANDING,
  PATH_PAWSIGHTS_REPORT,
} from '../../../app';
import PawsightsForm from '../pawsights-form';
import PawsightsLanding from '../pawsights-landing';
import PawsightsReport from '../pawsights-report';

function PawsightsFlow() {
  useEffect(() => {
    // clear quote-related session storage on Pawsights load, but not on page change
    const vetId = sessionStorage.getItem('@pumpkincare/vetId');
    sessionStorage.clear();
    if (vetId) sessionStorage.setItem('@pumpkincare/vetId', vetId);
  }, []);

  return (
    <>
      <AppMeta description='Discover your pet’s unique breed-specific needs with a Pumpkin Pawsights® Report, so you can help keep your pet happy and healthy.' />
      <Switch>
        <Route path={PATH_PAWSIGHTS_LANDING}>
          <PawsightsLanding />
        </Route>
        <Route path={PATH_PAWSIGHTS_FORM}>
          <PawsightsForm />
        </Route>
        <Route path={PATH_PAWSIGHTS_REPORT}>
          <PawsightsReport />
        </Route>

        <Route path={PATH_PAWSIGHTS_BASE}>
          <Redirect to={PATH_PAWSIGHTS_LANDING} />
        </Route>
      </Switch>
    </>
  );
}

export default PawsightsFlow;
