import { LOCAL_SEGMENT_KEY, SEGMENT_KEY } from '@pumpkincare/portal-config';

export function loadSegment(onSegmentReady, loadOptions = {}) {
  // eslint-disable-next-line no-multi-assign
  const analytics = (window.analytics = window.analytics || []);

  if (!analytics.initialize) {
    if (analytics.invoked) {
      window?.console?.error('Segment snippet included twice.');
    } else {
      analytics.invoked = !0;
      analytics.methods = [
        'trackSubmit',
        'trackClick',
        'trackLink',
        'trackForm',
        'pageview',
        'identify',
        'reset',
        'group',
        'track',
        'ready',
        'alias',
        'debug',
        'page',
        'once',
        'off',
        'on',
      ];
      analytics.factory = function (t) {
        return function () {
          // eslint-disable-next-line prefer-rest-params
          const e = Array.prototype.slice.call(arguments);
          e.unshift(t);

          analytics.push(e);

          return analytics;
        };
      };

      for (let t = 0; t < analytics.methods.length; t++) {
        const e = analytics.methods[t];

        analytics[e] = analytics.factory(e);
      }

      analytics.load = function (t, e) {
        const n = document.createElement('script');
        n.type = 'text/javascript';
        n.async = !0;
        n.src = `https://cdn.segment.com/analytics.js/v1/${t}/analytics.min.js`;

        const a = document.getElementsByTagName('script')[0];
        a.parentNode.insertBefore(n, a);
        // eslint-disable-next-line no-underscore-dangle
        analytics._loadOptions = e;
      };
      analytics.SNIPPET_VERSION = '4.1.0';
      const key = DEBUG ? LOCAL_SEGMENT_KEY : SEGMENT_KEY;
      analytics.load(key, loadOptions);

      const callback = function () {
        const segmentId = window.analytics.user().anonymousId();
        // window.analytics.page(getPageTitle(pathname));
        window.mixpanel?.alias(segmentId);
        window.mixpanel?.identify(segmentId);
        onSegmentReady();
      };

      window.analytics.ready(callback);
    }
  }
}
