// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".legal-section_legalSection_8a2fcad4 {\n    background-color: var(--cream);\n    padding: 108px var(--column1) 64px;\n}\n\n.legal-section_legal_7f59051c {\n    color: var(--scoutNavyLight);\n    max-width: 1440px;\n    font-size: 14px;\n    line-height: 18px;\n    margin: 0 auto 24px;\n}\n\n.legal-section_legal_7f59051c a {\n    color: var(--royal);\n    text-decoration: none;\n}\n\n.legal-section_legal_7f59051c a:hover {\n    text-decoration: underline;\n}", "",{"version":3,"sources":["webpack://src/domains/app/view/legal-section/legal-section.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,kCAAkC;AACtC;;AAEA;IACI,4BAA4B;IAC5B,iBAAiB;IACjB,eAAe;IACf,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;IACnB,qBAAqB;AACzB;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":[".legalSection {\n    background-color: var(--cream);\n    padding: 108px var(--column1) 64px;\n}\n\n.legal {\n    color: var(--scoutNavyLight);\n    max-width: 1440px;\n    font-size: 14px;\n    line-height: 18px;\n    margin: 0 auto 24px;\n}\n\n.legal a {\n    color: var(--royal);\n    text-decoration: none;\n}\n\n.legal a:hover {\n    text-decoration: underline;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"legalSection": "legal-section_legalSection_8a2fcad4",
	"legal": "legal-section_legal_7f59051c"
};
export default ___CSS_LOADER_EXPORT___;
