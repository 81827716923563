import { apiGet } from '../../app';
import { setAllClaims } from '../state';

export default function loadPatientPetClaims(petId, page = 0, limit = 5) {
  return dispatch => {
    return dispatch(
      apiGet({
        url: `/claims/pet/${petId}?start=${page * limit}&limit=${limit}`,
        options: {
          isAuth: true,
          handleActions: setAllClaims,
        },
      })
    );
  };
}
