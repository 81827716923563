import * as LDClient from 'launchdarkly-js-client-sdk';
import { withLDConsumer, withLDProvider } from 'launchdarkly-react-client-sdk';
import { compose } from 'redux';

export function loadLaunchDarkly(clientSideID, user) {
  return {
    withLaunchDarkly: compose(
      withLDProvider({ clientSideID, user, options: { streaming: false } }),
      withLDConsumer()
    ),
    ldClient: LDClient.initialize(clientSideID, user),
  };
}
