import { createSelector } from 'reselect';

import { formatCurrency, formatISODate } from '@pumpkincare/portal-shared';

import {
  getPatientsPets,
  getPatientsPetsRecentClaims,
} from '../../patients-selectors';

const getStatePetFromProps = (state, pet) => pet;

function formatAge(age) {
  const unit = !Number.isNaN(Number(age)) ? ` Year${age !== 1 ? 's' : ''}` : '';
  return `Age ${age}${unit}`;
}

const getPatientActivePet = createSelector(
  [getPatientsPets, getPatientsPetsRecentClaims, getStatePetFromProps],
  (allPets, allRecentClaims, statePet) => {
    const key = statePet?.id || statePet?.name;
    const activePet = allPets[key] || {};

    return activePet.id
      ? {
          ...activePet,
          formattedGender: activePet.gender === 'F' ? 'Female' : 'Male',
          formattedAge: activePet.birth_date
            ? formatISODate(activePet.birth_date)
            : formatAge(activePet.age),
          plan: activePet.plan
            ? {
                ...activePet.plan,
                formattedEffectiveDate: activePet.plan.plan_effective_date
                  ? formatISODate(activePet.plan.plan_effective_date, {
                      inputTimezone: 'local',
                    })
                  : null,
              }
            : null,
          policy: activePet.policy
            ? {
                ...activePet.policy,
                formattedPolicyEffectiveDate: activePet.policy
                  .policy_illness_effective_date
                  ? formatISODate(activePet.policy.policy_illness_effective_date, {
                      inputTimezone: 'local',
                    })
                  : null,
                formattedDeductible: formatCurrency(
                  activePet.policy.deductible.replace(/[^\d.]/g, ''),
                  {
                    useInteger: true,
                  }
                ),
                formattedAnnualLimit: formatCurrency(
                  activePet.policy.annual_limit.replace(/[^\d.]/g, ''),
                  {
                    useInteger: true,
                  }
                ),
                formattedReimbursementRate:
                  100 - Number(activePet.policy.coinsurance.replace(/\D+/g, '')),
              }
            : {},
          recentClaims: allRecentClaims[key] || [],
        }
      : activePet;
  }
);

export default getPatientActivePet;
