import classNames from 'classnames';

import { analyticsTrack } from '@pumpkincare/analytics';
import { CAT, formatCurrency, PET_AGE_OPTIONS } from '@pumpkincare/portal-shared';
import { ButtonPrimary } from '@pumpkincare/portal-ui';

import formatQuoteFlowRoute from '../../../utils/format-quote-flow-route';

import styles from './header.css';

import catIcon from '../../../../../../../../public/assets/paw-portal/illustrations/cat/cat-icon.png';
import dogIcon from '../../../../../../../../public/assets/paw-portal/illustrations/dog/dog-icon.png';

function Header({ pet, policyOptions = {}, quoteId }) {
  const { petAge, petBreedName, petBreedSpecies, petGender, petName } = pet;
  const { prices } = policyOptions;
  const isCat = petBreedSpecies === CAT;

  return (
    <div className={classNames(styles.root, { [styles.hasPrices]: prices })}>
      <div className={styles.petIcon}>
        <img alt='' src={isCat ? catIcon : dogIcon} />
      </div>
      <h1>
        {petName}’s Pawsights<sup>®</sup> Report
      </h1>
      <div>
        {prices ? (
          <>
            <p className={styles.planInfo}>
              Pumpkin Plan for {petName}:{' '}
              {formatCurrency(
                prices.Copay10[isCat ? 'Limit7000' : 'Limit10000'].Deductible500
              )}
              {'/mo'}
            </p>
            <p className={styles.petInfo}>
              {petBreedName} &#8226;{' '}
              {petGender.charAt(0).toUpperCase() + petGender.slice(1)} &#8226;{' '}
              {PET_AGE_OPTIONS.find(option => option.value === petAge)?.label}
            </p>
            <p className={styles.prices}>
              Reimbursement Rate: 90%; Annual Limit: {isCat ? '$7,000' : '$10,000'};
              Annual Deductible: $500
            </p>

            <a
              href={formatQuoteFlowRoute(quoteId)}
              onClick={() =>
                analyticsTrack({
                  category: 'Pawsights',
                  event: 'CTA Click',
                  label: 'Header: Quote - True',
                })
              }
              target='_blank'
              rel='noreferrer'
            >
              <ButtonPrimary className={styles.customize}>
                Customize Plan
              </ButtonPrimary>
            </a>
          </>
        ) : (
          <>
            <p className={styles.petInfo}>
              {petBreedName} &#8226;{' '}
              {petGender.charAt(0).toUpperCase() + petGender.slice(1)} &#8226;{' '}
              {PET_AGE_OPTIONS.find(option => option.value === petAge)?.label}
            </p>

            <a
              href={formatQuoteFlowRoute(quoteId)}
              onClick={() =>
                analyticsTrack({
                  category: 'Pawsights',
                  event: 'CTA Click',
                  label: 'Header: Quote - False',
                })
              }
              target='_blank'
              rel='noreferrer'
            >
              <ButtonPrimary className={styles.quote}>
                Get a Free Quote
              </ButtonPrimary>
            </a>
          </>
        )}
      </div>
    </div>
  );
}

export default Header;
