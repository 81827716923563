import { asyncInitialState, asyncReduxCreator } from '@pumpkincare/portal-shared';

const [
  SET_CLAIM_SUBMIT,
  SET_CLAIM_SUBMIT_ERROR,
  SET_CLAIM_SUBMIT_SUCCESS,
  setClaimSubmit,
] = asyncReduxCreator('SET_CLAIM_SUBMIT');
const [
  SET_CLAIM_PATIENT_SEARCH,
  SET_CLAIM_PATIENT_SEARCH_ERROR,
  SET_CLAIM_PATIENT_SEARCH_SUCCESS,
  setClaimPatientSearch,
] = asyncReduxCreator('SET_CLAIM_PATIENT_SEARCH');
const [
  SET_CLAIM_PATIENT,
  SET_CLAIM_PATIENT_ERROR,
  SET_CLAIM_PATIENT_SUCCESS,
  setClaimPatient,
] = asyncReduxCreator('SET_CLAIM_PATIENT');
const [
  SET_CLAIM_PATIENT_PET,
  SET_CLAIM_PATIENT_PET_ERROR,
  SET_CLAIM_PATIENT_PET_SUCCESS,
  setClaimPatientPet,
] = asyncReduxCreator('SET_CLAIM_PATIENT_PET');

const claimsInitialState = {
  submit: asyncInitialState(null),
  search: asyncInitialState(null),
  patient: asyncInitialState(null),
  pets: asyncInitialState({}),
};

export default function claimsReducer(
  state = claimsInitialState,
  { payload, type } = {}
) {
  switch (type) {
    case SET_CLAIM_SUBMIT:
    case SET_CLAIM_SUBMIT_ERROR:
    case SET_CLAIM_SUBMIT_SUCCESS:
      return { ...state, submit: { ...state.submit, ...payload } };

    case SET_CLAIM_PATIENT_SEARCH:
    case SET_CLAIM_PATIENT_SEARCH_SUCCESS:
    case SET_CLAIM_PATIENT_SEARCH_ERROR:
      return { ...state, search: { ...state.search, ...payload } };

    case SET_CLAIM_PATIENT:
    case SET_CLAIM_PATIENT_SUCCESS:
    case SET_CLAIM_PATIENT_ERROR:
      return { ...state, patient: { ...state.patient, ...payload } };

    case SET_CLAIM_PATIENT_PET:
    case SET_CLAIM_PATIENT_PET_ERROR:
      return { ...state, pets: { ...state.pets, ...payload } };

    case SET_CLAIM_PATIENT_PET_SUCCESS:
      return {
        ...state,
        pets: {
          ...state.pets,
          ...payload,
          data: {
            ...state.pets.data,
            [payload.data.id]: payload.data,
          },
        },
      };
    default:
      return state;
  }
}

export {
  setClaimPatient,
  setClaimPatientPet,
  setClaimPatientSearch,
  setClaimSubmit,
};
