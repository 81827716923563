import { apiPost } from '../../app';
import { setClaimSubmit } from '../state';

export default function submitClaim(claim) {
  return dispatch => {
    return dispatch(
      apiPost({
        url: `/claims`,
        options: {
          isAuth: true,
          body: claim,
          handleActions: setClaimSubmit,
          transformData: () => claim, // empty response
        },
      })
    );
  };
}
