// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".patient-filter_filterContainer_4545d448 {\n    display: flex;\n    justify-content: center;\n    margin-bottom: 24px;\n}\n\n.patient-filter_filterButton_51113518 {\n    display: flex;\n    font-weight: 700;\n    color: var(--royal);\n    min-height: 0px;\n}\n\n.patient-filter_icon_b5863fe3 {\n    width: 18px;\n    margin-right: 7px;\n}\n\n.patient-filter_popover_61c682dd {\n    top: 100%;\n    margin-top: -8px;\n    box-shadow: 0 12px 26px rgba(0, 0, 0, 0.15);\n    display: flex;\n    flex-direction: column;\n    gap: 16px;\n}", "",{"version":3,"sources":["webpack://src/domains/patients/view/patient-filter/patient-filter.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,SAAS;IACT,gBAAgB;IAChB,2CAA2C;IAC3C,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb","sourcesContent":[".filterContainer {\n    display: flex;\n    justify-content: center;\n    margin-bottom: 24px;\n}\n\n.filterButton {\n    display: flex;\n    font-weight: 700;\n    color: var(--royal);\n    min-height: 0px;\n}\n\n.icon {\n    width: 18px;\n    margin-right: 7px;\n}\n\n.popover {\n    top: 100%;\n    margin-top: -8px;\n    box-shadow: 0 12px 26px rgba(0, 0, 0, 0.15);\n    display: flex;\n    flex-direction: column;\n    gap: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filterContainer": "patient-filter_filterContainer_4545d448",
	"filterButton": "patient-filter_filterButton_51113518",
	"icon": "patient-filter_icon_b5863fe3",
	"popover": "patient-filter_popover_61c682dd"
};
export default ___CSS_LOADER_EXPORT___;
