import { apiGet } from '../../app';
import { setAllPatients } from '../state';

export default function loadPatients({ page = 0, limit = 10, filter, sortBy }) {
  let url = `/patients?start=${page * limit}&limit=${limit}`;

  if (filter) {
    url += `&filter=${filter}`;
  }

  if (sortBy) {
    url += `&sort_by=${sortBy}`;
  }

  return dispatch => {
    return dispatch(
      apiGet({
        url,
        options: {
          isAuth: true,
          handleActions: setAllPatients,
        },
      })
    );
  };
}
