import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { push } from 'connected-react-router';

import { useFrames } from '@pumpkincare/portal-app';
import { USER_AGENTS, useToggleState } from '@pumpkincare/portal-shared';
import {
  Body1,
  Button,
  FileInput,
  Processing,
  SubText1,
} from '@pumpkincare/portal-ui';
import { getVetSelfVetId, useVetSelf } from '@pumpkincare/portal-user';

import { CONTACT_EMAIL } from '../../../app';
import { resetVisit } from '../../state';
import PetPicTipsModal from '../pet-pic-tips-modal';
import PhotoSubmission from '../photo-submission';

import styles from './photo-tool.css';

import cameraIcon from '../../../../../../../public/assets/paw-portal/icons/camera-inverted.png';
import lightbulbIcon from '../../../../../../../public/assets/paw-portal/illustrations/misc/lightbulb.png';

const ACCEPTED_FORMATS = '.bmp, .png, .jpg, .tif, .tiff';

export default function PhotoTool({ onClose = () => {}, returnCopy }) {
  const dispatch = useDispatch();
  const { data: vetId } = useVetSelf({ select: getVetSelfVetId });
  const { isFetched: isFramesFetched, isError: isFramesError } = useFrames(vetId);

  const image = useRef({});
  const [imageSrc, setImageSrc] = useState('');
  const [isPetPicTipsModalOpen, setIsPetPicTipsModalOpen] = useToggleState(false);

  function handleImageChange(e) {
    const file = e.target.files[0];
    const isImage = file.type.indexOf('image') > -1;

    if (file && isImage) {
      dispatch(
        push({
          pathname: document.location.pathname,
          search: '?view=frame-selection',
        })
      );
      image.current = file;
      setImageSrc(URL.createObjectURL(file));
    }
  }

  function handleClosePopover() {
    dispatch(push({ pathname: document.location.pathname }));
    image.current = {};
    setImageSrc('');
    dispatch(resetVisit());
    onClose();
  }

  return (
    <>
      <div className={styles.photoContainer}>
        <div className={styles.flex}>
          {!isFramesFetched ? (
            <Processing />
          ) : (
            <>
              <FileInput
                disabled={isFramesError}
                accept={ACCEPTED_FORMATS}
                handleImageChange={handleImageChange}
              >
                <div
                  className={classNames(styles.iconContainer, {
                    [styles.disabledInput]: isFramesError,
                  })}
                >
                  <img src={cameraIcon} alt='camera' />
                </div>
                <Body1>
                  <span
                    className={classNames({
                      [styles.hidden]: !USER_AGENTS.desktop,
                    })}
                  >
                    Drag and drop files here, or{' '}
                    <span className={styles.photoBody}>browse to upload</span>
                  </span>
                  <span
                    className={classNames(styles.photoBody, {
                      [styles.hidden]: USER_AGENTS.desktop,
                    })}
                  >
                    Choose a Photo
                  </span>
                </Body1>
                <SubText1>
                  Accepted formats: {ACCEPTED_FORMATS}
                  <br />
                  Max file size of 10MB
                </SubText1>
                {isFramesError ? (
                  <SubText1 className={styles.framesError}>
                    There was an error. Please try refreshing the page. If the issue
                    continues, please reach out to {CONTACT_EMAIL} for help.
                  </SubText1>
                ) : null}
              </FileInput>
              <Button
                className={styles.petPicTipsButton}
                onClick={setIsPetPicTipsModalOpen}
              >
                <img
                  src={lightbulbIcon}
                  className={styles.lightbulbIcon}
                  alt='lightbulb'
                  role='presentation'
                />
                Pet Pic Tips
              </Button>
            </>
          )}
        </div>
      </div>

      {isPetPicTipsModalOpen ? (
        <PetPicTipsModal onClose={setIsPetPicTipsModalOpen} />
      ) : null}

      {imageSrc ? (
        <PhotoSubmission
          onClosePopover={handleClosePopover}
          onImageChange={handleImageChange}
          imageSrc={imageSrc}
          image={image.current}
          returnCopy={returnCopy}
        />
      ) : null}
    </>
  );
}
