import { asyncInitialState, asyncReduxCreator } from '@pumpkincare/portal-shared';

const [
  SET_VISIT_PATIENT,
  SET_VISIT_PATIENT_ERROR,
  SET_VISIT_PATIENT_SUCCESS,
  setVisitPatient,
] = asyncReduxCreator('SET_VISIT_PATIENT');
const [
  SET_VISIT_PHOTO,
  SET_VISIT_PHOTO_ERROR,
  SET_VISIT_PHOTO_SUCCESS,
  setVisitPhoto,
] = asyncReduxCreator('SET_VISIT_PHOTO');
const RESET_VISIT = 'visit/RESET';

const visitInitialState = {
  patient: asyncInitialState(null),
  photo: asyncInitialState(null),
};

export default function visitReducer(
  state = visitInitialState,
  { payload, type } = {}
) {
  switch (type) {
    case SET_VISIT_PATIENT:
    case SET_VISIT_PATIENT_ERROR:
    case SET_VISIT_PATIENT_SUCCESS:
      return { ...state, patient: { ...state.patient, ...payload } };

    case SET_VISIT_PHOTO:
    case SET_VISIT_PHOTO_ERROR:
    case SET_VISIT_PHOTO_SUCCESS:
      return { ...state, photo: { ...state.photo, ...payload } };

    case RESET_VISIT:
      return visitInitialState;

    default:
      return state;
  }
}

export { setVisitPatient, setVisitPhoto };

export function resetVisit() {
  return { type: RESET_VISIT };
}
