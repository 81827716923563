import { apiPost } from '../state/api-ducks';

export default function uploadToS3(file, data, options = {}) {
  return dispatch => {
    const formData = new FormData();
    const { fields, url } = data.signed_url;

    Object.keys(fields).forEach(key => {
      formData.append(key, fields[key]);
    });

    file.key = fields.key;
    // Actual file has to be appended last.
    formData.append('file', file);

    return dispatch(
      apiPost({
        url,
        options: {
          formData,
          isExternal: true,
          ...options,
        },
      })
    );
  };
}
