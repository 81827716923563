import { Body1, BodySubhead } from '@pumpkincare/portal-ui';

import bag from '../../../../../../../public/assets/paw-portal/illustrations/misc/medical-bag-red.png';

// for PEP1 / 2 medicines, transform item
function transformItem(item, isDog) {
  const isMedicines = item.quantity === 12 && item.name === 'Medicines';
  if (isMedicines && isDog) {
    return '12 months of Simparica Trio™ (sarolaner, moxidectin, and pyrantel chewable tablets)';
  }
  if (isMedicines) {
    return '12 months of Revolution® Plus (selamectin and sarolaner topical solution)';
  }

  return `${item.quantity} ${item.name}`;
}

export default function PatientPlan({ activePlan, isDog, classes = {} }) {
  return (
    <div className={classes.module}>
      <BodySubhead className={classes.moduleHeader}>
        <img
          src={bag}
          alt='medical bag'
          role='presentation'
          className={classes.moduleIcon}
        />
        Preventive Essentials Pack
      </BodySubhead>

      <Body1 className={classes.moduleLineItem}>
        Effective Date
        <b>{activePlan.formattedEffectiveDate}</b>
      </Body1>
      {activePlan.entitlements.map(entitlement =>
        entitlement.items.map(item => (
          <Body1 key={item.name} className={classes.moduleLineItem}>
            {transformItem(item, isDog)}
          </Body1>
        ))
      )}
    </div>
  );
}
