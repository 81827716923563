// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".unsubscribe-page_container_1cde858e {\n    height: 100vh;\n    max-width: 686px;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    text-align: center;\n    margin: 0 auto;\n}\n\n.unsubscribe-page_logo_27e05a36 {\n    margin-bottom: 60px;\n}\n\n.unsubscribe-page_icon_9931b91a {\n    width: 134px;\n    height: 134px;\n}\n\n.unsubscribe-page_header_44b7388b {\n    margin: 40px auto;\n    font-size: 36px;\n    line-height: 40px;\n    font-weight: bold;\n}\n\n.unsubscribe-page_subheader_428ae080, .unsubscribe-page_message_d2bd19f7 {\n    font-size: 24px;\n    line-height: 28px;\n}\n\n.unsubscribe-page_subheader_428ae080 {\n    font-weight: 800;\n    margin-bottom: 10px;\n}\n\n.unsubscribe-page_message_d2bd19f7 {\n    font-weight: 400;\n}", "",{"version":3,"sources":["webpack://src/domains/app/view/unsubscribe-page/unsubscribe-page.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;IAClB,cAAc;AAClB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,iBAAiB;IACjB,eAAe;IACf,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".container {\n    height: 100vh;\n    max-width: 686px;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    text-align: center;\n    margin: 0 auto;\n}\n\n.logo {\n    margin-bottom: 60px;\n}\n\n.icon {\n    width: 134px;\n    height: 134px;\n}\n\n.header {\n    margin: 40px auto;\n    font-size: 36px;\n    line-height: 40px;\n    font-weight: bold;\n}\n\n.subheader, .message {\n    font-size: 24px;\n    line-height: 28px;\n}\n\n.subheader {\n    font-weight: 800;\n    margin-bottom: 10px;\n}\n\n.message {\n    font-weight: 400;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "unsubscribe-page_container_1cde858e",
	"logo": "unsubscribe-page_logo_27e05a36",
	"icon": "unsubscribe-page_icon_9931b91a",
	"header": "unsubscribe-page_header_44b7388b",
	"subheader": "unsubscribe-page_subheader_428ae080",
	"message": "unsubscribe-page_message_d2bd19f7"
};
export default ___CSS_LOADER_EXPORT___;
