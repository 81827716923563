import classNames from 'classnames';

import { A, Body1 } from '@pumpkincare/portal-ui';

import styles from './patient-card.css';

const emojis = {
  dog: '🐶',
  cat: '🐱',
  default: '🐾',
};

export default function PatientCard({ patient, filterStatuses = {} }) {
  const { first_name: firstName, last_name: lastName, email, id, pets } = patient;

  function Wrapper({ children }) {
    return id ? (
      <A className={styles.container} href={`/patients/${id}`}>
        {children}
      </A>
    ) : (
      <div className={styles.container}>{children}</div>
    );
  }

  return (
    <Wrapper>
      <h4>
        {firstName} {lastName}
      </h4>
      <Body1>Email: {email}</Body1>

      {pets.map(pet => (
        <Body1
          key={`${patient.email}-${pet.name}`}
          className={classNames(styles.petItem, {
            [styles.gray]:
              (filterStatuses.activeOnly && !pet.has_insurance) ||
              (filterStatuses.inactiveOnly && pet.has_insurance),
          })}
        >
          <span style={{ marginRight: '8px' }}>
            {emojis[pet.species] || emojis.default}
          </span>
          <b className={styles.petName}>{pet.name}</b>
          <span className={styles.petProducts}>
            {pet.has_insurance
              ? `Insurance ${pet.has_preventative ? '+ Prevent' : 'Only'}`
              : pet.id
              ? 'Inactive Policy'
              : 'No Pumpkin Policy'}
          </span>
        </Body1>
      ))}
    </Wrapper>
  );
}
