import classNames from 'classnames';

import { Button } from '@pumpkincare/portal-ui';

import PET_IMAGES from '../../constants/pet-images';

import styles from './pet-button.css';

export default function PetButton({ pet, isSelected, onClick, className }) {
  return (
    <Button
      className={classNames(styles.petButton, className, {
        [styles.selected]: isSelected,
      })}
      onClick={() => onClick(pet.id || pet.name)}
    >
      <img
        alt='pet cartoon'
        role='presentation'
        className={styles.petImage}
        src={PET_IMAGES[pet.species]?.[pet.gender] || PET_IMAGES.default}
      />
      <h5
        className={classNames(styles.petName, {
          [styles.selected]: isSelected,
        })}
      >
        {pet.name}
      </h5>
    </Button>
  );
}
