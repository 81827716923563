import { useState } from 'react';
import { useLocation, useParams } from 'react-router';
import classNames from 'classnames';

import {
  formatISODate,
  formatPhoneNumber,
  useMutatePostDocument,
  useMutatePostSignedUrl,
  useMutateUploadToS3,
  useToggleState,
} from '@pumpkincare/portal-shared';
import {
  Body1,
  ButtonPrimary,
  FileUpload,
  Processing,
} from '@pumpkincare/portal-ui';

import {
  CONTACT_HOURS,
  CONTACT_PHONE_ARFMEOW,
  CONTACT_PHONE_LINK,
  RECORDS_EMAIL,
  RECORDS_EMAIL_LINK,
} from '../../../app';
import { useMedicalRecordsRequest } from '../../utils/medical-records-request-query';
import { useMutatePatchMedicalRecordRequest } from '../../utils/medical-records-requests-mutations';
import ReasonsModal from '../reasons-modal';
import RecordsRequestComplete from '../records-request-complete';
import RecordsRequestFooter from '../records-request-footer';

import styles from './records-request.css';

import dogFace from '../../../../../../../public/assets/paw-portal/icons/dog-face.svg';
import vetRecords from '../../../../../../../public/assets/paw-portal/icons/hospital-supplies/vet-records.png';
import mail from '../../../../../../../public/assets/paw-portal/icons/lifestyle/social/mail.png';
import email from './email.png';
import phone from './phone.png';

function RecordsRequest() {
  const { recordRequestId } = useParams();
  const { search } = useLocation();
  const urlOpenModal = new URLSearchParams(search).get('open_reason_modal');

  const [isReasonsModalOpen, toggleReasonsModal] = useToggleState(
    urlOpenModal === 'true'
  );
  const [isComplete, setIsComplete] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [isSubmittingDocuments, toggleSubmittingDocuments] = useToggleState(false);

  const { data: recordData, isFetching: isRecordFetching } =
    useMedicalRecordsRequest(
      { recordRequestId },
      {
        onSuccess: data => {
          if (data.is_expired || data.status.match(/complete/i)) {
            setIsComplete(true);

            if (isReasonsModalOpen) toggleReasonsModal();
          }
        },
      }
    );

  const { mutateAsync: postSignedUrl } = useMutatePostSignedUrl();
  const { mutateAsync: uploadToS3 } = useMutateUploadToS3();
  const { mutateAsync: postDocument } = useMutatePostDocument();

  const {
    isLoading: isRequestUpdating,
    isError: isRequestUpdateError,
    mutateAsync: updateMedicalRecordRequest,
  } = useMutatePatchMedicalRecordRequest(recordRequestId);

  const isReasonSubmitting = isRequestUpdating || isRecordFetching;

  const { allMedicalRecords, medicalRecords, invoice } = recordData?.records || {};

  function handleSubmitReason(reason, notes) {
    updateMedicalRecordRequest({
      status: 'COMPLETE',
      closed_with: reason,
      reason_notes: notes,
    }).catch(() => {});
  }

  function handleUpload(file, rawFile) {
    // returned bc FileUpload.onUpload expects a promise
    return postSignedUrl({
      body: {
        file_name: file.name,
        type: 'medical_record',
        user_id: recordData.user.id,
      },
    }).then(response => {
      const signedUrlParams = response[file.name];
      file.key = signedUrlParams.fields.key;
      return uploadToS3({
        file: rawFile,
        data: { signed_url: signedUrlParams },
      });
    });
  }

  function handleSubmitDocuments() {
    toggleSubmittingDocuments();

    Promise.all(
      fileList.map(file => {
        const bucket = new URL(file.signedUrls.url).hostname.split('.')[0];
        return postDocument({
          body: {
            source_file_name: file.name,
            type: 'medical_record',
            user_id: recordData.user.id,
            pet_id: recordData.user.pet.id,
            claim_id: recordData.claim_submission_id,
            location: `s3://${bucket}/${file.key}`,
            file_origin: 'vet',
            vet_id: recordData.vet_id,
          },
        });
      })
    )
      .then(() =>
        updateMedicalRecordRequest({
          status: 'COMPLETE',
          closed_with: 'DOCUMENTS',
        })
      )
      .catch(() => {})
      .finally(() => toggleSubmittingDocuments());
  }

  if (isRecordFetching)
    return (
      <>
        <Processing
          classes={{ container: classNames(styles.loading, styles.minHeight) }}
        />
        <RecordsRequestFooter classes={{ root: styles.peteyBlue }} />
      </>
    );

  return (
    <>
      {isComplete ? (
        <RecordsRequestComplete
          isExpired={recordData.is_expired}
          closedWith={recordData.closed_with}
          firstName={recordData.user.first_name}
          handleReturnToRequest={() => setIsComplete(false)}
          classes={{ root: styles.minHeight }}
        />
      ) : (
        <>
          <div className={classNames(styles.content, styles.minHeight)}>
            <h1>Upload {recordData.user.pet.name}'s medical records</h1>

            <div className={styles.blocks}>
              <div className={styles.uploadBlock}>
                <FileUpload
                  files={fileList}
                  onFileListChange={setFileList}
                  onUpload={handleUpload}
                  classes={{ root: styles.uploader }}
                  maxFileSize={{ raw: 100000000, formatted: '100MB' }}
                  acceptedFilesText='.pdf, .bmp, .png, .jpg, .jpeg, .tif, .tiff'
                />

                <ButtonPrimary
                  disabled={!fileList.length}
                  isLoading={isSubmittingDocuments}
                  onClick={handleSubmitDocuments}
                >
                  Upload Records
                </ButtonPrimary>

                <p>
                  Don't have this patient's records?{' '}
                  <span role='button' onClick={toggleReasonsModal}>
                    Let us know
                  </span>
                </p>
              </div>

              <div className={styles.blocksDetails}>
                <div className={styles.petBlock}>
                  <div className={styles.item}>
                    <div className={styles.imgSpacing}>
                      <img src={dogFace} alt='' />
                    </div>

                    <div className={styles.blockText}>
                      <h4>{recordData.user.pet.name}</h4>
                      <p>
                        Owner: {recordData.user.first_name}{' '}
                        {recordData.user.last_name}
                      </p>
                      <p>{formatPhoneNumber(recordData.user.phone)}</p>
                      {recordData.additional_owner ? (
                        <p>Additional Owner: {recordData.additional_owner}</p>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className={styles.docBlock}>
                  <div className={styles.item}>
                    <div className={styles.imgSpacing}>
                      <img src={vetRecords} alt='' />
                    </div>

                    <div className={styles.blockText}>
                      <Body1>
                        <b>Documents Requested</b>
                      </Body1>
                      {allMedicalRecords ? (
                        <>
                          <p>
                            All records from{' '}
                            {allMedicalRecords.start_date
                              ? formatISODate(allMedicalRecords.start_date)
                              : 'first visit'}{' '}
                            to present day, including:
                          </p>
                          <ul>
                            <li>Medical (SOAP/Doctor's) notes</li>
                            <li>Diagnostic Test Results</li>
                          </ul>
                        </>
                      ) : null}

                      {medicalRecords.length ? (
                        <>
                          <p>The following specific records:</p>
                          <ul>
                            {medicalRecords.map(record => (
                              <li key={record.description}>
                                {formatISODate(record.start_date)}:{' '}
                                {record.description}
                              </li>
                            ))}
                          </ul>
                        </>
                      ) : null}

                      {invoice ? (
                        <p>
                          A paid invoice from {formatISODate(invoice.start_date)}
                        </p>
                      ) : null}
                    </div>
                  </div>

                  {recordData.additional_notes ? (
                    <div className={styles.item}>
                      <div className={styles.imgSpacing}>
                        <img src={mail} alt='' />
                      </div>

                      <div className={styles.blockText}>
                        <Body1>
                          <b>Note from Pumpkin Claims Team</b>
                        </Body1>
                        <p>{recordData.additional_notes}</p>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            {isReasonsModalOpen ? (
              <ReasonsModal
                petName={recordData.user.pet.name}
                toggleClosed={toggleReasonsModal}
                onSubmit={handleSubmitReason}
                isError={isRequestUpdateError}
                isLoading={isReasonSubmitting}
              />
            ) : null}
          </div>

          <div className={styles.contact}>
            <h3>Having trouble uploading records?</h3>
            <h4>The Pumpkin Care Team is here for you!</h4>

            <div className={styles.contactItems}>
              <div className={styles.contactItem}>
                <img src={phone} alt='' />
                <div className={styles.contactDetails}>
                  <a href={CONTACT_PHONE_LINK}>Call {CONTACT_PHONE_ARFMEOW}</a>
                  <p>{CONTACT_HOURS}</p>
                </div>
              </div>

              <div className={styles.contactItem}>
                <img src={email} alt='' />
                <div className={styles.contactDetails}>
                  <a href={RECORDS_EMAIL_LINK}>Email {RECORDS_EMAIL}</a>
                  <p>We'll respond in a day or less</p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <RecordsRequestFooter
        classes={{
          root: isComplete ? styles.peteyBlue : styles.white,
        }}
      />
    </>
  );
}

export default RecordsRequest;
