import { useEffect, useRef, useState } from 'react';
import { useDebounce } from 'use-debounce';

import { analyticsTrack } from '@pumpkincare/analytics';
import { useTargetState, useZipcodes } from '@pumpkincare/portal-shared';
import { ButtonPrimary, Modal, Select, TextField } from '@pumpkincare/portal-ui';
import { useVetsSearch } from '@pumpkincare/portal-vets';

import styles from './vet-overlay.css';

import stethoscope from '../../../../../../../../public/assets/paw-portal/icons/stethoscope.svg';

export function formatSearchResults(searchData, isFetched) {
  if (!isFetched) return [];

  if (!searchData.length)
    return [
      {
        title: "I don't see my vet clinic on this list",
        label: "I don't see my vet clinic on this list",
        subtitle: 'No matching vet clinics found',
        value: 'none',
      },
    ];

  return searchData.map(vet => ({
    value: vet.id,
    label: vet.vet_name,
    ...vet,
  }));
}

/*
   react-select will automatically filter responses alphabetically, so if our
   BE returns options like "Gastrointestinal Clinic" for a search term 'test',
   react-select will filter it out. This filterOption function will prevent the
   native filtering in react-select. A separate async react-select component is
   available but does not play well with react-query
 */
function filterOption({ data }) {
  return data;
}

function VetOverlay({ onSkip, onSuccess }) {
  const vet = useRef({});
  const [zipcode, setZipcode] = useTargetState('');
  const [vetSearch, setVetSearch] = useState('');

  const [debouncedZipcode] = useDebounce(zipcode, 500);
  const [debouncedVetSearch] = useDebounce(vetSearch, 500);

  useEffect(() => {
    analyticsTrack({
      category: 'Pawsights',
      event: 'Step 0.5 impression',
    });
  }, []);

  // this endpoint returns a 404 if invalid input, so just forcing the data return to be consistent
  const { data: zipData = {} } = useZipcodes(debouncedZipcode);
  const {
    data: searchData,
    isFetched: isSearchVetsFetched,
    isFetching: isSearchVetsFetching,
  } = useVetsSearch(
    {
      search: debouncedVetSearch,
      zipcode: zipData.zip ? debouncedZipcode : '',
    },
    { keepPreviousData: true }
  );

  function handleSubmit(e) {
    e.preventDefault();

    if (vet.current.id) {
      onSuccess(vet.current);
    } else {
      onSkip();
    }
  }

  function handleVetClick(searchVet) {
    vet.current = searchVet;
    setVetSearch(searchVet.vet_name || searchVet.title);
  }

  return (
    <Modal
      hasCloseIcon={false}
      onClose={() => {}}
      classes={{
        container: styles.root,
        backdrop: styles.backdrop,
        content: styles.content,
      }}
    >
      <form onSubmit={handleSubmit}>
        <h3>
          Hi there! Start your pet's breed health report by sharing your primary vet
          info
        </h3>

        <p className={styles.firstQuestion}>Where is your vet located?</p>
        <TextField
          id='zipcode'
          placeholder='Enter ZIP code'
          isLabelHidden
          value={zipcode}
          onChange={setZipcode}
          variant='portal'
        />

        <p id='question-vet' className={styles.secondQuestion}>
          Find your vet hospital name
        </p>

        <Select
          options={formatSearchResults(searchData, isSearchVetsFetched)}
          error={{ isErrorHidden: true }}
          isLoading={isSearchVetsFetching}
          aria-labelledby='question-vet'
          filterOption={filterOption}
          formatOptionLabel={(item, { selectValue }) => {
            return selectValue.some(selected => item.value === selected.value) ? (
              <p>{item.vet_name || item.title}</p>
            ) : (
              <>
                <p>{item.vet_name || item.title}</p>
                <p>{item.normalized_addr || item.subtitle}</p>
              </>
            );
          }}
          id='select-vets'
          isSearchable
          placeholder='Start typing to search your hospital'
          onInputChange={setVetSearch}
          noOptionsMessage={() => null}
          onChange={handleVetClick}
        />

        <ButtonPrimary className={styles.submit} type='submit'>
          Get Started
        </ButtonPrimary>
      </form>

      <div className={styles.icon}>
        <img src={stethoscope} alt='' />
      </div>
    </Modal>
  );
}

export default VetOverlay;
