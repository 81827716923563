import { confirmSignIn, signIn } from 'aws-amplify/auth';

import { configureAmplify } from '@pumpkincare/portal-config';

import { apiPost, setAppResetPassword } from '../state';
import checkUserToken from './check-user-token';
import fetchUser from './fetch-user';

export default function changePasswordLoggedOut(token, email, password) {
  return dispatch => {
    configureAmplify();

    return dispatch(checkUserToken(token, 'password_signup'))
      .then(() =>
        signIn({ username: email, options: { authFlowType: 'CUSTOM_WITHOUT_SRP' } })
      )
      .then(() => confirmSignIn({ challengeResponse: `token_vet::${token}` }))
      .then(() =>
        dispatch(
          apiPost({
            url: '/users/self/change-password',
            options: {
              body: { password },
              isAuth: true,
              onSuccess: setAppResetPassword,
              onError: setAppResetPassword,
            },
          })
        )
      )
      .then(() => dispatch(fetchUser()));
  };
}
