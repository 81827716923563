import { Route, Switch } from 'react-router-dom';

import {
  PATH_PATIENT_PET_CLAIMS,
  PATH_PATIENT_VIEW,
  PATH_PATIENTS_BASE,
} from '../../../app';
import PatientView from '../patient-view';
import PatientsLanding from '../patients-landing';
import PetClaims from '../pet-claims';

export default function PatientsWrapper() {
  return (
    <Switch>
      <Route path={PATH_PATIENT_PET_CLAIMS} render={() => <PetClaims />} />
      <Route path={PATH_PATIENT_VIEW} render={() => <PatientView />} />
      <Route path={PATH_PATIENTS_BASE} render={() => <PatientsLanding />} />
    </Switch>
  );
}
