import { useEffect } from 'react';
import { useParams } from 'react-router';
import classNames from 'classnames';

import { useVisitPatient, useVisitPhoto } from '@pumpkincare/portal-photo-visits';
import { useDelayedLoading, USER_AGENTS } from '@pumpkincare/portal-shared';
import { A, Body1, Processing } from '@pumpkincare/portal-ui';

import { AppMeta, PATH_EXT_UNDERWRITING } from '../../../app';
import Footer from '../../../app/view/footer';
import MarvelPhotocard from '../marvel-photocard';
import Widget from '../widget';

// eslint-disable-next-line css-modules/no-unused-class
import styles from './customer-visit.css';

import download from '../../../../../../../public/assets/paw-portal/icons/download.svg';
import poweredByPumpkin from '../../../../../../../public/assets/paw-portal/illustrations/misc/Powered-By-Pumpkin.png';
import pumpkin from '../../../../../../../public/assets/paw-portal/logos/pumpkin.svg';

const MARVEL_FRAMES = ['SUPER_BRAVE', 'EPIC_CUTENESS', 'TREATS_DISAPPEAR'];

export default function CustomerVisit() {
  const { vetId, visitId } = useParams();

  const { data: photoData, isFetching: isPhotoFetching } = useVisitPhoto({
    vetId,
    visitId,
  });
  const { data: patientData, isFetching: isPatientFetching } = useVisitPatient({
    vetId,
    visitId,
  });
  const { merged_signed_url: mergedImage, frame: photoFrame } = photoData;

  const isLoading = useDelayedLoading([isPatientFetching, isPhotoFetching], 2000);

  useEffect(() => {
    if (!isLoading) {
      window.prerenderReady = true;
    }
  }, [isLoading]);

  return (
    <>
      <AppMeta
        image={mergedImage?.split('?')[0]}
        url={`https://portal.pumpkin.care/customer-visit/${visitId}/vets/${vetId}`}
        titleData={{
          petName: patientData.pet_name,
          titleTemplate: photoFrame?.title,
        }}
        description={`Check out ${patientData.pet_name || 'your pet'}'s photo from ${
          patientData.vet_display_name ? `${patientData.vet_display_name} & ` : ''
        }Pumpkin®`}
      />
      {isLoading ? (
        <div className={styles.loaderScreen}>
          <Processing classes={{ container: styles.loading, ring: styles.ring }} />
          <h5>
            Welcome to Pumpkin.care
            <br />
            We're fetching your photo card...
          </h5>
        </div>
      ) : (
        <>
          <div
            className={
              MARVEL_FRAMES.some(frameType => frameType === photoFrame?.frame_type)
                ? styles.marvelNavHeader
                : styles.header
            }
          >
            <div className={styles.nav}>
              <div>
                <img src={pumpkin} alt='' />
                {patientData.vet_display_name ? (
                  <>
                    <span>❤️</span>
                    <p className={styles.clinic}>{patientData.vet_display_name}</p>
                  </>
                ) : null}
              </div>

              <a
                href={PATH_EXT_UNDERWRITING}
                rel='noopener noreferrer'
                target='_blank'
              >
                Underwriting
              </a>
            </div>
          </div>
          {MARVEL_FRAMES.some(frameType => frameType === photoFrame?.frame_type) ? (
            <MarvelPhotocard
              mergedImage={mergedImage}
              petName={patientData.pet_name}
            />
          ) : (
            <>
              <div className={styles.header}>
                <h1>Here's a treat from your recent visit!</h1>

                <div className={styles.content}>
                  <div className={styles.image}>
                    <img
                      src={mergedImage}
                      alt={"your pet's visit"}
                      className={styles.photo}
                    />

                    <A
                      href={mergedImage}
                      download
                      className={classNames(styles.desktopAction, {
                        [styles.hidden]: !USER_AGENTS.desktop,
                      })}
                    >
                      <img
                        src={download}
                        alt='download'
                        role='presentation'
                        className={styles.download}
                      />
                      <Body1 className={styles.desktopText}>Download</Body1>
                    </A>

                    <Body1
                      className={classNames(styles.mobileAction, {
                        [styles.hidden]: USER_AGENTS.desktop,
                      })}
                    >
                      Tap & hold the image above to download.
                    </Body1>
                  </div>

                  <div className={styles.widget}>
                    <svg className={styles.arrow} viewBox='0 0 5 10'>
                      <path d='M5 0 V10 L0.8 5.8 Q0 5 0.8 4.2z' />
                    </svg>
                    <div className={styles.ribbon}>A Message from Pumpkin</div>

                    <Widget defaultPetName={patientData.pet_name} marvel={false} />
                  </div>
                </div>
              </div>

              <div className={styles.container}>
                <img
                  src={poweredByPumpkin}
                  alt='Powered by Pumpkin'
                  className={styles.powered}
                />
              </div>
              <Footer isCustomer />
            </>
          )}
        </>
      )}
    </>
  );
}
