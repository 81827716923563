import { apiPost, setAppResetPassword } from '../state';

export default function forgotPassword(email) {
  return dispatch => {
    return dispatch(
      apiPost({
        url: '/users/forgot-password',
        options: {
          body: { email },
          handleActions: setAppResetPassword,
        },
      })
    );
  };
}
