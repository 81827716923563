import { useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  ERROR,
  IDLE,
  LOADING,
  SUCCESS,
  useTargetState,
  validateEmail,
} from '@pumpkincare/portal-shared';

import { apiPost } from '../../../app';

export default function useAddPatient(initialValue) {
  const dispatch = useDispatch();
  const [email, setEmail, isValidEmail] = useTargetState(
    initialValue,
    validateEmail
  );
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [status, setStatus] = useState(IDLE);

  function requestPatient(e) {
    e.preventDefault();

    if (!isValidEmail) {
      setHasSubmitted(true);
      return;
    }

    setStatus(LOADING);
    dispatch(
      apiPost({
        url: '/patients/permissions',
        options: {
          body: { email },
          isAuth: true,
        },
      })
    )
      .then(() => setStatus(SUCCESS))
      .catch(() => setStatus(ERROR));
  }

  return {
    email,
    setEmail,
    requestPatient,
    statuses: {
      isError: status === ERROR,
      isLoading: status === LOADING,
      isSuccess: status === SUCCESS,
    },
    fieldErrorMessage:
      hasSubmitted && !isValidEmail ? 'Please enter a valid email to continue' : '',
  };
}
