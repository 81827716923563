import { apiGet } from '../../app';
import { setClaimPatientSearch } from '../state';

export default function loadClaimPatientSearch(keyword) {
  return dispatch => {
    return dispatch(
      apiGet({
        url: `/patients/search?keyword=${keyword}`,
        options: {
          handleActions: setClaimPatientSearch,
          isAuth: true,
          transformData: data => ({
            ...data,
            patients: data.patients.filter(patient => !!patient.pumpkin_id),
          }),
        },
      })
    );
  };
}
