// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../../../../libs/shared/src/ui/breakpoints.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fraud-notice-modal_modalContent_04d8bc11 {\n    background-color: var(--white);\n}\n\n.fraud-notice-modal_subheadText_6ca42661 {\n    font-size: 24px;\n    padding-bottom: 24px;\n}\n\n.fraud-notice-modal_legalText_4a628acf {\n    padding-bottom: 24px;\n}\n\n@media " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["b-mobile"] + " {\n    .fraud-notice-modal_legalText_4a628acf {\n        padding-bottom: 24px;\n    }\n\n    .fraud-notice-modal_subheadText_6ca42661 {\n        width: 100%;\n        font-size: 24px;\n        padding-bottom: 24px;\n    }\n}\n\n@media " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["b-desktop"] + " {\n    .fraud-notice-modal_modalContainer_986c6675 {\n        height: 90vh;\n        width: 65vw;\n    }\n\n    .fraud-notice-modal_modalContent_04d8bc11 {\n        padding: 32px 64px;\n    }\n}", "",{"version":3,"sources":["webpack://src/domains/claims/view/fraud-notice-modal/fraud-notice-modal.css"],"names":[],"mappings":"AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,eAAe;IACf,oBAAoB;AACxB;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI;QACI,oBAAoB;IACxB;;IAEA;QACI,WAAW;QACX,eAAe;QACf,oBAAoB;IACxB;AACJ;;AAEA;IACI;QACI,YAAY;QACZ,WAAW;IACf;;IAEA;QACI,kBAAkB;IACtB;AACJ","sourcesContent":["@value b-desktop, b-mobile from '~@pumpkincare/shared/ui/breakpoints';\n\n.modalContent {\n    background-color: var(--white);\n}\n\n.subheadText {\n    font-size: 24px;\n    padding-bottom: 24px;\n}\n\n.legalText {\n    padding-bottom: 24px;\n}\n\n@media b-mobile {\n    .legalText {\n        padding-bottom: 24px;\n    }\n\n    .subheadText {\n        width: 100%;\n        font-size: 24px;\n        padding-bottom: 24px;\n    }\n}\n\n@media b-desktop {\n    .modalContainer {\n        height: 90vh;\n        width: 65vw;\n    }\n\n    .modalContent {\n        padding: 32px 64px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"b-desktop": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["b-desktop"] + "",
	"b-mobile": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["b-mobile"] + "",
	"modalContent": "fraud-notice-modal_modalContent_04d8bc11",
	"subheadText": "fraud-notice-modal_subheadText_6ca42661",
	"legalText": "fraud-notice-modal_legalText_4a628acf",
	"modalContainer": "fraud-notice-modal_modalContainer_986c6675"
};
export default ___CSS_LOADER_EXPORT___;
