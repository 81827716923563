import classNames from 'classnames';

import { USER_AGENTS } from '@pumpkincare/portal-shared';
import { A, Body1, Body2 } from '@pumpkincare/portal-ui';

import MarvelFooter from '../marvel-footer';
import Widget from '../widget';

// eslint-disable-next-line css-modules/no-unused-class
import baseStyles from '../customer-visit/customer-visit.css';
import styles from './marvel-photocard.css';

import download from '../../../../../../../public/assets/paw-portal/icons/download.svg';
import checkmark from './checkmark.svg';
import checkmarkOff from './checkmark-off.svg';
import heroes from './heroes.png';
import pumpkinShadow from './pumpkin-shadow.svg';

export default function MarvelPhotocard({ petName, mergedImage }) {
  return (
    <>
      <div className={baseStyles.marvelHeader}>
        <h1>You're our superhero, {petName}!</h1>

        <div className={baseStyles.content}>
          <div className={baseStyles.image}>
            <img
              src={mergedImage}
              alt={"your pet's visit"}
              className={baseStyles.photo}
            />

            <A
              href={mergedImage}
              download
              className={classNames(baseStyles.desktopAction, {
                [baseStyles.hidden]: !USER_AGENTS.desktop,
              })}
            >
              <img src={download} alt='' className={baseStyles.download} />
              <Body1 className={baseStyles.desktopText}>Download</Body1>
            </A>

            <Body1
              className={classNames(baseStyles.mobileAction, {
                [baseStyles.hidden]: USER_AGENTS.desktop,
              })}
            >
              Tap & hold the image above to download.
            </Body1>
          </div>

          <div className={baseStyles.widget}>
            <svg className={baseStyles.arrow} viewBox='0 0 5 10'>
              <path d='M5 0 V10 L0.8 5.8 Q0 5 0.8 4.2z' />
            </svg>
            <div className={baseStyles.ribbon}>A Message from Pumpkin</div>

            <Widget
              defaultPetName={petName}
              title={
                <span>
                  Get a free pet insurance quote and a chance to win 2 Fandango Movie
                  Tickets* to see <i>The Marvels</i>!
                </span>
              }
              subtitle=''
              redirectUtms='utm_source=vetmktPet&utm_medium=photocard&utm_campaign=marvel_vet&utm_content=marvel_photo'
            />
          </div>
        </div>
      </div>

      <div className={styles.marvelContainer}>
        <div className={styles.left} />
        <div className={styles.right}>
          <div className={styles.rightContent}>
            <img src={pumpkinShadow} alt='' className={styles.logo} />
            <h3>The galaxy’s best pet insurance</h3>
            <div className={styles.checkmarks}>
              <div className={styles.checkmark}>
                <img src={checkmark} alt='' />
                <Body2>Accidents</Body2>
              </div>
              <div className={styles.checkmark}>
                <img src={checkmark} alt='' />
                <Body2>Illnesses</Body2>
              </div>
              <div className={styles.checkmark}>
                <img src={checkmarkOff} alt='' />
                <Body2>Alien Invasions</Body2>
              </div>
            </div>
            <img src={heroes} alt='heroes' className={styles.heroes} />
            <Body1 className={styles.subtitle}>
              Your little superhero may think they’re invincible, but pets can get
              hurt or sick at any time! A Pumpkin insurance plan can help you afford
              the best care with <b>90% cash back on eligible vet bills</b>.
            </Body1>
          </div>
        </div>
      </div>
      <MarvelFooter />
    </>
  );
}
