import { push } from 'connected-react-router';

import { ERROR } from '@pumpkincare/portal-shared';

import { PATH_ACCOUNT_BASE } from '../routing';
import { setAppResetPassword } from '../state';
import changePasswordLoggedOut from './change-password-logged-out';

export default function registerUser(token, email, password) {
  return dispatch => {
    return dispatch(changePasswordLoggedOut(token, email, password))
      .then(() => dispatch(push(PATH_ACCOUNT_BASE)))
      .catch(error =>
        dispatch(setAppResetPassword({ status: ERROR, error }, ERROR))
      );
  };
}
