import { trackCustomerPEP } from '@pumpkincare/analytics';
import { A, Body1, ButtonSecondary } from '@pumpkincare/portal-ui';

import { PATH_EXT_CAT_INSURANCE, PATH_EXT_DOG_INSURANCE } from '../../../app';
import { PP_BASE_UTM_PARAMS } from '../../constants';

import styles from './insurance-plans.css';

import ambulance from '../../../../../../../public/assets/paw-portal/illustrations/multipet/ambulance.png';

export default function InsurancePlans({ pepVersion }) {
  return (
    <div>
      <div className={styles.container}>
        <h2 className={styles.h2}>Pumpkin Pet Insurance Plans</h2>

        <div className={styles.content}>
          <img src={ambulance} className={styles.ambulance} alt='' />

          <div className={styles.copy}>
            <h4>Best-in-class pet insurance coverage for accidents & illnesses.</h4>

            <Body1 className={styles.body}>
              With a Pumpkin Pet Insurance plan, all pets 8 weeks and up get:
            </Body1>

            <ul>
              <li>Up to 90% cash back on all covered vet bills</li>
              <li>
                Coverage for in-clinic + virtual vet exam fees for accidents &
                illnesses
              </li>
              <li>
                Coverage for key diagnostic tests, treatment, and Rx medication plus
                life-saving emergency care, surgery, and rehab therapies for
                accidents & illnesses
              </li>
            </ul>

            <div className={styles.buttons}>
              <A
                href={`${PATH_EXT_DOG_INSURANCE}?${PP_BASE_UTM_PARAMS}${pepVersion}`}
                onClick={() =>
                  trackCustomerPEP('Clicked Insurance for Dogs', pepVersion)
                }
                target='_blank'
              >
                <ButtonSecondary className={styles.button}>
                  Insurance for Dogs
                </ButtonSecondary>
              </A>
              <A
                href={`${PATH_EXT_CAT_INSURANCE}?${PP_BASE_UTM_PARAMS}${pepVersion}`}
                onClick={() =>
                  trackCustomerPEP('Clicked Insurance for Cats', pepVersion)
                }
                target='_blank'
              >
                <ButtonSecondary className={styles.button}>
                  Insurance for Cats
                </ButtonSecondary>
              </A>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
