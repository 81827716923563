import { apiGet } from '../../app';
import { setClaimPatient } from '../state';

export default function loadClaimPatient(patientId) {
  return dispatch => {
    return dispatch(
      apiGet({
        url: `/patients/${patientId}`,
        options: {
          handleActions: setClaimPatient,
          isAuth: true,
        },
      })
    );
  };
}
