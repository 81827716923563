import { useCallback, useState } from 'react';

import {
  ERROR,
  fetchWrapper,
  IDLE,
  LOADING,
  SUCCESS,
} from '@pumpkincare/portal-shared';

export default function useLoadPatientOptions() {
  const [status, setStatus] = useState(IDLE);
  const [filterOptions, setFilterOptions] = useState([]);
  const [sortByOptions, setSortByOptions] = useState([]);

  const loadPatientOptions = useCallback(() => {
    setStatus(LOADING);
    fetchWrapper('/patients/options', { isAuth: true })
      .then(data => {
        setStatus(SUCCESS);
        setFilterOptions(data.filter);
        setSortByOptions(data.sort_by);
      })
      .catch(() => setStatus(ERROR));
  }, []);

  return {
    loadPatientOptions,
    filterOptions,
    sortByOptions,
    defaultFilter: filterOptions?.[0] ? Object.keys(filterOptions[0])[0] : null,
    defaultSortBy: sortByOptions?.[0] ? Object.keys(sortByOptions[0])[0] : null,
    statuses: {
      isError: status === ERROR,
      isLoading: status === LOADING,
      isSuccess: status === SUCCESS,
      isIdle: status === IDLE,
    },
  };
}
