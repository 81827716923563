import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  ERROR,
  IDLE,
  SUCCESS,
  useTargetState,
  validateEmail,
} from '@pumpkincare/portal-shared';
import { A, Body1, ButtonPrimary, TextField } from '@pumpkincare/portal-ui';

import { CONTACT_EMAIL_LINK } from '../../constants';
import { getAppResetPasswordStatus } from '../../selectors';
import { setAppResetPassword } from '../../state';
import forgotPassword from '../../thunks/forgot-password';

import styles from './request-password-page.css';

import check from '../../../../../../../public/assets/paw-portal/icons/check-circle-green.svg';

export default function RequestPasswordPage() {
  const dispatch = useDispatch();
  const [email, setEmail, isEmailValid] = useTargetState('', validateEmail);
  const [isErrorVisible, setErrorsVisible] = useState(false);
  const status = useSelector(getAppResetPasswordStatus);

  function handleSubmit(e) {
    e.preventDefault();

    if (!isEmailValid) {
      setErrorsVisible(true);
      return;
    }

    dispatch(forgotPassword(email));
  }

  function handleReset() {
    dispatch(setAppResetPassword({ data: null, status: IDLE, error: null }));
  }

  return (
    <div className={styles.container}>
      {status === SUCCESS ? (
        <>
          <h4 className={styles.title}>
            <img
              src={check}
              alt='check'
              role='presentation'
              className={styles.success}
            />
            Password Reset Email Sent
          </h4>
          <Body1 className={styles.text}>
            If the email you entered is associated with a PawPortal account, you'll
            receive an email to reset your password shortly. If you don't receive an
            email within 15 minutes, please{' '}
            <span className={styles.reset} onClick={handleReset} role='button'>
              try again
            </span>
            .
          </Body1>
          <Body1 style={{ textAlign: 'center' }}>Still having trouble?</Body1>
          <A
            style={{ textAlign: 'center', display: 'block' }}
            href={CONTACT_EMAIL_LINK}
          >
            Contact the Paw Portal Support Team
          </A>
        </>
      ) : (
        <>
          <h4 className={styles.text}>Request Your Password</h4>
          <Body1 className={styles.text}>
            Please enter the email address associated with your account, so we can
            send instructions on how to reset your password.
          </Body1>
          <form onSubmit={handleSubmit} noValidate>
            <TextField
              id='email'
              value={email}
              onChange={setEmail}
              label='Email'
              error={{
                errorMessage:
                  !isEmailValid && isErrorVisible && 'Please enter a valid email',
              }}
            />
            <ButtonPrimary className={styles.button} type='submit'>
              Send Reset Email
            </ButtonPrimary>
            {status === ERROR ? (
              <Body1 className={styles.error}>
                Sorry, something went wrong! Please try again later or reach out to
                our care team.
              </Body1>
            ) : null}
          </form>
        </>
      )}
    </div>
  );
}
