import catFemale from '../../../../../../public/assets/paw-portal/illustrations/misc/cat-female.png';
import catMale from '../../../../../../public/assets/paw-portal/illustrations/misc/cat-male.png';
import defaultPet from '../../../../../../public/assets/paw-portal/illustrations/misc/default-pet.png';
import dogFemale from '../../../../../../public/assets/paw-portal/illustrations/misc/dog-female.png';
import dogMale from '../../../../../../public/assets/paw-portal/illustrations/misc/dog-male.png';

const PET_IMAGES = {
  cat: {
    F: catFemale,
    M: catMale,
  },
  dog: {
    F: dogFemale,
    M: dogMale,
  },
  default: defaultPet,
};

export default PET_IMAGES;
