// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".reset-password-form_text_964487a8 {\n    margin-bottom: 34px;\n    text-align: center;\n}\n\n.reset-password-form_button_5e1bd888 {\n    display: block;\n    width: 100%;\n    margin-top: 24px;\n}\n\n.reset-password-form_error_b4559c5e {\n    color: var(--redAlert);\n    text-align: center;\n    margin-top: 16px;\n    font-size: 12px;\n}", "",{"version":3,"sources":["webpack://src/domains/app/view/reset-password-form/reset-password-form.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;AACnB","sourcesContent":[".text {\n    margin-bottom: 34px;\n    text-align: center;\n}\n\n.button {\n    display: block;\n    width: 100%;\n    margin-top: 24px;\n}\n\n.error {\n    color: var(--redAlert);\n    text-align: center;\n    margin-top: 16px;\n    font-size: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": "reset-password-form_text_964487a8",
	"button": "reset-password-form_button_5e1bd888",
	"error": "reset-password-form_error_b4559c5e"
};
export default ___CSS_LOADER_EXPORT___;
