export const getVisitPatient = state => state.visit.patient.data;
export const getVisitPatientId = state => state.visit.patient.data?.id;
export const getVisitPatientFirstName = state =>
  state.visit.patient.data?.client_first_name;
export const getVisitPatientLastName = state =>
  state.visit.patient.data?.client_last_name;
export const getVisitPatientEmail = state => state.visit.patient.data?.client_email;
export const getVisitPatientPetName = state => state.visit.patient.data?.pet_name;
export const getVisitPatientError = state => state.visit.patient.error;
export const getVisitPatientStatus = state => state.visit.patient.status;

export const getVisitPhoto = state => state.visit.photo.data;
export const getVisitPhotoFrame = state => state.visit.photo.data?.frame;
export const getVisitPhotoMergedUrl = state =>
  state.visit.photo.data?.merged_signed_url;
export const getVisitPhotoError = state => state.visit.photo.error;
export const getVisitPhotoStatus = state => state.visit.photo.status;
