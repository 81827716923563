import { useState } from 'react';

import {
  checkPasswordRequirements,
  useTargetState,
} from '@pumpkincare/portal-shared';
import {
  Body1,
  ButtonPrimary,
  PasswordRequirements,
  TextField,
} from '@pumpkincare/portal-ui';

import styles from './reset-password-form.css';

export default function ResetPasswordForm({
  className,
  onSubmit,
  isLoading,
  hasCurrentPassword,
  hasError,
  errorMessage,
}) {
  const [currentPassword, setCurrentPassword, isCurrentPasswordValid] =
    useTargetState('', value => value.length >= 12);
  const [password, setPassword, passwordValidity] = useTargetState(
    '',
    checkPasswordRequirements
  );
  const [confirmPassword, setConfirmPassword, arePasswordsMatching] = useTargetState(
    '',
    value => value && value === password
  );
  const [areErrorsVisible, setAreErrorsVisible] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();

    if (
      !arePasswordsMatching ||
      (hasCurrentPassword && !isCurrentPasswordValid) ||
      passwordValidity.hasErrors
    ) {
      setAreErrorsVisible(true);
      return;
    }

    onSubmit({ password, currentPassword });
  }

  return (
    <div className={className}>
      <h4 className={styles.text}>Reset Your Password</h4>
      <form onSubmit={handleSubmit} noValidate>
        {hasCurrentPassword ? (
          <TextField
            id='user-current-password'
            value={currentPassword}
            label='Current Password'
            type='password'
            onChange={setCurrentPassword}
            error={{
              errorMessage:
                areErrorsVisible && !isCurrentPasswordValid
                  ? 'Please enter a valid current password'
                  : '',
            }}
          />
        ) : null}

        <TextField
          id='user-password'
          value={password}
          label={hasCurrentPassword ? 'New Password' : 'Password'}
          type='password'
          onChange={setPassword}
          error={{ hasError: areErrorsVisible && passwordValidity.hasErrors }}
        />

        <TextField
          id='user-confirm-password'
          value={confirmPassword}
          label='Confirm Password'
          type='password'
          onChange={setConfirmPassword}
          error={{
            errorMessage:
              areErrorsVisible && !arePasswordsMatching
                ? "Password doesn't match"
                : '',
          }}
        />

        <PasswordRequirements validation={passwordValidity} />

        <ButtonPrimary isLoading={isLoading} type='submit' className={styles.button}>
          Reset
        </ButtonPrimary>
        {hasError || errorMessage ? (
          <Body1 className={styles.error}>
            {errorMessage ||
              'Sorry, something went wrong! Please try again later or reach out to our care team.'}
          </Body1>
        ) : null}
      </form>
    </div>
  );
}
