// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../../../../libs/shared/src/ui/breakpoints.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pawsights-report_root_be042a7a section {\n    padding: 42px 0;\n    border-bottom: 1px solid var(--pusheenGray);\n    max-width: 1440px;\n    margin: 0 auto;\n}\n\n@media " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["tablet"] + " {\n    .pawsights-report_root_be042a7a section {\n        padding: 60px var(--w1) 80px;\n    }\n}", "",{"version":3,"sources":["webpack://src/domains/pawsights/view/pawsights-report/pawsights-report.css"],"names":[],"mappings":"AAEA;IACI,eAAe;IACf,2CAA2C;IAC3C,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI;QACI,4BAA4B;IAChC;AACJ","sourcesContent":["@value tablet from '~@pumpkincare/shared/ui/breakpoints';\n\n.root section {\n    padding: 42px 0;\n    border-bottom: 1px solid var(--pusheenGray);\n    max-width: 1440px;\n    margin: 0 auto;\n}\n\n@media tablet {\n    .root section {\n        padding: 60px var(--w1) 80px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tablet": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["tablet"] + "",
	"root": "pawsights-report_root_be042a7a"
};
export default ___CSS_LOADER_EXPORT___;
