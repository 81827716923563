import styles from './meme.css';

function Meme({ src }) {
  return (
    <div className={styles.root}>
      <img src={src} alt='' />
    </div>
  );
}

export default Meme;
