import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getRouterLocationPathname } from '../../selectors';
import ErrorBoundary from '../error-boundary';
import Footer from '../footer';
import NavBar from '../nav-bar';

function PageComponent({
  component: Component,
  hideNavBar,
  isBasicPawPortalNav,
  basicHeader,
  hideFooter,
  isCustomer,
  isPrivate = false,
}) {
  const route = useSelector(getRouterLocationPathname);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [route]);

  return (
    <>
      {hideNavBar || isCustomer ? null : (
        <NavBar
          isBasicPawPortalNav={isBasicPawPortalNav}
          basicHeader={basicHeader}
        />
      )}

      <ErrorBoundary isPrivate={isPrivate}>
        <Component />
      </ErrorBoundary>

      {!hideFooter ? <Footer isCustomer={isCustomer} /> : null}
    </>
  );
}

export default PageComponent;
