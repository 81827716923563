import { apiPost } from '../../app';

export default function createClaimFileSignedUrl(userId, rawFile, fileType) {
  return dispatch => {
    return dispatch(
      apiPost({
        url: `/claims/signed-url`,
        options: {
          isAuth: true,
          body: {
            files: [rawFile.name],
            file_type: fileType,
            user_id: userId,
          },
        },
      })
    );
  };
}
