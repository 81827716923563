import { apiGet } from '../../app';
import { setPatient } from '../state';

// identifier is id for pumpkin customers, email for non
export default function loadPatient(identifier) {
  return dispatch => {
    return dispatch(
      apiGet({
        url: `/patients/${identifier}`,
        options: {
          isAuth: true,
          handleActions: setPatient,
        },
      })
    );
  };
}
