import { apiPost } from '../../app';
import { getVisitPatientId } from '../selectors/visit-selectors';
import { setVisitPhoto } from '../state/visit-ducks';

export default function createVisitPhoto(vetId, frameId, documentId) {
  return (dispatch, getState) => {
    const visitId = getVisitPatientId(getState());

    return dispatch(
      apiPost({
        url: `/vets/${vetId}/visits/${visitId}/photos`,
        options: {
          body: {
            frame_id: frameId,
            document_id: documentId,
          },
          handleActions: setVisitPhoto,
        },
      })
    );
  };
}
