import { QF_URL } from '@pumpkincare/portal-config';
import { filterUTMParams } from '@pumpkincare/portal-shared';

export default function functionQuoteFlowRoute(quoteId) {
  const { search } = window.location;

  const utms =
    filterUTMParams(search) ||
    'utm_source=vetmktPet_Print&utm_medium=lp&utm_campaign=Pawsights_Q1_2023&utm_content=Pawsights_lp';

  return `${QF_URL}/quote?quoteId=${quoteId}&${utms}`;
}
