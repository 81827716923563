import { matchPath } from 'react-router';

import {
  PATH_ACCOUNT_BASE,
  PATH_ACCOUNT_PROFILE,
  PATH_CUSTOMER_VISIT,
  PATH_FILE_CLAIM_BASE,
  PATH_FILE_CLAIM_SUCCESS,
  PATH_LOGIN,
  PATH_PATIENT_PET_CLAIMS,
  PATH_PATIENT_VIEW,
  PATH_PATIENTS_BASE,
  PATH_PAWSIGHTS_FORM,
  PATH_PAWSIGHTS_LANDING,
  PATH_PAWSIGHTS_REPORT,
  PATH_PHOTO_CARD_GALLERY,
  PATH_PREVENTIVE_ESSENTIALS,
  PATH_REGISTER,
  PATH_REQUEST_RESET,
  PATH_RESET_PASSWORD,
  QUERY_VIEWS,
  ROUTE_PARAM_PATHS,
} from './paths';

const PAWPORTAL = 'PawPortal';

function customerVisitTitles({ petName, titleTemplate }) {
  return petName && titleTemplate
    ? titleTemplate.replace('PET_NAME', petName)
    : 'Your pet had a furbulous visit to the vet today!';
}

function patientTitles({ firstName, lastName }) {
  return `${
    firstName && lastName ? `${firstName} ${lastName}` : 'Client'
  } | ${PAWPORTAL}`;
}

function claimTitles({ firstName, lastName }) {
  return `${
    firstName && lastName ? `Claims - ${firstName} ${lastName}` : 'Client Claims'
  } | ${PAWPORTAL}`;
}

export const DOCUMENT_TITLES_FNS = {
  [PATH_CUSTOMER_VISIT]: customerVisitTitles,
  [PATH_PATIENT_VIEW]: patientTitles,
  [PATH_PATIENT_PET_CLAIMS]: claimTitles,
};

const [FRAME_SELECTION, PATIENT_FORM, PREVIEW_SUBMIT, SUBMIT_SUCCESS] = QUERY_VIEWS;

export const DOCUMENT_TITLES = {
  [PATH_ACCOUNT_BASE]: `Home | ${PAWPORTAL}`,
  [FRAME_SELECTION]: `Visit Photo Frame | ${PAWPORTAL}`,
  [PATIENT_FORM]: `Visit Photo Patient | ${PAWPORTAL}`,
  [PREVIEW_SUBMIT]: `Visit Photo Preview | ${PAWPORTAL}`,
  [SUBMIT_SUCCESS]: `Visit Photo Success | ${PAWPORTAL}`,
  [PATH_PHOTO_CARD_GALLERY]: `Photo Cards | ${PAWPORTAL}`,
  [PATH_PREVENTIVE_ESSENTIALS]: `Pumpkin Preventive Essentials | ${PAWPORTAL}`,
  [PATH_PATIENTS_BASE]: `My Patients | ${PAWPORTAL}`,
  [PATH_FILE_CLAIM_BASE]: `File a Claim | ${PAWPORTAL}`,
  [PATH_FILE_CLAIM_SUCCESS]: `Claim Submitted | ${PAWPORTAL}`,
  [PATH_REGISTER]: `Register | ${PAWPORTAL}`,
  [PATH_LOGIN]: `Login | ${PAWPORTAL}`,
  [PATH_REQUEST_RESET]: `Forgot Password | ${PAWPORTAL}`,
  [PATH_RESET_PASSWORD]: `Reset Password | ${PAWPORTAL}`,
  [PATH_ACCOUNT_PROFILE]: `My Account | ${PAWPORTAL}`,
  [PATH_PAWSIGHTS_LANDING]: `Pumpkin Pawsights® Report`,
  [PATH_PAWSIGHTS_FORM]: `Pumpkin Pawsights® Report`,
  [PATH_PAWSIGHTS_REPORT]: `Pumpkin Pawsights® Report`,
  default: `Pumpkin® ${PAWPORTAL}`,
};

export function getTitle(location, titleData = {}) {
  const { pathname, search } = location;
  const matchedPath = ROUTE_PARAM_PATHS.find(path => matchPath(pathname, path));
  const pathBySearch = QUERY_VIEWS.find(path => search.indexOf(path) > -1);

  return (
    DOCUMENT_TITLES_FNS[matchedPath]?.(titleData) ||
    DOCUMENT_TITLES_FNS[pathname]?.(titleData) ||
    DOCUMENT_TITLES[matchedPath] ||
    DOCUMENT_TITLES[pathBySearch] ||
    DOCUMENT_TITLES[pathname] ||
    DOCUMENT_TITLES.default
  );
}
