export const getClaimSubmit = state => state.claim.submit.data;
export const getClaimSubmitStatus = state => state.claim.submit.status;

export const getClaimPatientSearch = state => state.claim.search.data;
export const getClaimPatientSearchStatus = state => state.claim.search.status;

export const getClaimPatient = state => state.claim.patient.data;
export const getClaimPatientStatus = state => state.claim.patient.status;

export const getClaimPatientPets = state => state.claim.pets.data;
