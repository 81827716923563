import classNames from 'classnames';

import { Body1, BodySubhead, Button, SubText1 } from '@pumpkincare/portal-ui';

import styles from './patient-policy.css';

import bandaid from '../../../../../../../public/assets/paw-portal/illustrations/misc/bandaid.png';

export default function PatientPolicy({
  activePolicy,
  downloadPolicy,
  classes = {},
}) {
  return activePolicy.id ? (
    <div className={classes.module}>
      <BodySubhead className={classes.moduleHeader}>
        <img
          src={bandaid}
          alt='bandaid'
          role='presentation'
          className={classes.moduleIcon}
        />
        Pet Health Insurance
        {activePolicy.policy_s3_path ? (
          <Button onClick={downloadPolicy} className={styles.insuranceLink}>
            View Policy & Coverage
          </Button>
        ) : null}
      </BodySubhead>

      <Body1 className={classes.moduleLineItem}>
        Coverage Start Date
        <b>{activePolicy.formattedPolicyEffectiveDate}</b>
      </Body1>
      <Body1 className={classes.moduleLineItem}>
        Annual Deductible
        <b>{activePolicy.formattedDeductible}</b>
      </Body1>
      <Body1 className={classes.moduleLineItem}>
        Annual Coverage Limit
        <b>{activePolicy.formattedAnnualLimit}</b>
      </Body1>
      <Body1 className={classes.moduleLineItem}>
        Reimbursement Rate
        <b>{activePolicy.formattedReimbursementRate}%</b>
      </Body1>
    </div>
  ) : (
    <div className={classNames(styles.inactive, classes.module)}>
      <BodySubhead className={classes.moduleHeader}>
        <img
          src={bandaid}
          alt='bandaid'
          role='presentation'
          className={classes.moduleIcon}
        />
        Pet Health Insurance
      </BodySubhead>
      <SubText1 style={{ margin: '4px 0 0 42px' }}>
        No Active Pumpkin Policy
      </SubText1>
    </div>
  );
}
