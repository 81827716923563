import { A, Body1 } from '@pumpkincare/portal-ui';

import {
  HELP_EMAIL,
  HELP_EMAIL_LINK,
  PATH_EXT_CALIFORNIA_NOTICE,
  PATH_EXT_COOKIES,
  PATH_EXT_INSURANCE_POLICY,
  PATH_EXT_MAINE_NOTICE,
  PATH_EXT_PRIVACY,
  PATH_EXT_TERMS,
  PATH_MARVEL,
} from '../../../app';

import styles from './marvel-footer.css';

export default function MarvelFooter() {
  return (
    <>
      <div className={styles.content}>
        <Body1>
          Independent ad from Pumpkin Insurance Services Inc. Marvel Studios is not
          an insurer or insurance producer.
        </Body1>

        <Body1>
          *Movie Ticket Giveaway ends 11/24/23. No purchase necessary. Email{' '}
          <A href={HELP_EMAIL_LINK}>{HELP_EMAIL}</A> for alternative entry. For full
          terms & conditions, visit{' '}
          <A href={PATH_MARVEL} target='_blank'>
            pumpkin.care/TheMarvels
          </A>
          .
        </Body1>

        <A href={PATH_EXT_CALIFORNIA_NOTICE} target='_blank'>
          Notice to California Residents
        </A>

        <A href={PATH_EXT_MAINE_NOTICE} target='_blank'>
          Notice to Maine Residents
        </A>

        <Body1>
          Pumpkin Pet Insurance policies do not cover pre-existing conditions.
          Waiting periods, annual deductible, co-insurance, benefit limits and
          exclusions may apply. For full terms, visit{' '}
          <A href={PATH_EXT_INSURANCE_POLICY} target='_blank'>
            pumpkin.care/insurancepolicy
          </A>
          . Products, discounts, and rates may vary and are subject to change.
          Insurance products are underwritten by either Independence American
          Insurance Company, a Delaware insurance company (NAIC #2658; Scottsdale,
          AZ), or United States Fire Insurance Company (NAIC #21113. Morristown, NJ);
          and are administered & produced by Pumpkin Insurance Services Inc.
          (“Pumpkin”) (NPN #19084749; New York, NY; CA License #6001617). Please
          refer to your policy forms to determine your policy’s underwriter. Pumpkin
          is a licensed insurance agency, not an insurer, and receives compensation
          based on the premiums for the insurance policies it sells.
        </Body1>

        <Body1>
          © {new Date().getFullYear()} Pumpkin Insurance Services Inc. All rights
          reserved.
        </Body1>
      </div>

      <div className={styles.links}>
        <A href={PATH_EXT_TERMS} target='_blank' className={styles.link}>
          Terms of Use
        </A>
        <span className={styles.separator}>|</span>
        <A href={PATH_EXT_PRIVACY} target='_blank' className={styles.link}>
          Privacy Policy
        </A>
        <span className={styles.separator}>|</span>
        <A href={PATH_EXT_COOKIES} target='_blank' className={styles.link}>
          Cookies Policy
        </A>
      </div>
    </>
  );
}
