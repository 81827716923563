// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../../../../libs/shared/src/ui/breakpoints.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pawsights-footer_root_48349371 {\n    background: var(--scoutNavy);\n    width: 100%;\n}\n\n.pawsights-footer_content_bd285375 {\n    max-width: 1440px;\n    margin: 0 auto;\n    padding: 44px var(--w1) 40px;\n    color: var(--white);\n}\n\n.pawsights-footer_logo_c1ee5f0b {\n    width: 128px;\n    margin-bottom: 22px;\n}\n\n.pawsights-footer_root_48349371 a, .pawsights-footer_root_48349371 p {\n    color: var(--white);\n    font-size: 12px;\n    line-height: 16px;\n}\n\n.pawsights-footer_root_48349371 p {\n    margin-top: 16px;\n}\n\n@media " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["tablet"] + " {\n    .pawsights-footer_content_bd285375 {\n        padding: 70px var(--w2) 55px;\n    }\n\n    .pawsights-footer_root_48349371 a, .pawsights-footer_root_48349371 p {\n        font-size: 14px;\n        line-height: 22px;\n    }\n}", "",{"version":3,"sources":["webpack://src/domains/pawsights/view/pawsights-footer/pawsights-footer.css"],"names":[],"mappings":"AAEA;IACI,4BAA4B;IAC5B,WAAW;AACf;;AAEA;IACI,iBAAiB;IACjB,cAAc;IACd,4BAA4B;IAC5B,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;IACnB,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI;QACI,4BAA4B;IAChC;;IAEA;QACI,eAAe;QACf,iBAAiB;IACrB;AACJ","sourcesContent":["@value tablet from '~@pumpkincare/shared/ui/breakpoints';\n\n.root {\n    background: var(--scoutNavy);\n    width: 100%;\n}\n\n.content {\n    max-width: 1440px;\n    margin: 0 auto;\n    padding: 44px var(--w1) 40px;\n    color: var(--white);\n}\n\n.logo {\n    width: 128px;\n    margin-bottom: 22px;\n}\n\n.root a, .root p {\n    color: var(--white);\n    font-size: 12px;\n    line-height: 16px;\n}\n\n.root p {\n    margin-top: 16px;\n}\n\n@media tablet {\n    .content {\n        padding: 70px var(--w2) 55px;\n    }\n\n    .root a, .root p {\n        font-size: 14px;\n        line-height: 22px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tablet": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["tablet"] + "",
	"root": "pawsights-footer_root_48349371",
	"content": "pawsights-footer_content_bd285375",
	"logo": "pawsights-footer_logo_c1ee5f0b"
};
export default ___CSS_LOADER_EXPORT___;
