import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { push } from 'connected-react-router';

import { formatSearchFromQuery, LOADING } from '@pumpkincare/portal-shared';
import {
  Body1,
  ButtonPrimary,
  Pagination,
  Processing,
} from '@pumpkincare/portal-ui';

import { AppMeta, getRouterLocation } from '../../../app';
import PET_IMAGES from '../../constants/pet-images';
import {
  getAllClaimsList,
  getAllClaimsStatus,
  getAllClaimsTotal,
  getPatient,
  getPatientsPets,
} from '../../selectors';
import getPatientActivePet from '../../selectors/reselect/get-patient-active-pet';
import loadPatientPetClaims from '../../thunks/load-patient-pet-claims';
import { formatClaimRedirect } from '../../utils';
import NoClaims from '../no-claims';
import PatientClaimCard from '../patient-claim-card';
import PatientNav from '../patient-nav';

import styles from './pet-claims.css';

export default function PetClaims() {
  const dispatch = useDispatch();
  const allClaims = useSelector(getAllClaimsList);
  const allClaimsStatus = useSelector(getAllClaimsStatus);
  const totalClaims = useSelector(getAllClaimsTotal);
  const { pathname, query } = useSelector(getRouterLocation);

  const isAllClaimsLoading = allClaimsStatus === LOADING;
  const isProcessing = !allClaims && isAllClaimsLoading;

  const user = useSelector(getPatient);
  const fullPets = useSelector(getPatientsPets) || {};

  const { pets } = user || { pets: [] };

  const { patientId, petId } = useParams();
  const activePet = useSelector(state =>
    getPatientActivePet(state, pets.filter(pet => pet.id === petId)[0])
  );

  function handlePageChange(page) {
    dispatch(
      push({
        pathname,
        search: formatSearchFromQuery({ ...query, page }),
      })
    );

    dispatch(loadPatientPetClaims(activePet.id, page - 1, 5));
  }

  function handlePetChange(newPet) {
    dispatch(push(`/patients/${user?.id}?pet=${newPet}`));
  }

  const hasActivePolicy = !!activePet.policy?.id;

  return (
    <>
      <AppMeta
        titleData={{ firstName: user?.first_name, lastName: user?.lastName }}
      />
      <div className={styles.container}>
        <PatientNav
          isMobilePatientHidden
          accordionTitle={
            <div className={styles.activePetInfo}>
              <img
                alt='pet cartoon'
                role='presentation'
                className={styles.activePetImage}
                src={
                  PET_IMAGES[activePet.species]?.[activePet.gender] ||
                  PET_IMAGES.default
                }
              />
              <div className={styles.activePetHeader}>
                <h4>{activePet.name}</h4>
              </div>
            </div>
          }
          fullPets={fullPets}
          onPetClick={handlePetChange}
          goBackLink={`/patients/${user?.id}?pet=${activePet.name}`}
          goBackText='Back to Pet Details'
          selectedPet={activePet.name}
          user={user}
        />

        <main className={styles.content}>
          <div className={styles.desktop}>
            <div className={styles.activePetInfo}>
              <img
                alt='pet cartoon'
                role='presentation'
                className={styles.activePetImage}
                src={
                  PET_IMAGES[activePet.species]?.[activePet.gender] ||
                  PET_IMAGES.default
                }
              />
              <div className={styles.activePetHeader}>
                <Body1 className={styles.headerName}>{activePet.name}</Body1>
                <Body1 className={styles.headerSlash}>/</Body1>
                <Body1 className={styles.headerLabel}>Claims</Body1>
              </div>
            </div>
          </div>

          <div className={styles.header}>
            <h3>Claim History</h3>
            {hasActivePolicy ? (
              <Link
                to={formatClaimRedirect(patientId, petId)}
                style={{ fontWeight: 'initial' }}
              >
                <ButtonPrimary>New Claim</ButtonPrimary>
              </Link>
            ) : null}
          </div>

          <div className={styles.claimsResultContainer}>
            {allClaims && totalClaims > 0
              ? allClaims.map(claim => (
                  <PatientClaimCard
                    key={`${claim.claim_number}-${claim.claimType}`}
                    claim={claim}
                  />
                ))
              : null}

            {allClaims && totalClaims > 0 ? (
              <Pagination
                resultsPerPage={5}
                totalResults={totalClaims}
                unit='Claim'
                page={Number(query.page) || 1}
                onPageChange={handlePageChange}
              />
            ) : null}

            {allClaims && totalClaims === 0 ? (
              <NoClaims
                hasActivePolicy={hasActivePolicy}
                patientId={patientId}
                petId={petId}
              />
            ) : null}

            {isProcessing ? <Processing /> : null}
          </div>
        </main>
      </div>
    </>
  );
}
