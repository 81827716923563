import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Store from 'store';

import { useFormattedPlanLegal } from '@pumpkincare/portal-app';

import { LegalSection } from '../../../app';
import { getRouterLocationQuery } from '../../../app/selectors';
import {
  CUSTOMER_VET_ID,
  CUSTOMER_VET_NAME,
  CUSTOMER_VET_PEP_VERSION,
} from '../../constants';
import { BusinessInsider } from '../business-insider';
import { CreatePlanNav } from '../create-plan-nav';
import { InsurancePlans } from '../insurance-plans';
import { PlansHeader } from '../plans-header';
import { PlansSection } from '../plans-section';
import { SigningUp } from '../signing-up';

export default function CustomerPlans() {
  const query = useSelector(getRouterLocationQuery);
  const vetId = query.vetId || Store.get(CUSTOMER_VET_ID);
  const vetName = query.vetName
    ? decodeURIComponent(query.vetName)
    : Store.get(CUSTOMER_VET_NAME);
  const pepVersion = query.pepVersion
    ? parseFloat(query.pepVersion)
    : Store.get(CUSTOMER_VET_PEP_VERSION);
  const { data: legal } = useFormattedPlanLegal(vetId);

  useEffect(() => {
    // bc hashed elements on the page are rendered via BE call (legal)
    window.location.hash = '';
  }, []);

  useEffect(() => {
    if (vetId) {
      Store.set(CUSTOMER_VET_ID, vetId);
    }

    if (vetName) {
      Store.set(CUSTOMER_VET_NAME, vetName);
    }

    if (pepVersion) {
      Store.set(CUSTOMER_VET_PEP_VERSION, pepVersion);
    }
  }, [pepVersion, vetName, vetId]);

  return (
    <>
      <CreatePlanNav pepVersion={pepVersion} />

      <PlansHeader vetName={vetName} pepVersion={pepVersion} />

      <BusinessInsider />

      <InsurancePlans pepVersion={pepVersion} />

      <PlansSection vetId={vetId} vetName={vetName} pepVersion={pepVersion} />

      <SigningUp pepVersion={pepVersion} />

      {legal.length ? <LegalSection legal={legal} /> : null}
    </>
  );
}
